import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import EditVehicleByAdmin from './EditVehicleByAdmin';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { selectUser } from '../../slices/auth';
import { useDispatch } from 'react-redux';

import { fetchSellerByEmailAsync } from './adminHomeSlice';

const EditButtonRenderer = (props) => {
  const rowData = props.data;
  const parentOnClosePopup = props.parentOnClosePopup();
  const adminEditAllData = props.adminEditAllData;
  const [open, setOpen] = useState(false);
  const user = useSelector(selectUser);
  const isSuperAdmin = user && user.roles.includes("ROLE_SuperAdmin");
  const dispatch = useDispatch();

  const onOpenModal = (event) => { event.stopPropagation();  setOpen(true); return false; }
  const onCloseModal = () => {
    setOpen(false);
    dispatch(fetchSellerByEmailAsync({ email: '' }));
    if (parentOnClosePopup) parentOnClosePopup();
  }

  return (
    <>
      {rowData && (rowData.status === 'InProcess' || rowData.status === 'Rejected' || isSuperAdmin) &&
        <div>
          <button className="btn btn-success btn-success-thin" onClick={onOpenModal}>Edit</button>
          <Modal open={open} onClose={onCloseModal} center
            classNames={adminEditAllData ? {
              modal: 'customModal'
            } : {}}
          >
            <EditVehicleByAdmin data={rowData} co2saverId={rowData.id} closePopup={onCloseModal} adminEditAllData={adminEditAllData} />
          </Modal>
        </div>
      }
      {!rowData &&
        <div>
          <button className="btn btn-success btn-success-thin" onClick={onOpenModal}>Add</button>
          <Modal open={open} onClose={onCloseModal} center
            classNames={adminEditAllData ? {
              modal: 'customModal'
            } : {}}
          >
            <EditVehicleByAdmin data={{}} co2saverId={0} closePopup={onCloseModal} adminEditAllData={adminEditAllData} />
          </Modal>
        </div>
      }
    </>
  );
};

export default EditButtonRenderer;