import React from 'react';
import Progress from './Progress';
import '../../styles.scss';
import qs from 'qs';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl'


const Header = (props) => {
  const { location, user, history } = props;
  const pathname = location.pathname;
  const isFirstStep = pathname === '/first';
  const isSecondStep = pathname === '/second';
  const isThirdStep = pathname === '/third';
  const isLoginPage = pathname === '/home';
  const intl = useIntl();

  const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true, });

  var ut;
  if (!queryStrings.ut) {
    var isPrivate, isFleet, isChargeSt, isWithVehicles, isCompany;
    try {
      ({ isPrivate, isFleet, isChargeSt, isWithVehicles, isCompany } = user.userType);
    } catch {
      history.push("/");
      window.location.reload();
    }
    ut = isPrivate ? 'po' : isFleet ? 'cf' : 'cs';
  } else {
    ut = queryStrings.ut;
  }

  return (
    <div style={{ textAlign: 'center' }}>


      {props.agentUser && props.agentUser.agent && props.agentUser.agent.partner &&
        <div style={{
          maxWidth: '300px',
          display: 'inline-block'
        }}>
          <img height="auto" style={{ width: '100%', height: 'auto', maxHeight: '200px' }}
            src={`https://eco2wo.com/api/admin/partners/logoFile/${props.agentUser.agent.partner.id}`}
          />
        </div>
      }

      <h1>{ut === 'po' ? intl.formatMessage({ id: 'regAsPvtO' }) :
        (ut === 'cf' ? intl.formatMessage({ id: 'regAsComFle' }) : "Charging Stations Owner")}</h1>
      <Progress {...props} />
    </div>
  )
};

export default Header;
