import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link } from "react-router-dom";

import * as Yup from "yup";
import qs from "qs";

import { login } from "../slices/auth";
import { clearMessage } from "../slices/message";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import ecoImage from "../assets/leaves-gec19df3f6_1280.jpg";
import ECOwoCard from "./ECOwoCard";

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(intl.formatMessage({ id: 'thisFieldIsReq' })),
    password: Yup.string().required(intl.formatMessage({ id: 'thisFieldIsReq' })),
  });

  const queryStrings = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });

  const initialMessage = queryStrings.verifSuccess
    ? "Account email successfully verified."
    : "";

  const handleLogin = (formValue) => {
    const { username, password } = formValue;
    setLoading(true);

    dispatch(login({ username, password }))
      .unwrap()
      .then(() => {
        props.history.push("/profile");
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (isLoggedIn) {
    return <Redirect to="/profile" />;
  }

  return (
    <>
      <div className="row content-container two-column-form">
        <div className=" left col-md-6 col-sm-12">
          <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
          />
          <label>{initialMessage}</label>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}>
            <Form>
              <div className="form-group">
                <label htmlFor="username">Email</label>
                <Field name="username" type="text" className="form-control" />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="alert alert-danger"
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">
                  <FormattedMessage id="password" />
                </label>
                <Field
                  name="password"
                  type="password"
                  className="form-control"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="alert alert-danger"
                />
              </div>

              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={loading}>
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>
                    <FormattedMessage id="login" />
                  </span>
                </button>
              </div>
            </Form>
          </Formik>
          <Link to={"/forgotPassword"} className="nav-link">
            <FormattedMessage id="forgotPassword" />
          </Link>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                <FormattedMessage id={message} />
              </div>
            </div>
          )}
        </div>
        <div className="col-md-6 col-sm-12 p-0">
          <div className="image-container">
            <img src={ecoImage} alt="eco green car co2" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
