import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import UploadFiles from '../../components/file-upload-2/file-upload.component';
import { BASE_API_URL } from '../../utils/constants';
import axios from 'axios';
import fileService from '../../services/upload-files.service';
import '../../styles.scss';
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from '@mantine/dates';

const SecondStep = (props) => {
  const { user } = props;
  const [vehicleType, setVehicleType] = useState('Car');
  const [regDate, setRegDate] = useState([]);
  const [files, setFiles] = useState({});
  const [fileMissingErrors, setFileMissingErrors] = useState({});
  const intl = useIntl(); //intl.formatMessage({ id: 'certId' })
  const { bvs } = props;
  const { thirdAttachmentNeeded } = bvs;
  var isPrivate, isFleet, isChargeSt, isWithVehicles, isCompany;
  try {
    ({ isPrivate, isFleet, isChargeSt, isWithVehicles, isCompany } =
      user.userType);
  } catch {
    props.history.push('/');
    window.location.reload();
  }

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      licence_plate: user.licence_plate,
    },
  });

  const onSubmit = (data) => {
    console.log({ dataons: data })
    data.numChildren = numChildren;
    if (!areAllUploadEvidencesSet()) {
      return false;
    }

    let i = 0;
    while (regDate[i]) {
      data[`registrationDate${i}`] = regDate[i];
      i++;
    }

    props.updateUser(data);
    console.log({ data });
    props.history.push('/third');
  };

  function areAllUploadEvidencesSet() {
    //fileMissingErrors, setFileMissingErrors
    var temp = { ...fileMissingErrors };
    var outcome = true;
    for (i = 0; i < numChildren; i++) {
      if (!files['ev' + i]) {
        temp['ev' + i] = '* ' + intl.formatMessage({ id: 'bothFilesMand' });
        outcome = false;
      } else if (!files['ev' + i]['front'] || (!files['ev' + i]['notSpecified'] && thirdAttachmentNeeded)) { //|| !files['ev' + i]['back']
        temp['ev' + i] = '* ' + intl.formatMessage({ id: 'bothFilesMand' });
        outcome = false;
      } else {
        temp['ev' + i] = null;
      }
    }

    setFileMissingErrors(temp);

    return outcome;
  }

  const [numChildren, setNumChildren] = useState(1);

  useEffect(() => {
    if (user.numChildren && user.numChildren > 1) {
      if (numChildren !== user.numChildren) {
        setNumChildren(user.numChildren);
      }
    }

    window.scrollTo(0, 0);
  }, []);

  const removeLastVehicle = () => {
    setNumChildren(numChildren - 1);
    user[`licence_plate${numChildren - 1}`] = null;
    user[`vin${numChildren - 1}`] = null;
    user[`regDate${numChildren - 1}`] = null;
    axios.get(
      `${BASE_API_URL}/upload/remove/${user.randomReference}-${numChildren - 1}`
    );
  };

  const vehicleTypes = [
    { isoCode: 'Car', name: intl.formatMessage({ id: 'car' }) },
    { isoCode: 'Bus', name: 'Bus' },
    { isoCode: 'Truck', name: intl.formatMessage({ id: 'truck' }) },
  ];

  const children = [];

  function updateVehicleType(name, value) {
    const update = {};
    update[name] = value;
    props.updateUser(update);
  }

  function updateFileMandatoryControl(fullPrefix, i) {
    const temp = { ...files };
    if (!temp['ev' + i]) {
      temp['ev' + i] = {};
    }
    temp['ev' + i][fullPrefix] = 'ok';
    setFiles(temp);
  }

  function setDateReg(i, e) {
    console.log({ i, e });
    var c = [];
    c[i] = e;
    console.log(c);
    setRegDate(c);
  }

  for (var i = 0; i < numChildren; i += 1) {
    children.push(
      <div>
        {i > 0 && <br />}
        <h3>
          {intl.formatMessage({
            id: isChargeSt ? 'ChargingPoint ' : 'vehicle',
          }) + ' '}{' '}
          {i + 1}
        </h3>
        <hr />
        {isFleet && (
          <Form.Group controlId={`vehicleTypeGroup${i}`}>
            <Form.Label>
              <FormattedMessage id='type' />
            </Form.Label>
            <Form.Control
              as='select'
              name={`vehicleType${i}`}
              defaultValue={vehicleType}
              ref={register({})}
              onChange={(event) =>
                updateVehicleType(event.target.name, event.target.value)
              }>
              {vehicleTypes.map(({ isoCode, name }) => (
                <option value={isoCode} key={isoCode}>
                  {name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}
        {isWithVehicles && (
          <>
            <Form.Group controlId={`licenceFormGroup${i}`}>
              <Form.Label>
                <FormattedMessage id='licplate' />
              </Form.Label>
              <div style={{ width: '200px' }}>
                <InputGroup className="mb-3">
                  <Form.Control
                    type='test'
                    name={`licence_plate${i}`}
                    //placeholder="Licence plate"
                    autoComplete='off'
                    defaultValue={
                      user[`licence_plate${i}`] ? user[`licence_plate${i}`] : ''
                    }
                    mask='111-111-1111E'
                    ref={register({
                      required: intl.formatMessage({ id: 'licPlateReq' }),
                      minLength: {
                        value: 4,
                        message: intl.formatMessage({ id: 'pwdMinChars' }, { numChars: 4 }),
                      },

                      // pattern: {
                      //   value: /^[a-zA-Z0-9\\-]+$/,
                      //   message:
                      //     'Licence plate should contain only characters and numbers.',
                      // },
                    })}
                    className={errors[`licence_plate${i}`] ? 'input-error' : ''}
                  />
                  <InputGroup.Text>E</InputGroup.Text>
                </InputGroup>
              </div>
              {errors[`licence_plate${i}`] && (
                <p className='errorMsg'>{errors[`licence_plate${i}`].message}</p>
              )}
            </Form.Group>

            <Form.Group controlId={`vinGroup${i}`}>
              <Form.Label>
                <FormattedMessage id='vin' />
              </Form.Label>
              <div style={{ width: '200px' }}>
                <Form.Control
                  type='test'
                  name={`vin${i}`}
                  autoComplete='off'
                  defaultValue={
                    user[`vin${i}`] ? user[`vin${i}`] : ''
                  }
                  ref={register()}
                  className={''}
                />
              </div>
            </Form.Group>

            <Form.Group controlId={`regDateGroup${i}`}>
              <Form.Label>
                <FormattedMessage id='regDate' />
              </Form.Label>
              <div style={{ width: '200px' }}>
                <DatePicker withAsterisk onChange={(e, c) => { setDateReg(0, e); }}
                />
              </div>
            </Form.Group>
          </>
        )}
        {isChargeSt && (
          <Form.Group controlId={`nameOfChPointGroup${i}`}>
            <Form.Label>Charging Point Name</Form.Label>
            <Form.Control
              type='test'
              name={`nameOfChPoint${i}`}
              placeholder='Enter the Charging Point Name'
              autoComplete='off'
              defaultValue={
                user[`nameOfChPoint${i}`] ? user[`nameOfChPoint${i}`] : ''
              }
              ref={register({
                required: 'Name of Charging Point is required.',
                minLength: {
                  value: 4,
                  message:
                    'Name of Charging Point should have at-least 4 characters.',
                },
              })}
              className={errors[`nameOfChPoint${i}`] ? 'input-error' : ''}
            />
            {errors[`nameOfChPoint${i}`] && (
              <p className='errorMsg'>{errors[`nameOfChPoint${i}`].message}</p>
            )}
          </Form.Group>
        )}
        {isChargeSt && (
          <Form.Group controlId={`numberOfChPointGroup${i}`}>
            <Form.Label>Charging Point Number</Form.Label>
            <Form.Control
              type='test'
              name={`numberOfChPoint${i}`}
              placeholder='Enter the Charging Point Number'
              autoComplete='off'
              defaultValue={
                user[`numberOfChPoint${i}`] ? user[`numberOfChPoint${i}`] : ''
              }
              ref={register({
                required: 'Charging Point Number is required.',
              })}
              className={errors[`numberOfChPoint${i}`] ? 'input-error' : ''}
            />
            {errors[`numberOfChPoint${i}`] && (
              <p className='errorMsg'>
                {errors[`numberOfChPoint${i}`].message}
              </p>
            )}
          </Form.Group>
        )}
        {isChargeSt && (
          <Form.Group controlId={`chPointAddressGroup${i}`}>
            <Form.Label>Charging Point Address</Form.Label>
            <Form.Control
              type='test'
              name={`chPointAddress${i}`}
              placeholder='Enter the Charging Point Address'
              autoComplete='off'
              defaultValue={
                user[`chPointAddress${i}`] ? user[`chPointAddress${i}`] : ''
              }
              ref={register({
                required: 'Charging Point Address is required.',
                minLength: {
                  value: 4,
                  message:
                    'Charging Point Address should have at-least 4 characters.',
                },
              })}
              className={errors[`chPointAddress${i}`] ? 'input-error' : ''}
            />
            {errors[`chPointAddress${i}`] && (
              <p className='errorMsg'>{errors[`chPointAddress${i}`].message}</p>
            )}
          </Form.Group>
        )}
        <div className='flexbox-container-horiz-no-border'>
          {isChargeSt && (
            <Form.Group
              controlId={`chPointCityGroup${i}`}
              style={{ 'padding-right': '15px' }}>
              <Form.Label>Charging Point City</Form.Label>
              <Form.Control
                type='test'
                name={`chPointCity${i}`}
                placeholder='Enter the City'
                autoComplete='off'
                defaultValue={
                  user[`chPointCity${i}`] ? user[`chPointCity${i}`] : ''
                }
                ref={register({
                  required: 'Charging Point City is required.',
                  minLength: {
                    value: 4,
                    message:
                      'Charging Point City should have at-least 4 characters.',
                  },
                })}
                className={errors[`chPointCity${i}`] ? 'input-error' : ''}
              />
              {errors[`chPointCity${i}`] && (
                <p className='errorMsg'>{errors[`chPointCity${i}`].message}</p>
              )}
            </Form.Group>
          )}
          {isChargeSt && (
            <Form.Group
              controlId={`chPointZipGroup${i}`}
              style={{ width: '50%' }}>
              <Form.Label>Charging Point Postcode</Form.Label>
              <Form.Control
                type='test'
                name={`chPointZip${i}`}
                placeholder='Enter the Postcode'
                autoComplete='off'
                defaultValue={
                  user[`chPointZip${i}`] ? user[`chPointZip${i}`] : ''
                }
                ref={register({
                  required: 'Charging Point Postcode is required.',
                  minLength: {
                    value: 4,
                    message:
                      'Charging Point Postcode should have at-least 4 characters.',
                  },
                })}
                className={errors[`chPointZip${i}`] ? 'input-error' : ''}
              />
              {errors[`chPointZip${i}`] && (
                <p className='errorMsg'>{errors[`chPointZip${i}`].message}</p>
              )}
            </Form.Group>
          )}
        </div>
        {isChargeSt && (
          <Form.Group controlId={`chPointAmountMWGroup${i}`}>
            <Form.Label>Charging Point Amount of MW Generated</Form.Label>
            <Form.Control
              type='test'
              name={`chPointAmountMW${i}`}
              placeholder='Enter the Charging Point Amount of MW Generated'
              autoComplete='off'
              defaultValue={
                user[`chPointAmountMW${i}`] ? user[`chPointAmountMW${i}`] : ''
              }
              ref={register({
                required: 'Charging Point Amount of MW Generated is required.',
              })}
              className={errors[`chPointAmountMW${i}`] ? 'input-error' : ''}
            />
            {errors[`chPointAmountMW${i}`] && (
              <p className='errorMsg'>
                {errors[`chPointAmountMW${i}`].message}
              </p>
            )}
          </Form.Group>
        )}
        {isChargeSt && (
          <Form.Group controlId={`chPointPowerSourceGroup${i}`}>
            <Form.Label>Power source</Form.Label>
            <div className='flexbox-container-horiz'>
              <Form.Check
                ref={register({})}
                inline
                label='Local Sustainable'
                name={`chPointPowerSource${i}`}
                type='radio'
                id={`inline-radio-${i}${i}`}
                value='LocalSustainable'
              />
              <Form.Check
                ref={register({})}
                inline
                label='Public'
                name={`chPointPowerSource${i}`}
                type='radio'
                defaultChecked={true}
                id={`inline-radio-${i + 1}${i + 1}`}
                value='Public'
              />
            </div>
          </Form.Group>
        )}
        {isWithVehicles && (
          <div>
            <Form.Label>
              <FormattedMessage id='attachments' />
            </Form.Label>
            <div
              className={'flexbox-container-horiz'}
              style={{ justifyContent: 'space-around' }}>
              <Form.Group
                controlId={`evidence_front${i}`}
                style={{ width: '50%' }}>
                <UploadFiles
                  updateFilesCb={fileService.uploadMultiple}
                  title={intl.formatMessage({ id: 'frontscan' })}
                  index={i}
                  frontBack='front'
                  filenamePrefix={`${user.randomReference}-${i}-front`}
                  label=''
                  callback={updateFileMandatoryControl}
                  thin={thirdAttachmentNeeded}
                />
              </Form.Group>
              {/* <Form.Group
                controlId={`evidence_back${i}`}
                style={{ width: '50%' }}>
                <UploadFiles
                  updateFilesCb={fileService.uploadMultiple}
                  title={intl.formatMessage({ id: 'backscan' })}
                  index={i}
                  frontBack='back'
                  filenamePrefix={`${user.randomReference}-${i}-back`}
                  label=''
                  callback={updateFileMandatoryControl}
                  thin={thirdAttachmentNeeded}
                />
              </Form.Group> */}
              {thirdAttachmentNeeded &&
                <Form.Group
                  controlId={`evidence_third${i}`}
                  style={{ width: '50%' }}>
                  <UploadFiles
                    updateFilesCb={fileService.uploadMultiple}
                    title={intl.formatMessage({ id: 'thirdDoc' })}
                    index={i}
                    frontBack='notSpecified'
                    filenamePrefix={`${user.randomReference}-${i}-notSpecified`}
                    label=''
                    callback={updateFileMandatoryControl}
                    thin={thirdAttachmentNeeded}
                  />
                </Form.Group>
              }
            </div>
            <p className='errorMsg'>{fileMissingErrors['ev' + i]}</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <Form className='input-form' onSubmit={handleSubmit(onSubmit)}>
        <motion.div
          className='col-md-12'
          initial={{ x: '-100vw' }}
          animate={{ x: 0 }}
          transition={{ stiffness: 150 }}>

          {isWithVehicles &&
            <>
              <table>
                <tr height="70">
                  <td width="30px"><h4>1.</h4></td>
                  <td align="center"><img src="camera.png" width="50" height="50" alt="D" /></td>
                  <td colSpan="2" style={{ paddingLeft: "10px" }} ><FormattedMessage id="regDir1" /></td>
                </tr>
                <tr height="70">
                  <td><h4>2.</h4></td>
                  <td align="center"><img src="scan2.png" width="50" height="50" alt="D" /></td>
                  <td style={{ paddingLeft: "10px" }} ><FormattedMessage id="regDir2" /></td>
                  <td style={{ paddingLeft: "4px" }} >
                    <div style={{ width: '300px', display: window.innerWidth >= 600 ? 'inherit' : 'none' }} >
                      <img src="vin.png" alt="VIN" width="100%" align="center" />
                    </div>
                  </td>
                </tr>
                <tr height="60">
                  <td><h4>3.</h4></td>
                  <td align="center"><img src="submit.png" width="50" alt="D" /></td>
                  <td colSpan="2" style={{ paddingLeft: "10px" }} ><FormattedMessage id="regDir3" /></td>
                </tr>
              </table>
              <div style={{ width: '100%', display: window.innerWidth < 600 ? 'inherit' : 'none' }} >
                <img src="vin.png" alt="VIN" width="100%" align="center" />
              </div>
              <table>
                <tr>
                  <td ><FormattedMessage id="regDir4" />
                    <a href="https://www.umweltbundesamt.de/themen/verkehr-laerm/kraft-betriebsstoffe/vollzug-38-bimschv-anrechnung-von-strom-fuer#hintergrund"
                      target="_blank"
                      rel="noreferrer"><FormattedMessage id="readMore" /></a> </td>
                  <td width="50"><a href="https://www.umweltbundesamt.de/themen/verkehr-laerm/kraft-betriebsstoffe/vollzug-38-bimschv-anrechnung-von-strom-fuer#hintergrund"
                    target="_blank"
                    rel="noreferrer">
                    <img src=" logo-ex.png" alt="logo" align="center" /></a></td>
                </tr>
              </table>
              <hr />
            </>
          }

          {children}

          <div
            className='flexbox-container-horiz'
            style={{ justifyContent: 'space-around', border: 0 }}>
            <Button
              variant='primary'
              type='button'
              onClick={() => {
                removeLastVehicle();
              }}>
              <FormattedMessage id='removeLast' values={{ what: intl.formatMessage({ id: isChargeSt ? 'ChargingPoint ' : 'vehicle' }), }} />
            </Button>
            &nbsp;&nbsp;
            <Button
              variant='primary'
              type='button'
              onClick={() => {
                setNumChildren(numChildren + 1);
              }}>
              <FormattedMessage id='addWhat' values={{ what: intl.formatMessage({ id: isChargeSt ? 'ChargingPoint ' : 'vehicle' }), }} />
            </Button>
            &nbsp;&nbsp;
            <Button variant='primary' type='submit'>
              <FormattedMessage id='next' />
            </Button>
          </div>
        </motion.div>
      </Form>
    </div >
  );
};

export default SecondStep;
