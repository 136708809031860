import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, selectIsLoggedIn } from '../../slices/auth';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import EditRowButtonRenderer from './editButtonRenderer';
import DocsIconsListRenderer from './iconsListRenderer';

import {
  saveBuyerInvoiceAsync,
  fetchBuyersInvoicesAsync,
  selectBuyersInvoices,
  selectSaveBuyerInvoiceResult,
  selectSaveBuyerInvoiceStatus,
} from './adminHomeSlice';

let needsReloading = true;
var saveButtonClicked = false;

export default function BuyersInvoicesList(props) {
  const vehicles = useSelector(selectBuyersInvoices);
  const saveBuyerInvoiceStatus = useSelector(selectSaveBuyerInvoiceStatus);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (needsReloading) {
      dispatch(fetchBuyersInvoicesAsync());
      needsReloading = false;
    }
  });
  
  if (!user) {
    props.history.push('/login');
  }

  const cellLeftAlign = { 'text-align': "left", padding: '2px' };
  const cellRightAlign = { 'text-align': "right", padding: '2px' };
  const cellCenterAlign = { 'text-align': "center", padding: '2px' };

  function headerHeightSetter() {
    var padding = 2;
    var height = 50 + padding;
    this.api.setHeaderHeight(height);
    this.api.resetRowHeights();
  }

  function dateFormatter(params) {
    var newDate = new Date();
    newDate.setTime(Date.parse(params.value));
    return newDate.toLocaleDateString();
  }

  function round2dec(num) {
    return Math.round(num * 100) / 100
  }

  return (
    <div>
      <header className="">
        <h3>Buyers Invoices</h3>
        {/*On this page you can see all invoices that have been issued in the past.*/}
      </header>
      <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
        <AgGridReact style={{ height: 800, width: '100%' }}
          //onGridReady={onGridReady}
          onFirstDataRendered={headerHeightSetter}
          rowData={vehicles}
          frameworkComponents={{
            editRowButtonRenderer: EditRowButtonRenderer,
            docsIconsListRenderer: DocsIconsListRenderer
          }}
          defaultColDef={{
            flex: 1,
            editable: true,
            cellStyle: cellLeftAlign,
            wrapText: true
          }}
          default key="id" rowSelection='multiple'
        >
          <AgGridColumn headerName="Invoice" field="invoiceNumber" maxWidth="80" cellStyle={cellCenterAlign} filter={true} />
          <AgGridColumn headerName="Company" field="companyName" filter={true} />
          <AgGridColumn headerName="Email" field="email" filter={true} />
          <AgGridColumn field="vatNumber" headerName="VAT ID" sortable={true} />
          <AgGridColumn field="date" headerName="Date" valueFormatter={dateFormatter}
            sortable={true} />
          <AgGridColumn field="address" headerName="Address" sortable={true} />
          <AgGridColumn field="postcode" headerName="Postcode" sortable={true} />
          <AgGridColumn field="city" headerName="City" sortable={true} />
          <AgGridColumn field="country" headerName="Country" sortable={true} />
          {/*<AgGridColumn headerName="Documents" cellRendererParams={{ "rendererImage": "icon-doc.png" }}
          cellRenderer="docsIconsListRenderer" field="fileEvidences" />*/}
          {/*<AgGridColumn headerName="Status" field="status" maxWidth="100" filter={true} />*/}
          <AgGridColumn field="pricePerTCo2" cellStyle={cellRightAlign} valueGetter={(params) =>
            params.data.pricePerTCo2 ? ('' + round2dec(params.data.pricePerTCo2)).concat(` €`) : ''}
            maxWidth="120" headerName="Price Per TCo2" headerClass="header-text-center" />
          <AgGridColumn field="totalTCo2Sold" cellStyle={cellRightAlign} valueGetter={(params) =>
            params.data.totalTCo2Sold ? ('' + round2dec(params.data.totalTCo2Sold)).concat(` t`) : ''}
            maxWidth="120" headerName="Total tCO2" headerClass="header-text-center" />
          <AgGridColumn field="totalVat" cellStyle={cellRightAlign} valueGetter={(params) =>
            params.data.totalVat ? ('' + round2dec(params.data.totalVat)).concat(` €`) : ''}
            maxWidth="120" headerName="Total VAT" headerClass="header-text-center" />
          <AgGridColumn cellStyle={cellRightAlign} valueGetter={(params) =>
            params.data.pricePerTCo2 ? ('' + round2dec(params.data.pricePerTCo2 * params.data.totalTCo2Sold)).concat(` €`) : ''}
            maxWidth="120" headerName="Total Without VAT" headerClass="header-text-center" />
          <AgGridColumn field="totalPrice" cellStyle={cellRightAlign} valueGetter={(params) =>
            params.data.totalPrice ? ('' + round2dec(params.data.totalPrice)).concat(` €`) : ''}
            maxWidth="120" headerName="Total Price" headerClass="header-text-center" />

          {/*
    private String vatNumber;
    private byte[] invoice;
    private List<CO2Saver> co2Savers;*/}

        </AgGridReact>
      </div >
    </div>
  );

}
