import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import Register from "./components/Register";

import BoardUser from "./components/BoardUser";
import BoardAdmin from "./components/BoardAdmin";

import { logout, selectSeller } from "./slices/auth";
import { slide as Menu } from "react-burger-menu";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";

import EventBus from "./common/EventBus";
import FirstStep from "./features/registration/FirstStep";
import SecondStep from "./features/registration/SecondStep";
import ThirdStep from "./features/registration/ThirdStep";
import Header from "./features/registration/Header";
import SetNewPassword from "./features/registration/SetNewPassword";
import EditAccount from "./features/home/editAccount";
import AdminSettings from "./features/admin-home/AdminSettings";
import SellQuotas from "./features/admin-home/SellQuotas";
import BuyersInvoicesList from "./features/admin-home/BuyersInvoicesList";
import Referrals from "./features/home/Referrals";
import AdminReferrals from "./features/admin-home/AdminReferrals";
import AdminStats from "./features/admin-home/AdminStats";
import sellerType from "./features/sellerType";
import Footer from "./components/Footer";
import RegAgent from './features/registration/regAgent';
import AgentHome from './features/agents/AgentHome';
import AdminPartners from './features/admin-home/AdminPartners';
import AdminAgents from "./features/admin-home/AdminAgents";
import EditAgentAccount from "./features/agents/editAccount";
import AdminQuotasPartners from "./features/admin-home/AdminQuotasPartners";
import Landing from "./components/Landing";

const App = () => {
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [showAgentBoard, setShowAgentBoard] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const [lang, setLang] = useState(localStorage.getItem("lang") || "de");
  const seller = useSelector(selectSeller);
  const [showReferrals, setShowReferrals] = useState(false);
  const [agentUser, setAgentUser] = useState(null);

  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setShowAdminBoard(currentUser.roles.includes("ROLE_Admin"));
      setShowAgentBoard(currentUser.roles.includes("ROLE_Agent"));

      setIsAdmin(currentUser.roles.includes("ROLE_Admin"));
      setIsSuperAdmin(currentUser.roles.includes("ROLE_SuperAdmin"));
    } else {
      setShowAdminBoard(false);
      setShowAgentBoard(false);
      setIsSuperAdmin(false);
    }

    console.log({ isSuperAdmin: isSuperAdmin });

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  const [user, setUser] = useState({});

  const updateUser = (data) => {
    setUser((prevUser) => ({ ...prevUser, ...data }));
  };

  const updateLanguage = (langIn) => {
    localStorage.setItem("lang", langIn);
    setLang(langIn);
  };

  const resetUser = () => {
    setUser({});
  };

  if (currentUser && !showAdminBoard) {
    if (seller != null) {
      const { isPrivate, isFleet, isChargeSt, isWithVehicles, isCompany } =
        sellerType(seller.sellerType);
      if (isPrivate) {
        if (!showReferrals) setShowReferrals(true);
      } else {
        if (showReferrals) setShowReferrals(false);
      }
    }
  }

  const brandVisualSettingsEco2wo = {
    adminEditAllData: true,
    browserTitle: 'eco2wo App',
    logoImage: {
      src: 'logo.png',
    },
    label: 'eCO2wo',
    infoDomain: 'eco2wo.de',
    hostUri: 'https://eco2wo.de',
    linkContactUs: "https://eco2wo.de/#footer",
    ourNgosLink: "https://eco2wo.de/ngo-partners",
    msgEng: {
      linktc: "https://eco2wo.de/en/agb/",
      linkprivacy: "https://eco2wo.de/en/datenschutz/",
      footerLinkImprint: "https://eco2wo.de/en/impressum/",
      footerLinkRightToWithdraw: "https://eco2wo.de/en/widerrufsrecht/",
    },
    msgDe: {
      linktc: "https://eco2wo.de/agb/",
      linkprivacy: "https://eco2wo.de/datenschutz/",
      footerLinkImprint: "https://eco2wo.de/impressum/",
      footerLinkRightToWithdraw: "https://eco2wo.de/widerrufsrecht/",
    },
    headerBackground: "#000738",
    mainFontColor: '#3f4343',
    menuFontColor: '#fff'
  }

  const brandVisualSettingLocalhost = {
    ...brandVisualSettingsEco2wo,
    adminEditAllData: true,
    hostUri: 'http://localhost:3000'
  }

  const brandVisualSettingsToolfuel = {
    adminEditAllData: true,
    browserTitle: 'ToolFuel ECO App',
    logoImage: {
      src: 'toolfuel_logo_main.png',
    },
    label: 'ToolFuel',
    infoDomain: 'toolfuel.eu',
    hostUri: 'https://toolfuel.de',
    linkContactUs: "https://toolfuel.eu/#footer",
    ourNgosLink: "https://toolfuel.eu/ngo-partners",
    msgEng: {
      linktc: "https://toolfuel.eu/en/agb/",
      linkprivacy: "https://toolfuel.eu/en/datenschutz/",
      footerLinkImprint: "https://toolfuel.eu/en/impressum/",
      footerLinkRightToWithdraw: "https://toolfuel.eu/en/widerrufsrecht/",
    },
    msgDe: {
      linktc: "https://toolfuel.eu/agb/",
      linkprivacy: "https://toolfuel.eu/datenschutz/",
      footerLinkImprint: "https://toolfuel.eu/impressum/",
      footerLinkRightToWithdraw: "https://toolfuel.eu/widerrufsrecht/",
    },
    headerBackground: '#f5f5f5',
    mainFontColor: '#0546a5',
    menuFontColor: '#0546a5'
  }

  const brandVisualSettingsEvFeldhausDe = {
    adminEditAllData: true,
    browserTitle: 'FeldHaus ECO App',
    logoImage: {
      src: 'feldhaus_logo_main.png',
    },
    label: 'FeldHaus',
    infoDomain: 'feldhaus-energie.de',
    hostUri: 'https://praemie-eauto.de',
    linkContactUs: "https://www.feldhaus-energie.de/team",
    ourNgosLink: "https://feldhaus-energie.de/ngo-partners",
    msgEng: {
      linktc: "https://feldhaus-energie.de/en/agb/",
      linkprivacy: "https://www.feldhaus-energie.de/en/datenschutz",
      footerLinkImprint: "https://www.feldhaus-energie.de/impressum/",
      footerLinkRightToWithdraw: "https://feldhaus-energie.de/en/widerrufsrecht/",
    },
    msgDe: {
      linktc: "https://feldhaus-energie.de/agb/",
      linkprivacy: "https://www.feldhaus-energie.de/en/datenschutz",
      footerLinkImprint: "https://www.feldhaus-energie.de/impressum/",
      footerLinkRightToWithdraw: "https://feldhaus-energie.de/widerrufsrecht/",
    },
    headerBackground: '#f5f5f5',
    mainFontColor: '#0546a5',
    menuFontColor: '#0546a5'
  }

  const brandVisualSettingsCar4ma = {
    adminEditAllData: true,
    thirdAttachmentNeeded: true,
    browserTitle: 'Car4ma EV App',
    logoImage: {
      src: 'car4ma_weiss_pastell.png'
    },
    label: 'Car4ma',
    infoDomain: 'car4ma.de',
    hostUri: 'https://ev.car4ma.de',
    linkContactUs: "https://car4ma.de/#footer",
    ourNgosLink: "https://car4ma.de/ngo-partners",
    msgEng: {
      linktc: "https://car4ma.de/en/agb/",
      linkprivacy: "https://car4ma.de/en/datenschutz/",
      footerLinkImprint: "https://car4ma.de/en/impressum/",
      footerLinkRightToWithdraw: "https://car4ma.de/en/widerrufsrecht/",
    },
    msgDe: {
      linktc: "https://car4ma.de/agb/",
      linkprivacy: "https://car4ma.de/datenschutz/",
      footerLinkImprint: "https://car4ma.de/impressum/",
      footerLinkRightToWithdraw: "https://car4ma.de/widerrufsrecht/",
    }
  }

  const brandVisualSettingsMap = {
    'localhost': brandVisualSettingLocalhost,
    'eco2wo.com': brandVisualSettingsEco2wo,
    'ev.car4ma.de': brandVisualSettingsCar4ma,
    'toolfuel.de': brandVisualSettingsToolfuel,
    'praemie-eauto.de': brandVisualSettingsEvFeldhausDe
  }

  const brandVisualSettings = brandVisualSettingsMap[window.location.hostname];
  const bvs = brandVisualSettings;
  const label = brandVisualSettings.label;

  if (bvs) {
    console.log({adminEditAllData: bvs.adminEditAllData});
  }
  document.title = bvs.browserTitle;

  const messages = {
    en: {
      regDate: 'Date of registration (Field I on page 1 of the vehicle registration document)',
      duplicateVehicle: 'Register vehicle for year {year}',
      year: 'Year',
      moveToYear: '-> {year}',
      validIbanIsReq: 'Valid IBAN is required',
      pwdsDoNotMatch: "The passwords do not match",
      pwdMinChars: "Minimum {numChars} characters",
      emailFormatNotValid: 'The Email format is not valid',
      mandatoryField: 'Mandatory field',
      thisFieldIsReq: "This field is required",
      sendPwdResetLink: "Send password reset link",
      adminHomeSubtitle: "You can use this page to review and update the Quotas information.",
      quotaID: "Quota ID",
      notEligVat: "not eligible for input tax deduction",

      landingTitle: 'Welcome to our Partnership portal',

      landingIntro: 'Don\'t miss out! You can now earn with your e-car up to {partnerPvtVBasePayAmount} € annual bonus!',
      landingMain: 'As you are already a customer of {what} you will get an additional {thisMany} €. ',
      landingMain2:
        'Please contact your sales consultant to receive your “Promo Code. ' +
        'If you already have a “Promo Code” with you, please select your carbon saving bonus footprint path:',

      landingIntroWl: "Great to hear that you own a 100% eCar! You can now receive a compensation bonus (€) for the CO₂ savings your eAuto avoids. ",
      landingMainWl: "You will receive 255,- € when you register your eAuto with us. " +
        "This is based on a new federal government regulation that went into effect Jan. 1, 2022. " +
        "This process is handled via the eCO2wo platform. You will receive the payment from us 14 days after the confirmation of your data by the Federal Environmental Agency. This process can take up to 3 months. " +
        "For this, you only need to enter your data here. We will take care of everything else for you. ",

      landingMainWl2:
        'Please contact your sales consultant to receive your “Promo Code. ' +
        'If you already have a “Promo Code” with you, please select your carbon saving bonus footprint path:',

      agentLinkForPvr: 'Link for Private Vehicles Registration',
      agentLinkForCfr: 'Link for Commercial Fleet Registration',
      agentQRForPvr: 'QR Code for Private Vehicles Registration',
      agentQRForCfr: 'QR Code for Commercial Fleet Registration',
      privateECars: 'Private E-Cars',
      commFleets: 'Commercial Fleets',
      yourPromoCode: 'Your Promo Code',
      commStatus: 'Commission Status',
      commAmount: 'Commission Amount',
      certStatus: "Certificate Status",
      salesTnc: 'Sales T&C',
      markTnc: 'Marketing T&C',
      yourAccepted: 'Your accepted',
      regWithPartnerInstr1: 'Great to hear that you own an 100% electric car! You can now receive a compensation bonus (€) for these carbon emissions your electric car is saving.',
      regWithPartnerInstr2: label + ' pays everyone € {howMuchBasicPayment} if you register your e-car with us. ',
      regWithPartnerInstr3: 'However since you are already a customer of our partner {partnerName}, you will be getting an additional {howMuch} € bonus. ',
      regWithPartnerInstr3p1: 'You can either have it paid out or invest it in climate-friendly projects. We take care of everything else for you.',
      regWithPartnerInstr4: 'The basis for this is a <a href=\"https://lnkd.in/eTpP2Npm anrechnung-von-strom-fuer#hintergrund\">new ordinance</a> of the Federal Government which comes into force on 1 January 2022.',

      regWithPartnerInstr1Wl:
        "Great to hear that you own a 100% eCar! You can now receive a compensation bonus (€) for the CO₂ savings your eAuto avoids.",
      regWithPartnerInstr2Wl:
        "You will receive {howMuchBasicPayment},- € when you register your eAuto with us. ",
      regWithPartnerInstr3Wl: "This is based on a new <a href=\"https://www.umweltbundesamt.de/themen/verkehr-laerm/kraft-betriebsstoffe/vollzug-38-bimschv-anrechnung-von-strom-fuer\">federal government regulation</a> that went into effect Jan. 1, 2022.",
      regWithPartnerInstr3Wl1: "This process is handled via the eCO2wo platform. You will receive the payment from us 14 days after the confirmation of your data by the Federal Environmental Agency. This process can take up to 3 months.",
      regWithPartnerInstr4Wl: "For this, you only need to enter your data here. We will take care of everything else for you.",

      agentHomeInstrTitle: 'Ways to refer your customers:',
      agentHomeInstr1: 'Authentic “Promo Code” so that your customer can insert it in our main registration form (Private e-Car owner or Company Car / Commercial Fleet Operator) and we can identify it came from you.',
      agentHomeInstr2: 'Direct URL link to the registration form (Private e-Car owner or Company Car / Commercial Fleet Operator) with your promo code already included.',
      agentHomeInstr3: 'One of a kind “QR code” so that you can include it in any physical letters that you would like to send via post.',
      agentRegText1: "We are so proud to be partnering with your company!<br/><br/>" +
        "We are even happier to know that you want to be part of this incredible journey by helping your current/new customers earn an annual bonus of up to € 300 just by owning a 100% electric car.<br/><br/>" +
        "In return, we will pay you a commission for each e-car that your customers register in our platform and gets validated by the government authorities.<br/><br/>" +
        "We have made it very easy for you! Once you have registered with us, not only will you be able to see the status of all your referred customers, but also you will have many unique ways to refer them to us: " +
        "<il> <li>Authentic “Promo Code” so that your customer can insert it in our main registration form (Private e-Car owner or Company Car / Commercial Fleet Operator) and we can identify it came from you. " +
        "</li><li>Direct URL link to the registration form (Private e-Car owner or Company Car / Commercial Fleet Operator) with your promo code already included. " +
        "</li><li>One of a kind “QR code” so that you can include it in any physical letters that you would like to send via post." +
        "</li></il><br/>The basis for this is a <a href=\"https://www.umweltbundesamt.de/themen/verkehr-laerm/kraft-betriebsstoffe/vollzug-38-bimschv-anrechnung-von-strom-fuer\">new ordinance</a> of the Federal Government which comes into force on 1 January 2022. " +
        "<br/><br/>",
      agentRegText2: 'For more information, please read the ',
      agentRegText3: '“Sales T&Cs”',
      agentRegText3m: 'Marketing T&Cs”',
      promoCode: 'Promo Code',
      agentId: "Agent ID",
      userPassIncorrect: 'The username or password are not correct.',
      errorTryLater: 'There was an error. Please try later.',
      officeId: "Office ID",
      registerAgent: "Register As Agent",
      licPlateReq: 'Licence plate is required',
      bothFilesMand: "Beide Dateien sind erforderlich",
      page3Nstep1: 'We will send you an email to activate your account. After you have confirmed, you can log  into your dashboard.',
      page3Nstep2: 'Our team will verify the information you have provided, in order to proceed with the payment.',
      page3Nstep3: 'In the meantime, you can check your status, refer a friend and add more vehicles when you log into your account.',
      vehicles: 'Vehicles',
      regDir1:
        "Photograph or scan the front and back of the the vehicle registration document.",
      regDir2: "Upload images.",
      regDir3: "Enter your data and click on continue.",
      regDir4:
        "We enforce the 38th Federal Immission Control Ordinance (BImSchV No. 38) for you. ",
      readMore: "Read more.",
      dashboard: "Dashboard",
      toChangePassClick: "To change your password, click ",
      company: "Company",
      nextStepsHeader: "Next Steps",
      homeInstrLine1:
        "We will send you an email to activate your account. After you have confirmed, you can log  into your dashboard.",
      homeInstrLine2:
        "Our team will verify the information you have provided, in order to proceed with the payment.",
      homeInstrLine3:
        "In the meantime, you can check your status, refer a friend and add more vehicles when you log into your account.",
      homeInstrLine4: "Your certificate will go through several phases:",
      homeInstrLine5:
        "Initial phase. During this phase you will still be able to edit your information.",
      homeInstrLine6:
        "Our team is reviewing the information and documentation you provided with the government authorities. You cannot edit anything anymore.",
      homeInstrLine7:
        "Some of the information you have provided are not correct and could not be validated. Here you MUST edit your information and documentation so that we can review it and validate it again.",
      homeInstrLine8:
        "The information and documentation you have provided is correct and validated with the government authorities. You will soon either be paid or we will donate it to one of the NGOs that you have selected.",
      homeInstrLine9:
        "The payment has been completed either toward your bank account or to one of the NGOs that you have selected.",
      certId: "Certificate ID",
      certificates: "Certificates",
      type: "Type",
      email: "Email",
      fname: "First Name",
      lname: "Last Name",
      password: "Password",
      enterYourFirstName: "Enter your first name",
      enterYourLastName: "Enter your last name",
      enterYourEmail: "Enter your email address",
      choosePassword: "Choose a password",
      agreeTo: "I agree to the " + label + " ",
      agreeTo2: " , as well as the ",
      agreeTo3: " .",
      licplate: "Licence plate",
      companyName: "Company Name",
      address: "Address",
      vatNum: "VAT Number",
      companyId: "Trade Register ID",
      city: "City",
      postcode: "Postcode",
      doc1: "Document 1",
      doc2: "Document 2",
      docs: "Documents",
      vin: "VIN",
      paymentMethod: "Payment Method",
      submDate: "Submission date",
      mwhGen: "MWh Generated",
      tco2Saved: "tCO2 Saved",
      paymentAmount: "Payment Amount",
      bankName: "Bank Name",
      recipientName: "Recipient Name",
      iban: "IBAN",
      status: "Status",
      sold: "Sold",
      sellerId: "Seller ID",
      editDelete: "Edit/ delete",
      referralBonus: "Referal Bonus (€)",
      tnc: "T&C",
      dataPrivacy: "Data Privacy",
      custSettings: "CUSTOMER SETTINGS",
      recAFriend: "RECOMMEND A FRIEND",
      custService: "CUSTOMER SERVICE",
      changePassword: "Change Password",
      vehicle: "Vehicle",
      car: "Car",
      truck: "Truck",
      removeLast: "Remove last {what}",
      next: "Next",
      referralsEmail: "Refer a Friend",
      withlicenseplate: "with license plate",
      totalAmountWillBe: "The total amount to be paid: ",
      totalAmount2: "We are also donating another {fortyFiveTimes}€ to climate protection projects with MyClimate and avoiding further {tonsTimes}t CO2", addAnotherRef: "Add another referral",
      register: "Register",
      regPage3TheFolow:
        "The following {what} will be registered in your account:",
      step: "Step",
      regAsComFle: "Register as Commercial Fleet Owner",
      regAsPvtO: "Register as Private Owner",
      welcomeBack: "Welcome back ",
      frontscan: "Scan the front of the vehicle registration document",
      backscan: "Scan the back of the vehicle registration document",
      thirdDoc: 'Scan the vehicle Homologation document',
      shortFrontscan: 'VIN front',
      shortBackscan: 'VIN back',
      shortThirdDoc: 'Homologation doc',
      attachments: 'Attachments',
      uploadAFile: "Upload a file",
      succReg: "You're successfully registered!",
      succReg2:
        "We've sent you a confirmational email. Please click the link in the email to verify your account.",
      add: "Add",
      addWhat: "Add {what}",
      edit: "Edit",
      payoutToIban: "Payout to IBAN",
      dtNGO01: "Donate to Climate saving projects",
      dtNGO02: "Donate to MyClimate",
      referrals: "Referrals",
      referralsSubTitle:
        "Have you had a great experience with our platform? Would you like to earn an additional € 50 bonus with us? Do you have a friend that owns an e-Car? Then recommend us to your friends by only giving us their email address!",
      addReferral: "Add Referral",
      editAccount: "Edit Account",
      about: "About Our Platform",
      contactUs: "Contact Us",
      addNewVehicleInfo: "Add New Vehicle Information",
      editVehicleInfo: "Edit Vehicle Information",
      save: "Save",
      referRFriend: "Refer a friend",
      accInfo: "Account Information",
      accSubt: "You can use this page to edit your account's information.",
      curPass: "Current Password",
      codeFromEmail: "Code from email",
      newPassword: "New Password",
      confiPass: "Confirm Password",

      country: "Country",
      regPage3nextSteps:
        'Next steps:<ul style="padding-left: 1em;\'">' +
        "<li>We'll send you an email to activate your account. After you click on the link in the email, you can login into your dashboard.</li>" +
        "<li>Our teams will verify the information you provided, in order to proceed to the payment phase.</li>" +
        "<li>In the meantime, you can add more vehicles when you log into your account.</li>" +
        "</ul>",

      plsChooseWayOP: 'Note: You can further support the climate protection or social projects by donating your bonus to one of our <a>NGO partners</a>',
      wop: "Payment Method",
      youCanReferLongText1:
        "You can refer your friends or acquaintances and earn an additional referral bonus of ",
      youCanReferLongText2:
        " euros per referral, as soon as they register on our website and pass the validation process.",
      subtitlePlsChangePass: "Please use this page to set a new password.",
      nextSteps:
        "Next steps:" +
        '<ul style="padding-left: 1em;\'">' +
        "<li>Our teams will verify the information you provided, in order to proceed to the payment phase.</li>" +
        "<li>In the meantime, you can add more information when you log into your account.</li>" +
        "<li>" +
        "Your quota will go through several phases described here:" +
        "<ol>" +
        "<li>In process - this is the initial phase. While your quota is in this phase, you can still edit the information, for example, if you've made a typo or similar.</li>" +
        "<li>Reviewing - this is when our teams are validating the info you provided. You cannot update the quota details any more.</li>" +
        "<li>Validated - this means that the quota information have been validated and we'll soon proceed with payment.</li>" +
        "<li>Paid - the payment has been completed either towards your IBAN or one of the NGOs depending on your choice.</li>" +
        "</ol>" +
        "</li>" +
        'There is another status that your quota can appear in: "rejected". This means some of the information you provided are not correct and could not be validated.' +
        "In this phase you can edit the quota information, so we can try to validate them again." +
        "</ul>",
      forgotPassword: "Forgot Password",
      login: "Log In",
      emailAInUse: "Email already in use",
      statusInProcess: "In Process",
      statusReviewing: "Reviewing",
      statusRejected: "Rejected",
      statusValidated: "Validated",
      statusPaid: "Paid",
      statusPaymentInitiated: "Payment Initiated",
      statusPaymentFailed: "Payment Failed",
      referralStatusEmailed: "E-Mail sent",
      referralStatusSignedUp: "Registered",
      referralStatusCO2SaverValidated: "Validated",
      referralStatusToBePaid: "To Be Paid",
      referralStatusPaid: "Paid",

      footerText:
        "The platform that negotiates carbon emissions savings; helping the world become carbon neutral by 2050.",
      footerCopyright: "Copyright |" + label + "| 2022",

      imprint: "Imprint",
      rtWith: "Right to Withdraw",
      confirmPassword: "Confirm Password",

      ...bvs.msgEng
    },
    de: {
      regDate: 'Datum der Zulassung (Feld I auf Seite 1 des Fahrzeugscheins)',
      confirm: 'Ich bestätige',
      duplConfirmText: 'Ich bestätige hiermit ausdrücklich, dass ich Halter des Fahrzeugs bin und mir der Anspruch der THG-Prämie für dieses Fahrzeug zusteht. Hiermit stimme ich den AGB der eCo2wo MoBiLe GmbH zu. Ich erteile den Auftrag mein Fahrzeug beim Umweltbundesamt anzumelden und übertrage eCo2wo die Rechte und Pflichten aus dem Quotenhandel gemäß § 7 Absatz 5 der 38. BImSchV.',
      duplicateVehicle: 'Fahrzeug registrieren für Jahr {year}',
      year: 'Jahr',
      moveToYear: '-> {year}',// 'Auf {year} verschieben',
      validIbanIsReq: 'Gültige IBAN ist erforderlich',
      pwdsDoNotMatch: "Die Kennwörter stimmen nicht überein",
      pwdMinChars: "Mindestens {numChars} Zeichen",
      emailFormatNotValid: 'Das E-Mail-Format ist nicht gültig',
      mandatoryField: 'Pflichtfeld',
      thisFieldIsReq: "dieses Feld ist erforderlich",
      sendPwdResetLink: "Link zum Zurücksetzen des Passworts",
      adminHomeSubtitle: "Auf dieser Seite können Sie die Informationen zu den Quoten überprüfen und aktualisieren.",
      quotaID: "ID",
      notEligVat: "Nicht vorsteuerabzugsberechtigt",

      landingTitle: 'Willkommen zu unserem Partner Portal',
      landingIntro: 'Nicht verpassen! Verdienen Sie jetzt mit Ihrem E-Auto bis zu {partnerPvtVBasePayAmount} € Jahresbonus! ',
      landingMain: 'Da Sie bereits Kunde von {what} sind, erhalten Sie zusätzlich {thisMany} €. ',
      landingMain2: 'Bitte kontaktieren Sie Ihren Verkaufsberater, um Ihren „Promo-Code“ zu erhalten. ' +
        'Wenn Sie bereits einen „Promo-Code“ bei sich haben, wählen Sie bitte Ihren CO2-Einsparungsbonus-Fußabdruckpfad:',

      landingIntroWl: "Schön zu hören, dass Sie ein 100% eAuto besitzen! Für die CO₂-Einsparung, die Ihr eAuto vermeidet, können Sie jetzt einen Ausgleichsbonus (€) erhalten.",
      landingMainWl: "Sie erhalten 255,- €, wenn Sie bei uns Ihr eAuto registrieren. Die Grundlage hierfür ist eine neue Verordnung der Bundesregierung (Link zur Infoseite des UBA), " +
        "die am 1. Januar 2022 in Kraft getreten ist. Dieser Vorgang wird über die Plattform eCO2wo abgewickelt. " +
        "Die Auszahlung erhalten Sie von uns 14 Tage nach der Bestätigung Ihrer Daten durch das Bundesumweltamt. Dieser Vorgang kann bis zu 3 Monate dauern. " +
        "Dafür müssen Sie nur noch hier Ihre Daten eingeben. Alles weitere erledigen wir für Sie. ",

      landingMainWl2: 'Bitte kontaktieren Sie Ihren Verkaufsberater, um Ihren „Promo-Code“ zu erhalten. ' +
        'Wenn Sie bereits einen „Promo-Code“ bei sich haben, wählen Sie bitte Ihren CO2-Einsparungsbonus-Fußabdruckpfad:',

      agentLinkForPvr: 'Link zur Registrierung von Privatfahrzeugen',
      agentLinkForCfr: 'Link zur Registrierung gewerblicher Flotten',
      agentQRForPvr: 'QR Code zur Registrierung von Privatfahrzeugen',
      agentQRForCfr: 'QR Code zur Registrierung gewerblicher Flotten',
      privateECars: 'Private E-Autos',
      commFleets: 'Kommerzielle Flotten',
      yourPromoCode: 'Ihr Aktionscode',
      commStatus: 'Provisionsstatus',
      commAmount: 'Provisionsbetrag',
      certStatus: "Zertifikatsstatus",
      salesTnc: 'Verkaufsbedingungen',
      markTnc: 'Marketing-AGB',
      yourAccepted: 'Ihre akzeptierten',
      regWithPartnerInstr1: 'Schön zu hören, dass Sie ein 100% Elektroauto besitzen! Für die CO2-Einsparungen, die Ihr Elektroauto vermeidet, können Sie jetzt einen Ausgleichsbonus (€) erhalten.',
      regWithPartnerInstr2: "" + label + " zahlt bis zu {howMuchBasicPayment} €, wenn Sie Ihr eAuto bei uns registrieren. ",
      regWithPartnerInstr3: 'Da Sie bereits Kunde unseres Partners {partnerName} sind, erhalten Sie einen zusätzlichen Bonus von {howMuch} €. ',
      regWithPartnerInstr3p1: 'Sie können es sich auszahlen lassen oder in klimafreundliche Projekte investieren. Alles andere erledigen wir für Sie.',
      regWithPartnerInstr4: 'Die Grundlage hierfür ist eine neue <a href=\"https://www.umweltbundesamt.de/themen/verkehr-laerm/kraft-betriebsstoffe/vollzug-38-bimschv-anrechnung-von-strom-fuer\">Verordnung der Bundesregierung</a> die am 1. Januar 2022 in Kraft tritt.',

      regWithPartnerInstr1Wl:
        "Schön zu hören, dass Sie ein 100% eAuto besitzen! Für die CO₂-Einsparung, die Ihr eAuto vermeidet, können Sie jetzt einen Ausgleichsbonus (€) erhalten.",
      regWithPartnerInstr2Wl:
        "Sie erhalten {howMuchBasicPayment},- €, wenn Sie bei uns Ihr eAuto registrieren. ",
      regWithPartnerInstr3Wl: "Die Grundlage hierfür ist eine neue <a href=\"https://www.umweltbundesamt.de/themen/verkehr-laerm/kraft-betriebsstoffe/vollzug-38-bimschv-anrechnung-von-strom-fuer\">Verordnung der Bundesregierung</a>, die am 1. Januar 2022 in Kraft getreten ist.",

      regWithPartnerInstr3Wl1: "Dieser Vorgang wird über die Plattform eCO2wo abgewickelt. Die Auszahlung erhalten Sie von uns 14 Tage nach der Bestätigung Ihrer Daten durch das Bundesumweltamt. Dieser Vorgang kann bis zu 3 Monate dauern.",
      regWithPartnerInstr4Wl:
        "Dafür müssen Sie nur noch hier Ihre Daten eingeben. Alles weitere erledigen wir für Sie.",


      agentHomeInstrTitle: 'Möglichkeiten, Ihre Kunden zu empfehlen:',
      agentHomeInstr1: 'Authentischer „Promo-Code“, damit Ihr Kunde diesen in unser Hauptregistrierungsformular (privater E-Autobesitzer oder Firmenwagen / gewerblicher Flottenbetreiber) eingeben kann und wir identifizieren können, dass er von Ihnen stammt.',
      agentHomeInstr2: 'Direkter Link zur Webseite mit dem Registrierungsformular (privater E-Autobesitzer oder Firmenwagen / gewerblicher Flottenbetreiber) mit Ihrem bereits enthaltenen Promo-Code.',
      agentHomeInstr3: 'Ein eineindeutiger „QR-Code“, damit Sie ihn in alle physischen Briefe einfügen können, die Sie per Post versenden möchten.',

      agentRegText1: "Wir freuen uns, mit Ihrem Unternehmen zusammenzuarbeiten!<br/><br/>" +
        "Umso mehr freut es uns, dass Sie an unserer Kooperation teilnehmen wollen, indem Sie Ihren bestehenden/neuen Kunden helfen, einen Jahresbonus von bis zu 300 € zu verdienen, allein schon durch den Besitz eines 100 % Elektroautos.<br/><br/>" +
        "Im Gegenzug zahlen wir Ihnen für jedes eAuto, das Ihre Kunden auf unserer Plattform registrieren und das von den Behörden validiert wird, eine Provision.<br/><br/>" +
        "Wir haben es Ihnen ganz leicht gemacht! Sobald Sie sich bei uns registriert haben, können Sie nicht nur die Übersicht und den Status all Ihrer geworbenen Kunden einsehen, sondern haben auch viele weitere Möglichkeiten, sie an uns zu verweisen: " +
        "<il> <li>Authentischer „Promo-Code“, damit Ihr Kunde diesen in unser Hauptregistrierungsformular (privater E-Autobesitzer oder Firmenwagen / gewerblicher Flottenbetreiber) eingeben kann und wir ihn als Ihren Kunden identifizieren können. " +
        "</li><li>Direkter Link zum Registrierungsformular (privater E-Autobesitzer oder Firmenwagen / gewerblicher Flottenbetreiber) mit Ihrem bereits enthaltenen Promo-Code für den Versand an den Kunden. " +
        "</li><li>Ein eineindeutiger „QR-Code“, damit Sie ihn in alle physischen Briefe einfügen können, die Sie per Post versenden möchten." +
        "</li></il><br/>Die Grundlage hierfür ist eine neue <a href=\"https://lnkd.in/eTpP2Npm anrechnung-von-strom-fuer#hintergrund\">Verordnung der Bundesregierung</a> die am 1. Januar 2022 in Kraft tritt. " +
        "<br/><br/>",
      agentRegText2: 'Für weitere Informationen lesen Sie bitte die ',
      agentRegText3: '„AGBs“',
      agentRegText3m: '„AGBs“',
      promoCode: 'Promo Code',
      registerAgent: "Als Agent registrieren",
      userPassIncorrect: 'Der Benutzername oder das Passwort sind nicht korrekt',
      errorTryLater: 'Es gab einen Fehler. Bitte versuch es später.',
      officeId: "Büro-ID",
      agentId: "Berater-ID",
      licPlateReq: 'Nummernschild ist erforderlich',
      bothFilesMand: "Beide Dateien sind erforderlich",
      page3Nstep1: 'Wir werden dir eine Email für die Aktivierung deine Accounts schicken. Nachdem du deine Adresse verifiziert hast, kannst du dich in deinen Account einloggen.',
      page3Nstep2: ' Unser Team wird die von dir angegebenen Informationen im Austausch mit dem Umweltbundesamt überprüfen, um mit der Zahlung fortzufahren.',
      page3Nstep3: 'In der Zwischenzeit kannst du deinen Status überprüfen, einen Freund empfehlen und weitere Fahrzeuge hinzufügen, indem du dich in dein Konto einloggst.',
      vehicles: 'Fahrzeuge',
      regDir1: " Fotographiere oder scanne die Vorder- und Rückseite deines Fahrzeugscheins.",
      regDir2: "Lade beide Bilder hoch.",
      regDir3: "Trage Deine Daten ein und klicke auf weiter.",
      regDir4: "Wir setzen für Sie die die 38. Bundesimmissions-schutzverordnung (BImSchV Nr. 38) durch. ",
      readMore: "Mehr.",

      toChangePassClick: "Um Ihr Passwort zu ändern, klicken Sie ",
      dashboard: "Armaturenbrett",
      company: "Firma",
      nextStepsHeader: "NÄCHSTE SCHRITTE :",
      homeInstrLine1:
        "Wir werden die eine Email für die Aktivierung deine Accounts schicken. Nachdem du deine Adresse verifiziert hast, kannst du dich in deinen Account einloggen.",
      homeInstrLine2:
        " Unser Team wird die von dir angegebenen Informationen im Austausch mit dem Umweltbundesamt überprüfen, um mit der Zahlung fortzufahren.",
      homeInstrLine3:
        "In der Zwischenzeit kannst du deinen Status überprüfen, einen Freund empfehlen und weitere Fahrzeuge hinzufügen, indem du dich in dein Konto einloggst.",
      homeInstrLine4: "Ihr Zertifikat wird mehrere Phasen durchlaufen:",
      homeInstrLine5:
        "Anfangsphase. In dieser Phase können Sie Ihre Daten noch bearbeiten.",
      homeInstrLine6:
        "Unser Team prüft die Informationen und Unterlagen, die Sie den Behörden zur Verfügung gestellt haben. Sie können nichts mehr bearbeiten.",
      homeInstrLine7:
        "Einige der von Ihnen gemachten Angaben sind nicht korrekt und konnten nicht validiert werden. Hier MÜSSEN Sie Ihre Informationen und Unterlagen bearbeiten, damit wir sie überprüfen und erneut validieren können.",
      homeInstrLine8:
        "Die von Ihnen zur Verfügung gestellten Informationen und Unterlagen sind korrekt und wurden von den Regierungsbehörden bestätigt. In Kürze werden Sie entweder bezahlt oder wir spenden das Geld an eine der von Ihnen ausgewählten NGOs.",
      homeInstrLine9:
        "Die Zahlung wurde entweder auf Ihr Bankkonto oder an eine der von Ihnen ausgewählten NGOs überwiesen.",

      certId: "Zertifikats-ID",
      certificates: "Bescheinigungen",
      type: "Typ",
      email: "Email",
      fname: "Vorname",
      lname: "Nachname",
      password: "Passwort",
      enterYourFirstName: "Geben Sie Ihren Vornamen ein",
      enterYourLastName: "Geben Sie Ihren Nachnamen ein",
      enterYourEmail: "Geben Sie Ihre E-Mail Adresse ein",
      choosePassword: "Wählen Sie ein Passwort",
      agreeTo: "Ich stimme den " + label + " ",
      agreeTo2: " und den ",
      agreeTo3: " zu.",
      licplate: "Nummernschild",
      companyName: "Firmenname",
      address: "Adresse",
      vatNum: "U-St.-ID",
      companyId: "Handelsregister ID",
      city: "Stadt",
      postcode: "PLZ",
      doc1: "Dokument 1",
      doc2: "Dokument 2",
      docs: "Dokumente",
      vin: "Fahrzeug-Identifizierungsnummer (FIN)",
      paymentMethod: "Auszahlungsoptionen",
      submDate: "Antragsdatum",
      mwhGen: "Produzierte MWh",
      tco2Saved: "Eingesparte Tonnen CO2",
      paymentAmount: "Auszahlungsbetrag",
      bankName: "Bank",
      recipientName: "Empfängername",
      iban: "IBAN",
      status: "Status",
      sold: "ausgezahlt",
      sellerId: "Verkäufer ID",
      editDelete: "Edit / löschen",
      referralBonus: "Empfehlungsbonus",
      tnc: "AGBs",
      dataPrivacy: "Datenschutz-Bedingungen",
      custSettings: "Einstellungen",
      recAFriend: "Freunde werben",
      custService: "Kundenservice",
      changePassword: "Passwort ändern",
      vehicle: "Fahrzeug",
      car: "Auto",
      truck: "Lastkraftwagen",
      Car: "Auto",
      Truck: "Lastkraftwagen",
      removeLast: "Letztes {what} entfernen",
      next: "Weiter",
      referralsEmail: "Email für Weiterempfehlung",
      withlicenseplate: "mit Nummernschild",
      register: "Registrieren",
      regPage3TheFolow:
        "Die folgenden {what} werden in Ihrem Konto registriert:",
      step: "Schritt",
      regAsComFle: "Registrierung als gewerblicher Flottenbesitzer",
      regAsPvtO: "Als Privateigentümer registrieren",
      country: "Land",
      welcomeBack: "Willkommen zurück ",
      frontscan: "Scan der Vorderseite Fahrzeugschein",
      backscan: "Scan der Rückseite Fahrzeugschein ",
      thirdDoc: 'Scan das Homologations dokument',
      attachments: 'Anhänge',
      shortFrontscan: 'VIN front',
      shortBackscan: 'VIN back',
      shortThirdDoc: 'Zulassung',
      uploadAFile: "Bild hochladen",
      succReg: "Sie sind erfolgreich registriert!",
      succReg2:
        "Wir haben Ihnen eine Bestätigungs-E-Mail geschickt. Bitte klicken Sie auf den Link in der E-Mail, um Ihr Konto zu bestätigen.",
      editAccount: "Konto bearbeiten",
      payoutToIban: "Auszahlung",
      dtNGO01: "Spende für Klimaschutzprojekte",
      dtNGO02: "Spenden an MyClimate",
      add: "Hinzufügen",
      addWhat: "{what} hinzufügen ",
      edit: "Ändern",
      referrals: "Empfehlungen",
      addReferral: "Überweisung hinzufügen",
      about: "Über unsere Plattform",
      contactUs: "Kontakt",
      addNewVehicleInfo: "Neue Fahrzeuginformationen hinzufügen",
      editVehicleInfo: "Fahrzeuginformationen bearbeiten",
      save: "Speichern",
      referRFriend: "Empfehlen Sie einen Freund",
      accInfo: "Konto-Informationen",
      accSubt:
        "Auf dieser Seite können Sie die Informationen zu Ihrem Konto bearbeiten.",
      curPass: "Aktuelles Kennwort",
      codeFromEmail: "Code aus E-Mail",
      newPassword: "Neues Passwort",
      confiPass: "Passwort bestätigen",

      referralsSubTitle:
        "Haben Sie bereits gute Erfahrungen mit unserer Plattform gemacht? Möchten Sie bei uns einen zusätzlichen Bonus von 50 € verdienen? Haben Sie einen Freund, der ein E-Auto besitzt? Dann empfehlen Sie uns Ihren Freunden und Bekannten, indem Sie uns einfach ihre E-Mail-Adresse mitteilen!",
      totalAmountWillBe: "Ihre Prämie beläuft sich auf ",
      totalAmount2: "Wir spenden zudem weitere {fortyFiveTimes}€ für Klimaschutzprojekte mit MyClimate und vermeiden weitere {tonsTimes}t CO2",
      plsChooseWayOP:
        "Bitte beachte: Du kannst weitere Klimaschutz oder Sozialprojekte unterstützen in dem du deine Prämie an einen <a>unserer Partner</a> spendest.",
      wop: "Art der Bezahlung",
      youCanReferLongText1:
        "Du kannst deine Freunde oder Bekannten werben und einen zusätzlichen Empfehlungsbonus von ",
      youCanReferLongText2:
        " Euro pro Empfehlung verdienen. Du erhältst deinen Bonus, sobald sich dein Kontakt unserer Website anmeldet und seine Daten den Validierungsprozess durchlaufen haben.",
      addAnotherRef: "Weitere Empfehlung",
      subtitlePlsChangePass:
        "Bitte benutzen Sie diese Seite, um ein neues Passwort festzulegen.",

      regPage3nextSteps:
        'Nächste Schritte:<ul style="padding-left: 1em;\'">' +
        "<li>Wir schicken Ihnen eine E-Mail, um Ihr Konto zu aktivieren. Nachdem Sie auf den Link in der E-Mail geklickt haben, können Sie sich in Ihr Dashboard einloggen.</li>" +
        "<li>Unsere Teams werden die von Ihnen angegebenen Informationen überprüfen, um mit der Zahlungsphase fortzufahren.</li>" +
        "<li>In der Zwischenzeit können Sie weitere Fahrzeuge hinzufügen, wenn Sie sich in Ihrem Konto anmelden.</li>" +
        "</ul>",

      nextSteps:
        "Nächste Schritte:" +
        '<ul style="padding-left: 1em;\'">' +
        "<li>Unsere Teams werden die von Ihnen angegebenen Informationen überprüfen, um mit der Zahlungsphase fortzufahren.</li>" +
        "<li>In der Zwischenzeit können Sie weitere Informationen hinzufügen, wenn Sie sich bei Ihrem Konto anmelden.</li>" +
        "<li>" +
        "Ihre Quote durchläuft mehrere Phasen, die hier beschrieben werden:" +
        "<ol>" +
        "<li>In Bearbeitung - dies ist die Anfangsphase. Während sich Ihre Quote in dieser Phase befindet, können Sie die Informationen noch bearbeiten, zum Beispiel, wenn Sie einen Tippfehler oder ähnliches gemacht haben.</li>" +
        "<li>Überprüfung - in dieser Phase überprüfen unsere Teams die von Ihnen bereitgestellten Informationen. Sie können die Quotendetails nicht mehr aktualisieren.</li>" +
        "<li>Gültig gemacht - dies bedeutet, dass die Quoteninformationen überprüft wurden und wir bald mit der Zahlung fortfahren werden.</li>" +
        "<li>Bezahlt - die Zahlung ist abgeschlossen, entweder auf Ihre IBAN oder auf eine der NGOs, je nach Ihrer Wahl.</li>" +
        "</ol>" +
        "</li>" +
        'Es gibt einen weiteren Status, in dem Ihre Quote erscheinen kann: "abgelehnt". Das bedeutet, dass einige der von Ihnen gemachten Angaben nicht korrekt sind und nicht validiert werden konnten.' +
        "In dieser Phase können Sie die Quoteninformationen bearbeiten, damit wir versuchen können, sie erneut zu validieren." +
        "</ul>",
      forgotPassword: "Passwort vergessen",
      login: "Log In",
      emailAInUse: "E-Mail wird bereits verwendet",
      statusInProcess: "In Bearbeitung",
      statusReviewing: "Überprüfung",
      statusRejected: "Abgelehnt",
      statusValidated: "Validiert",
      statusPaid: "Bezahlt",
      statusPaymentInitiated: "Payment Initiated",
      statusPaymentFailed: "Payment Failed",
      referralStatusEmailed: "E-Mail gesendet",
      referralStatusSignedUp: "Registriert",
      referralStatusCO2SaverValidated: "Bestätigt",
      referralStatusToBePaid: "Bezahlt Werden",
      referralStatusPaid: "Bezahlt",
      footerText: "Die Plattform, die über die Einsparung von CO2-Emissionen verhandelt und dazu beiträgt, dass die Welt bis 2050 klimaneutral wird.",
      footerCopyright: "Copyright |" + label + "| 2022",
      imprint: "Impressum",
      rtWith: "Widerrufsrecht",
      confirmPassword: "Passwort wiederholen",
      ...bvs.msgDe
    },
  };

  document.documentElement.style.setProperty("--fontMainColor", bvs.mainFontColor);
  document.documentElement.style.setProperty("--fontMenuColor", bvs.menuFontColor);
  document.documentElement.style.setProperty("--footerBgColor", bvs.headerBackground);

  return (
    <div style={{ height: "100%" }}>
      <IntlProvider locale={lang} messages={messages[lang]}>
        <Router>
          <div id="appDiv">
            <nav
              className="navbar navbar-expand"
              style={{
                background: bvs.headerBackground,
              }}>
              <div className="navbar-logo">
                <img src={brandVisualSettings.logoImage.src} alt="brand logo" width="auto" style={{ width: 'auto', height: '31px' }} />
              </div>
              <div className="navbar-nav ml-auto">
                {currentUser ? (
                  <></>
                ) : (
                  <>
                    <li className="nav-item">
                      <a
                        id="about"
                        className="nav-link"
                        href={brandVisualSettings.hostUri}>
                        Home
                        {/*<FormattedMessage id='about' />*/}
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link to={"/login"} className="nav-link">
                        <FormattedMessage id="login" />
                      </Link>
                    </li>

                    {!(["/", "/login"].includes(window.location.pathname)) &&
                      <li className="nav-item">
                        <Link to={"/first?ut=po"} className="nav-link">
                          <FormattedMessage id="register" />
                        </Link>
                      </li>
                    }
                  </>
                )}
                {showAdminBoard && (
                  <li className="nav-item">
                    <a id="admin" className="nav-link" href="/admin">
                      Certificates
                    </a>
                  </li>
                )}
                {currentUser && !showAdminBoard && (
                  <li className="nav-item">
                    <a
                      id="user"
                      className={
                        "nav-link" +
                        (["/user", "/profile"].includes(
                          window.location.pathname
                        )
                          ? " active"
                          : "")
                      }
                      href="/profile">
                      <FormattedMessage id="dashboard" />
                    </a>
                  </li>
                )}
                {showAdminBoard && (
                  <li className="nav-item">
                    <a
                      id="adminSettings"
                      className="nav-link"
                      href="/adminSettings">
                      Admin Settings
                    </a>
                  </li>
                )}
                {showAdminBoard && isSuperAdmin && (
                  <li className="nav-item">
                    <a id="sellQuotas" className="nav-link" href="/sellQuotas">
                      Sell Quotas To Buyers
                    </a>
                  </li>
                )}
                {showAdminBoard && isSuperAdmin && (
                  <li className="nav-item">
                    <a
                      id="buyersInvoices"
                      className="nav-link"
                      href="/buyersInvoices">
                      Buyers Invoices
                    </a>
                  </li>
                )}
                {showAdminBoard && (
                  <li className="nav-item">
                    <a
                      id="adminReferrals"
                      className="nav-link"
                      href="/adminReferrals">
                      Referrals
                    </a>
                  </li>
                )}
                {showAdminBoard && (
                  <li className="nav-item">
                    <a id="adminPartners" className="nav-link" href="/adminPartners">
                      Partners
                    </a>
                  </li>
                )}
                {showAdminBoard && (
                  <li className="nav-item">
                    <a id="adminAgents" className="nav-link" href="/adminAgents">
                      Agents
                    </a>
                  </li>
                )}
                {showAdminBoard && (
                  <li className="nav-item">
                    <a id="adminAgentsQuotas" className="nav-link" href="/partnersQuotas">
                      Commissions
                    </a>
                  </li>
                )}
                {showAdminBoard && (
                  <li className="nav-item">
                    <a id="adminStats" className="nav-link" href="/adminStats">
                      Statistics
                    </a>
                  </li>
                )}
                {currentUser && !showAdminBoard && showReferrals && (
                  <li className="nav-item">
                    <a
                      id="user"
                      href="/referrals"
                      className={
                        "nav-link" +
                        (window.location.pathname === "/referrals"
                          ? " active"
                          : "")
                      }>
                      <FormattedMessage id="referRFriend" />
                    </a>
                  </li>
                )}
                {currentUser && !showAdminBoard && (
                  <li className="nav-item">
                    <a
                      id="editAccount"
                      className={
                        "nav-link" +
                        (window.location.pathname === "/editAccount"
                          ? " active"
                          : "")
                      }
                      href="/editAccount">
                      <FormattedMessage id="editAccount" />
                    </a>
                  </li>
                )}
                {currentUser && !showAdminBoard && (
                  <li className="nav-item">
                    <a id="about" className="nav-link" href={bvs.infoDomain}>
                      {currentUser ? "FAQ" : "Home"}
                      {/*<FormattedMessage id='about' />*/}
                    </a>
                  </li>
                )}
                {currentUser && !showAdminBoard && (
                  <li className="nav-item">
                    <a
                      id="contact"
                      className="nav-link"
                      href={bvs.linkContactUs}>
                      <FormattedMessage id="contactUs" />
                    </a>
                  </li>
                )}
                {currentUser && (
                  <li className="nav-item">
                    <a href="/login" className="nav-link" onClick={logOut}>
                      Logout
                    </a>
                  </li>
                )}
              </div>
              <select
                className="language-select"
                value={lang}
                onChange={(e) => updateLanguage(e.target.value)}>
                <option value="en">ENG</option>
                <option value="de">DE</option>
              </select>
            </nav>
            <div id="blabla">
              <Menu pageWrapId="blabla" menuClassName={"menuClass"} right>
                {!currentUser && (
                  <a id="reg" className="menu-item" href="/first?ut=po">
                    <FormattedMessage id="register" />
                  </a>
                )}
                {(!currentUser) && (
                  <a id="login" href={"/login"} className="menu-item">
                    <FormattedMessage id="login" />
                  </a>
                )}
                {showAdminBoard && (
                  <a id="admin" className="menu-item" href="/admin">
                    Certificates
                  </a>
                )}
                {currentUser && !showAdminBoard && (
                  <a
                    id="user"
                    className={
                      "menu-item" +
                      (["/user", "/profile"].includes(window.location.pathname)
                        ? " active"
                        : "")
                    }
                    href="/user">
                    <FormattedMessage id="certificates" />
                  </a>
                )}
                {showAdminBoard && (
                  <a
                    id="adminSettings"
                    className="menu-item"
                    href="/adminSettings">
                    Admin Settings
                  </a>
                )}
                {showAdminBoard && isSuperAdmin && (
                  <a id="sellQuotas" className="menu-item" href="/sellQuotas">
                    Sell Quotas To Buyers
                  </a>
                )}
                {showAdminBoard && isSuperAdmin && (
                  <a
                    id="buyersInvoices"
                    className="menu-item"
                    href="/buyersInvoices">
                    Buyers Invoices
                  </a>
                )}
                {showAdminBoard && (
                  <a
                    id="adminReferrals"
                    className="menu-item"
                    href="/adminReferrals">
                    Referrals
                  </a>
                )}
                {showAdminBoard && (
                  <a id="adminStats" className="menu-item" href="/adminStats">
                    Statistics
                  </a>
                )}
                {currentUser && !showAdminBoard && showReferrals && (
                  <a
                    id="user"
                    className={
                      "menu-item" +
                      +(window.location.pathname === "/referrals"
                        ? " active"
                        : "")
                    }
                    href="/referrals">
                    <FormattedMessage id="referrals" />
                  </a>
                )}
                {currentUser && !showAdminBoard && (
                  <a
                    id="editAccount"
                    className={
                      "menu-item" +
                      (window.location.pathname === "/editAccount"
                        ? " active"
                        : "")
                    }
                    href="/editAccount">
                    <FormattedMessage id="editAccount" />
                  </a>
                )}
                {currentUser && (
                  <a
                    id="changePass"
                    className="menu-item"
                    href="/setNewPassword">
                    <FormattedMessage id="changePassword" />
                  </a>
                )}
                {!currentUser && (
                  <a id="about" className="menu-item" href={bvs.infoDomain}>
                    Home
                    {/*<FormattedMessage id='about' />*/}
                  </a>
                )}
                {currentUser && (
                  <a
                    id="contact"
                    className="menu-item"
                    href={bvs.linkContactUs}>
                    <FormattedMessage id="contactUs" />
                  </a>
                )}
                {/*<a onClick={showSettings} className="menu-item--small" href="">Other</a>*/}
                {currentUser && (
                  <a id="profile" href="/profile" className="menu-item">
                    {currentUser.username}
                  </a>
                )}
                {currentUser && (
                  <a
                    id="logout"
                    href="/login"
                    className="menu-item"
                    onClick={logOut}>
                    Logout
                  </a>
                )}
                <div className="language-select-mobile">
                  <select
                    value={lang}
                    onChange={(e) => updateLanguage(e.target.value)}>
                    <option value="en">ENG</option>
                    <option value="de">DE</option>
                  </select>
                </div>
              </Menu>
              <div className="container pt-4">
                <Switch>
                  <Route
                    exact
                    path={["/", "/home"]}
                    render={(props) =>
                      currentUser
                        ? showAdminBoard
                          ? <BoardAdmin {...props} isSuperAdmin={isSuperAdmin} adminEditAllData={bvs.adminEditAllData} />
                          : (showAgentBoard ? <AgentHome {...props} hostUri={bvs.hostUri} /> : <BoardUser {...props} />)
                        : <Login {...props} />
                    }
                  />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/register" component={Register} />
                  <Route
                    exact
                    path="/profile"
                    render={(props) =>
                      currentUser
                        ? showAdminBoard
                          ? <BoardAdmin {...props} isSuperAdmin={isSuperAdmin} adminEditAllData={bvs.adminEditAllData} />
                          : (showAgentBoard ? <AgentHome {...props} hostUri={bvs.hostUri} /> : <BoardUser {...props} />)
                        : <Login {...props} />
                    }
                  />
                  <Route path="/user" component={BoardUser} />
                  <Route path="/editAccount" component={
                    currentUser ? (!showAgentBoard ? EditAccount : EditAgentAccount) : Login} />

                  <Route path="/admin"
                    render={(props) => (
                      <BoardAdmin
                        {...props}
                        exact={true}
                        isSuperAdmin={isSuperAdmin}
                        adminEditAllData={bvs.adminEditAllData}
                      />
                    )} />

                  <Route path="/setNewPassword" component={SetNewPassword} />
                  <Route path="/forgotPassword" component={ForgotPassword} />

                  <Route path="/adminSettings"
                    render={(props) => (
                      <AdminSettings
                        {...props}
                        exact={true}
                        isSuperAdmin={isSuperAdmin}
                        adminEditAllData={bvs.adminEditAllData}
                      />
                    )} />

                  <Route path="/sellQuotas" component={SellQuotas} />
                  <Route
                    path="/buyersInvoices"
                    component={BuyersInvoicesList}
                  />
                  <Route path="/referrals" component={Referrals} />

                  <Route path="/adminReferrals"
                    render={(props) => (
                      <AdminReferrals
                        {...props}
                        exact={true}
                        isSuperAdmin={isSuperAdmin}
                      />
                    )} />

                  <Route path="/adminStats"
                    render={(props) => (
                      <AdminStats
                        {...props}
                        exact={true}
                        isSuperAdmin={isSuperAdmin}
                      />
                    )}/>

                  <Route path="/adminPartners" component={AdminPartners} />
                  <Route path="/adminAgents" component={AdminAgents} />
                  <Route path="/partnersQuotas" component={AdminQuotasPartners} />
                  <Route path="/land" component={Landing} />

                  <Route path="/regAgent"
                    render={(props) => (
                      <RegAgent
                        {...props}
                        exact={true}
                        lang={lang}
                      />
                    )} />
                  <Route
                    path="/first"
                    render={(props) => (
                      <div className="content-container">
                        <Header {...props} exact={true} user={user} agentUser={agentUser} />
                        <FirstStep setAgentUser={setAgentUser}
                          {...props}
                          exact={true}
                          user={user}
                          updateUser={updateUser}
                          bvs={bvs}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path="/second"
                    exact={true}
                    render={(props) => (
                      <div className="content-container">
                        <Header {...props} exact={true} user={user} agentUser={agentUser} />
                        <SecondStep
                          {...props}
                          user={user}
                          updateUser={updateUser}
                          bvs={bvs}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path="/third"
                    exact={true}
                    render={(props) => (
                      <div className="content-container">
                        <Header {...props} exact={true} user={user} agentUser={agentUser} />
                        <ThirdStep
                          {...props}
                          user={user}
                          updateUser={updateUser}
                          resetUser={resetUser}
                          lang={lang}
                          ourNgosLink={bvs.ourNgosLink}
                          bvs={bvs}
                        />
                      </div>
                    )}
                  />
                </Switch>
              </div>
            </div>
            <Footer logoImage={brandVisualSettings.logoImage}></Footer>
          </div>
        </Router>
      </IntlProvider>
    </div>
  );
};

export default App;
