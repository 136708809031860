import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useIntl } from 'react-intl';
import { selectUser, selectIsLoggedIn } from '../../slices/auth';
import { useSelector, useDispatch } from 'react-redux';

import {
  saveAdminSettingsAsync,
  fetchAdminSettingsAsync,
  selectAdminSettings,
  selectSaveAdminSettingsStatus,
  selectSaveSellerAccountResult
} from './adminHomeSlice';

var saveButtonClicked = false;

const AdminSettings = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const adminSettings = useSelector(selectAdminSettings);
  const saveAdminSettingsStatus = useSelector(selectSaveAdminSettingsStatus);
  const user = useSelector(selectUser);
  const [wayOfPayment, setWayOfPayment] = useState('IBAN');
  const intl = useIntl(); //intl.formatMessage({ id: 'certId' })
  const adminEditAllData = props.adminEditAllData;
  console.log({ ddfdffd: adminEditAllData});
  const { register, errors, handleSubmit, reset } = useForm({
    defaultValues: adminSettings
  });

  const onSubmit = async (data, event) => {
    event.preventDefault();
    dispatch(saveAdminSettingsAsync({
      data: {
        ...data,
        id: adminSettings.id
      }
    }));
  };

  if (!user) {
    props.history.push('/login');
  }

  useEffect(() => {
    dispatch(fetchAdminSettingsAsync())
  }, []);

  useEffect(() => {
    if (adminSettings) {
      reset(adminSettings);
    }
  }, [adminSettings])

  useEffect(() => {
    
    if (saveAdminSettingsStatus === 'idle' && saveButtonClicked) {
      saveButtonClicked = false;
      Swal.fire('Awesome!', "You updated Admin settings!", 'success').then(
        (result) => {
          if (result.isConfirmed || result.isDismissed) {
            props.history.push('/admin');
          }
        }
      );
      return;
    }

    if (saveAdminSettingsStatus === 'loading') {
      saveButtonClicked = true;
    }
  });

  var waysOfPayment = [
    { isoCode: 'NGO01', name: intl.formatMessage({ id: 'dtNGO01' }) },
  ];

  return (
    <div>
      <header className="">
        <h3>Admin Settings</h3>
        You can use this page to adjust default values and other system settings.
      </header>
      <div className="col-md-6 offset-md-3" >
        <Form className="input-form" onSubmit={handleSubmit(onSubmit)}>
          {adminEditAllData &&
            <Form.Group controlId="emailClientsGrp">
              <Form.Label>Email for Admin info</Form.Label>
              <Form.Control
                type="email"
                name="emailForAdminInfo"
                autoComplete="off"
                ref={register({
                  required: "Email is required.",
                  pattern: {
                    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                    message: "Email is not valid.",
                  },
                })}
                className={`${errors.emailForAdminInfo ? "input-error" : ""}`}
              />
              {errors.emailForAdminInfo && <p className="errorMsg">{errors.emailForAdminInfo.message}</p>}
            </Form.Group>
          }
          <Form.Group controlId="pvtCarTCo2Saved">
            <Form.Label>Private Vehicle tCO2 Saved</Form.Label>
            <Form.Control
              type="text"
              name="pvtCarTCo2Saved"
              placeholder="Enter Private Vehicle tCO2 Saved"
              autoComplete="off"
              ref={register({
                required: 'Private Vehicle tCO2 Saved is required.',
                pattern: {
                  value: /^[0-9\\.]+$/,
                  message: 'Private Vehicle tCO2 Saved should contain only numbers.'
                }
              })}
              className={`${errors.pvtCarTCo2Saved ? 'input-error' : ''}`}
            />
            {errors.pvtCarTCo2Saved && (
              <p className="errorMsg">{errors.pvtCarTCo2Saved.message}</p>
            )}
          </Form.Group>
          <Form.Group controlId="pvtCarPaymentAmountGr">
            <Form.Label>Private Vehicle Payment Amount (NOT Tax Optimized)</Form.Label>
            <Form.Control
              type="text"
              name="pvtCarPaymentAmount"
              placeholder="Enter Private Vehicle Payment Amount"
              autoComplete="off"
              ref={register({
                required: 'Private Vehicle Payment Amount is required.',
                pattern: {
                  value: /^[0-9\\.]+$/,
                  message: 'Private Vehicle Payment Amount should contain only numbers.'
                }
              })}
              className={`${errors.pvtCarPaymentAmount ? 'input-error' : ''}`}
            />
            {errors.pvtCarPaymentAmount && (
              <p className="errorMsg">{errors.pvtCarPaymentAmount.message}</p>
            )}
          </Form.Group>
          <Form.Group controlId="pvtCarPartialPaymentAmountGr">
            <Form.Label>Private Vehicle Payment Amount (Tax Optimized)</Form.Label>
            <Form.Control
              type="text"
              name="pvtCarPartialPaymentAmount"
              placeholder="Enter Private Vehicle Payment Amount (Tax Optimized)"
              autoComplete="off"
              ref={register({
                required: 'Amount is required.',
                pattern: {
                  value: /^[0-9\\.]+$/,
                  message: 'Amount should contain only numbers.'
                }
              })}
              className={`${errors.pvtCarPartialPaymentAmount ? 'input-error' : ''}`}
            />
            {errors.pvtCarPartialPaymentAmount && (
              <p className="errorMsg">{errors.pvtCarPartialPaymentAmount.message}</p>
            )}
          </Form.Group>
          <Form.Group controlId="pvtCarPartialDonateAmGr">
            <Form.Label>Private Vehicle Donate Amount (Remaining from Tax Optimized)</Form.Label>
            <Form.Control
              type="text"
              name="pvtCarPartialDonateAm"
              placeholder="Enter Private Vehicle Donate Amount"
              autoComplete="off"
              ref={register({
                required: 'Amount is required.',
                pattern: {
                  value: /^[0-9\\.]+$/,
                  message: 'Amount should contain only numbers.'
                }
              })}
              className={`${errors.pvtCarPartialDonateAm ? 'input-error' : ''}`}
            />
            {errors.pvtCarPartialDonateAm && (
              <p className="errorMsg">{errors.pvtCarPartialDonateAm.message}</p>
            )}
          </Form.Group>
          <Form.Group controlId="fleetCarTCo2Saved">
            <Form.Label>Commercial Fleet Car tCO2 Saved</Form.Label>
            <Form.Control
              type="text"
              name="fleetCarTCo2Saved"
              placeholder="Enter Commercial Fleet Car tCO2 Saved"
              autoComplete="off"
              ref={register({
                required: 'Commercial Fleet Car tCO2 Saved is required.',
                pattern: {
                  value: /^[0-9\\.]+$/,
                  message: 'Commercial Fleet Car tCO2 Saved should contain only numbers.'
                }
              })}
              className={`${errors.fleetCarTCo2Saved ? 'input-error' : ''}`}
            />
            {errors.fleetCarTCo2Saved && (
              <p className="errorMsg">{errors.fleetCarTCo2Saved.message}</p>
            )}
          </Form.Group>
          <Form.Group controlId="fleetCarPaymentAmount">
            <Form.Label>Commercial Fleet Car Payment Amount</Form.Label>
            <Form.Control
              type="text"
              name="fleetCarPaymentAmount"
              placeholder="Enter Commercial Fleet Car Payment Amount"
              autoComplete="off"
              ref={register({
                required: 'Commercial Fleet Car Payment Amount is required.',
                pattern: {
                  value: /^[0-9\\.]+$/,
                  message: 'First name should contain only numbers.'
                }
              })}
              className={`${errors.fleetCarPaymentAmount ? 'input-error' : ''}`}
            />
            {errors.fleetCarPaymentAmount && (
              <p className="errorMsg">{errors.fleetCarPaymentAmount.message}</p>
            )}
          </Form.Group>
          <Form.Group controlId="fleetBusTCo2Saved">
            <Form.Label>Commercial Fleet Bus tCO2 Saved</Form.Label>
            <Form.Control
              type="text"
              name="fleetBusTCo2Saved"
              placeholder="Enter Commercial Fleet Bus tCO2 Saved"
              autoComplete="off"
              ref={register({
                required: 'Commercial Fleet Bus tCO2 Saved is required.',
                pattern: {
                  value: /^[0-9\\.]+$/,
                  message: 'Commercial Fleet Bus tCO2 Saved should contain only numbers.'
                }
              })}
              className={`${errors.fleetBusTCo2Saved ? 'input-error' : ''}`}
            />
            {errors.fleetBusTCo2Saved && (
              <p className="errorMsg">{errors.fleetBusTCo2Saved.message}</p>
            )}
          </Form.Group>
          <Form.Group controlId="fleetBusPaymentAmount">
            <Form.Label>Commercial Fleet Bus Payment Amount</Form.Label>
            <Form.Control
              type="text"
              name="fleetBusPaymentAmount"
              placeholder="Enter Commercial Fleet Bus Payment Amount"
              autoComplete="off"
              ref={register({
                required: 'Commercial Fleet Bus Payment Amount is required.',
                pattern: {
                  value: /^[0-9\\.]+$/,
                  message: 'First name should contain only numbers.'
                }
              })}
              className={`${errors.fleetBusPaymentAmount ? 'input-error' : ''}`}
            />
            {errors.fleetBusPaymentAmount && (
              <p className="errorMsg">{errors.fleetBusPaymentAmount.message}</p>
            )}
          </Form.Group>
          <Form.Group controlId="fleetTruckTCo2Saved">
            <Form.Label>Commercial Fleet Truck tCO2 Saved</Form.Label>
            <Form.Control
              type="text"
              name="fleetTruckTCo2Saved"
              placeholder="Enter Commercial Fleet Truck tCO2 Saved"
              autoComplete="off"
              ref={register({
                required: 'Commercial Fleet Truck tCO2 Saved is required.',
                pattern: {
                  value: /^[0-9\\.]+$/,
                  message: 'Commercial Fleet Truck tCO2 Saved should contain only numbers.'
                }
              })}
              className={`${errors.fleetTruckTCo2Saved ? 'input-error' : ''}`}
            />
            {errors.fleetTruckTCo2Saved && (
              <p className="errorMsg">{errors.fleetTruckTCo2Saved.message}</p>
            )}
          </Form.Group>
          <Form.Group controlId="fleetTruckPaymentAmount">
            <Form.Label>Commercial Fleet Truck Payment Amount</Form.Label>
            <Form.Control
              type="text"
              name="fleetTruckPaymentAmount"
              placeholder="Enter Commercial Fleet Truck Payment Amount"
              autoComplete="off"
              ref={register({
                required: 'Commercial Fleet Truck Payment Amount is required.',
                pattern: {
                  value: /^[0-9\\.]+$/,
                  message: 'First name should contain only numbers.'
                }
              })}
              className={`${errors.fleetTruckPaymentAmount ? 'input-error' : ''}`}
            />
            {errors.fleetTruckPaymentAmount && (
              <p className="errorMsg">{errors.fleetTruckPaymentAmount.message}</p>
            )}
          </Form.Group>
          <Form.Group controlId="referralPayment">
            <Form.Label>Referral Payment Amount</Form.Label>
            <Form.Control
              type="text"
              name="referralPayment"
              placeholder="Enter Referral Payment Amount"
              autoComplete="off"
              ref={register({
                required: 'Referral Payment Amount is required.',
                pattern: {
                  value: /^[0-9\\.]+$/,
                  message: 'First name should contain only numbers.'
                }
              })}
              className={`${errors.referralPayment ? 'input-error' : ''}`}
            />
            {errors.referralPayment && (
              <p className="errorMsg">{errors.referralPayment.message}</p>
            )}
          </Form.Group>


          <Button variant="primary" type="submit">Save</Button>
        </Form >
      </div>
    </div>
  );
};

export default AdminSettings;
