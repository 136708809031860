import http, { newAxiosWithJwt } from "../../http-common";

export async function fetchVehicles(user) {
  return newAxiosWithJwt(user).get("/co2savers/vehicles");
}

export async function fetchChargingPoints(user) {
  return newAxiosWithJwt(user).get("/co2savers/chargingPoints");
}

export async function saveVehicle(vehicleId, data, user) {
  return newAxiosWithJwt(user).post("/co2savers/vehicles/" + vehicleId, data);
}

export async function duplicateVehicle(vehicleId, user) {
  return newAxiosWithJwt(user).post("/co2savers/vehicles/duplicate/" + vehicleId);
}

export async function saveChPoint(vehicleId, data, user) {
  return newAxiosWithJwt(user).post("/co2savers/chargingPoints/" + vehicleId, data);
}

export async function saveSellerAccount(data, user) {
  return newAxiosWithJwt(user).post("/sellers", data);
}

export async function saveReferral(data, user) {
  return newAxiosWithJwt(user).post("/referrals", data);
}

export async function fetchReferrals(user) {
  return newAxiosWithJwt(user).get("/referrals");
}


