import React from 'react';

function myFunction(e) {
    var link = document.createElement("a");
    console.log(JSON.stringify(e));
    link.href = "https://eco2wo.com/api/evidences/"+ e.id;
    //link.download = e.id + '-' + e.type + '.' + e.fileExt;
    link.target="_blank"

    document.body.appendChild(link);

    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
}

const IconListRenderer = props => {

  return (
    <div className="flexbox-container-horiz-no-border">
        {
            props.value
                .map((element, index) =>
                    (
                      <li key={index} className="ul-none">
                        <button onClick={() => myFunction(element)} className="button-borderless">
                          <img src={`${element.type}-${props.rendererImage}`} width="15" height="20" alt="D"/>
                        </button>
                      </li>
                    )
                )
        }
    </div>
)};

export default IconListRenderer;