import React, { useEffect, useState } from 'react';
import UploadFrontAndBack from '../../components/file-upload-2/UploadFrontAndBack';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { selectUser, selectSeller } from '../../slices/auth';
import sellerType from '../sellerType';
import { FormattedMessage } from 'react-intl'
import { IntlProvider, useIntl } from 'react-intl';

import {
  saveVehicleDataAsync,
  selectSaveVehicleResult,
  selectSaveVehicleStatus
} from './homeSlice';

var saveButtonClicked = false;

const EditVehicleByOwner = (props) => {
  const { co2saverId, sellerId, closePopup, data } = props;
  const dispatch = useDispatch();
  const saveVehicleStatus = useSelector(selectSaveVehicleStatus);
  const seller = useSelector(selectSeller);
  const [attachsOk, setAttachsOk] = useState(true);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: data
  });
  const intl = useIntl();

  const [vehicleType, setVehicleType] = useState(data.type || 'Car');

  const { isPrivate, isFleet, isChargeSt, isWithVehicles, isCompany } = sellerType(seller.sellerType);

  const isVehicle = isWithVehicles;
  const isChPoint = isChargeSt;

  useEffect(() => {
    if (saveVehicleStatus === 'idle' && saveButtonClicked) {
      saveButtonClicked = false;
      closePopup();
      return;
    }

    if (saveVehicleStatus === 'loading') {
      saveButtonClicked = true;
    }
  });

  const onSubmit = (updatedData) => {
    if (!attachsOk) {
      return false;
    }

    setSaveButtonDisabled(true);

    if (co2saverId && (co2saverId != '0')) {
      dispatch(saveVehicleDataAsync({ co2saverId, data: updatedData }));
    } else {
      dispatch(saveVehicleDataAsync({ co2saverId: '0', data: updatedData }));
    }
  };

  const vehicleTypes = [
    { isoCode: 'Car', name: 'Car' },
    { isoCode: 'Bus', name: 'Bus' },
    { isoCode: 'Truck', name: 'Truck' }
  ];

  function updateVehicleType(name, value) {
    setVehicleType(value);
  }

  return (
    <div>
      {isVehicle &&
        <h2>{((co2saverId && (co2saverId != '0')) ? intl.formatMessage({ id: 'editVehicleInfo' }) : intl.formatMessage({ id: "addNewVehicleInfo" }))}</h2>
      }
      {isChPoint &&
        <h2>{((co2saverId && (co2saverId != '0')) ? 'Edit Charging Point Information' : 'Add new Charging Point')}</h2>
      }
      <Form className="input-form" onSubmit={handleSubmit(onSubmit)} style={{ width: "600px" }}>
        {isFleet &&
          <Form.Group controlId={`vehicleTypeGroup`}>
          <Form.Label><FormattedMessage id="type" /></Form.Label>
            <Form.Control
              as="select"
              name={`type`}
              ref={register({})}
              onChange={(event) => updateVehicleType(event.target.name, event.target.value)} >
              {vehicleTypes.map(({ isoCode, name }) => (
                <option value={isoCode} key={isoCode}>
                  {name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        }
        {isVehicle && (
          <Form.Group controlId="lpGroup">
            <Form.Label><FormattedMessage id="licplate" /></Form.Label>
            <Form.Control
              type="text"
              name="licencePlate"
              autoComplete="off"
              ref={register({
                required: intl.formatMessage({ id: 'licPlateReq' }),
                minLength: {
                  value: 4,
                  message: 'Licence plate should have at-least 4 characters.'
                },
                pattern: {
                  value: /^[a-zA-Z0-9\\-]+$/,
                  message: 'Licence plate should contain only characters and numbers.'
                }
              })}
              className={`${errors.licencePlate ? 'input-error' : ''}`}
            />
            {errors.licencePlate && (
              <p className="errorMsg">{errors.licencePlate.message}</p>
            )}
          </Form.Group>
        )}
        {isChPoint && (
          <Form.Group controlId="nameGroup">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder=""
              autoComplete="off"
              ref={register({
                required: 'Name is required.',
                minLength: {
                  value: 1,
                  message: 'Name should have at-least 1 characters.'
                }
              })}
              className={`${errors.name ? 'input-error' : ''}`}
            />
            {errors.name && (
              <p className="errorMsg">{errors.name.message}</p>
            )}
          </Form.Group>
        )}
        {isChPoint && (
          <Form.Group controlId="numGroup">
            <Form.Label>Number</Form.Label>
            <Form.Control
              type="text"
              name="number"
              placeholder=""
              autoComplete="off"
              ref={register({
                required: 'Number is required.',
                pattern: {
                  value: /^[0-9\\-]+$/,
                  message: 'Number should contain numbers and dashes.'
                }
              })}
              className={`${errors.number ? 'input-error' : ''}`}
            />
            {errors.number && (
              <p className="errorMsg">{errors.number.message}</p>
            )}
          </Form.Group>
        )}
        {isChPoint && (
          <Form.Group controlId="addGroup">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              placeholder=""
              autoComplete="off"
              ref={register({
                required: 'Address is required.',
                minLength: {
                  value: 4,
                  message: 'Address should have at-least 4 characters.'
                },
              })}
              className={`${errors.address ? 'input-error' : ''}`}
            />
            {errors.address && (
              <p className="errorMsg">{errors.address.message}</p>
            )}
          </Form.Group>
        )}
        <div className="flexbox-container-horiz-no-border">
          {isChPoint && (
            <Form.Group controlId="cityGroup">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                placeholder=""
                autoComplete="off"
                ref={register({
                  required: 'City is required.',
                  minLength: {
                    value: 2,
                    message: 'City should have at-least 2 characters.'
                  },
                })}
                className={`${errors.city ? 'input-error' : ''}`}
              />
              {errors.city && (
                <p className="errorMsg">{errors.city.message}</p>
              )}
            </Form.Group>
          )}
          {isChPoint && (
            <Form.Group controlId="pcGroup">
              <Form.Label>Postcode</Form.Label>
              <Form.Control
                type="text"
                name="postcode"
                placeholder=""
                autoComplete="off"
                ref={register({
                  required: 'Postcode is required.',
                  minLength: {
                    value: 4,
                    message: 'Postcode should have at-least 4 characters.'
                  },
                  pattern: {
                    value: /^[0-9\\-]+$/,
                    message: 'Postcode should contain numbers.'
                  }
                })}
                className={`${errors.postcode ? 'input-error' : ''}`}
              />
              {errors.postcode && (
                <p className="errorMsg">{errors.postcode.message}</p>
              )}
            </Form.Group>
          )}
        </div>
        {isChargeSt &&
          <Form.Group controlId={`chPointAmountMWGroup`}>
            <Form.Label>Amount of MW Generated</Form.Label>
            <Form.Control
              type="test"
              name={`amountMWGenerated`}
              placeholder="Enter the Amount of MW Generated"
              autoComplete="off"
              ref={register({})}
              className={errors[`amountMWGenerated`] ? 'input-error' : ''}
            />
            {errors[`amountMWGenerated`] && (
              <p className="errorMsg">{errors[`amountMWGenerated`].message}</p>
            )}
          </Form.Group>
        }
        {isChPoint &&
          <Form.Group controlId="chPointPowerSourceGroup">
            <Form.Label>Power source</Form.Label>
            <div className="flexbox-container-horiz">
              <Form.Check
                ref={register({})}
                inline
                label="Private Renewable"
                name="powerSourceType"
                type="radio"
                defaultChecked={data.powerSourceType === 'LocalSustainable'}
                id={`inline-radio-1`}
                value="LocalSustainable"
              />
              <Form.Check
                ref={register({})}
                inline
                label="Public Power Grid"
                name="powerSourceType"
                type="radio"
              defaultChecked={data.powerSourceType === 'Public'}
                id={`inline-radio-2`}
              value="Public"
              />
            </div>
          </Form.Group>
        }
        {isVehicle &&
          <UploadFrontAndBack filenamePrefix={`${sellerId}-${co2saverId}`} setAttachsOk={setAttachsOk}
            isEdit={(co2saverId && (co2saverId != '0'))} />
        }
        <Button variant="primary" type="submit" disabled={saveButtonDisabled}>
          <FormattedMessage id="save" />
        </Button>
      </Form>
    </div>
  );
};

export default EditVehicleByOwner;
