import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  agentFetchVehicles
} from './agentHomeAPI';

const initialState = {
  vehicles: []
};

export const fetchVehiclesAsync = createAsyncThunk(
  'home/adminFetchVehicles',
  async (dummy, { getState }) => {
    const state = getState();
    const response = await agentFetchVehicles(state.auth.user);
    return response.data;
  }
);

export const agentHomeSlice = createSlice({
  name: 'agentHome',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchVehiclesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVehiclesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log(action.payload.numberOfElements);
        state.vehicles = action.payload.content;
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectVehicles = (state) => state.adminHome.vehicles;

export default agentHomeSlice.reducer;
