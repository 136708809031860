import React, { Component, useEffect, useState, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { IntlProvider, useIntl, FormattedMessage } from 'react-intl';
import sellerType from '../sellerType';
import UploadFrontAndBack from '../../components/file-upload-2/UploadFrontAndBack';
import { selectUser, selectIsLoggedIn } from '../../slices/auth';
import { fetchSellerByEmailAsync, selectSellerByEmail, fetchAdminSettingsAsync, selectAdminSettings } from './adminHomeSlice';
import { DatePicker } from '@mantine/dates';

import {
  saveVehicleDataAsync,
  selectSaveVehicleResult,
  selectSaveVehicleStatus,
  deleteVehicleAsync
} from './adminHomeSlice';
import { isInteger } from 'formik';

var saveButtonClicked = false;

const EditVehicleByAdmin = (props) => {
  const { co2saverId = 0, closePopup, data = {} } = props;
  const user = useSelector(selectUser);
  const sellerByEmail = useSelector(selectSellerByEmail);
  const isNew = co2saverId == 0;
  console.log({ data });
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState(data.status || 'InProcess');
  const [deleteButtonShown, setDeleteButtonShown] = useState(false);
  const isSuperAdmin = user && user.roles.includes("ROLE_SuperAdmin");
  const [sellerTypeChoice, setSellerTypeChoice] = useState('PrivatePerson'); // CommercialFleet
  const adminEditAllData = props.adminEditAllData;
  const formRef = useRef(null);
  const adminSettings = useSelector(selectAdminSettings);
  const [year, setYear] = useState(data.year || new Date().getFullYear());
  const [regDate, setRegDate] = useState(data.registrationDate ? new Date(data.registrationDate) : null);

  const intl = useIntl();

  const seller = data.seller || { id: 0 };
  var isPrivate, isFleet, isChargeSt, isWithVehicles, isCompany;
  ({ isPrivate, isFleet, isChargeSt, isWithVehicles, isCompany } = sellerType(seller.sellerType));

  if (isNew) {
    isCompany = sellerTypeChoice === 'CommercialFleet';
  }

  console.log({ seller });

  const [wayOfPayment, setWayOfPayment] = useState(seller.wayOfPayment || 'IBAN');

  const isVehicle = (data.objType === 'vehicle') || true;
  const isChPoint = !isVehicle;

  const saveVehicleStatus = useSelector(selectSaveVehicleStatus);
  const { register, handleSubmit, errors, reset: resetForm } = useForm({
    defaultValues: data
  });

  var statuses = [
    { isoCode: 'InProcess', name: 'In Process' },
    { isoCode: 'Reviewing', name: 'Reviewing' },
    { isoCode: 'Rejected', name: 'Rejected' },
    { isoCode: 'Validated', name: 'Validated' },
    { isoCode: 'Paid', name: 'Paid' }
    /*{isoCode:'PaymentInitiated', name:''},
    {isoCode:'PaymentFailed', name:''}*/
  ];

  var years = [
    { isoCode: 2022, name: '2022' },
    { isoCode: 2023, name: '2023' },
    { isoCode: 2024, name: '2024' },
    { isoCode: 2025, name: '2025' },
    { isoCode: 2026, name: '2026' },
    { isoCode: 2027, name: '2027' },
    { isoCode: 2028, name: '2028' },
    { isoCode: 2029, name: '2029' },
    { isoCode: 2030, name: '2030' },
    { isoCode: 2031, name: '2031' },
  ];

  var sellerTypeOptions = [
    { isoCode: 'PrivatePerson', name: 'Private Person' },
    { isoCode: 'CommercialFleet', name: 'Commercial Fleet' },
  ];
  var waysOfPayment = [
    { isoCode: 'IBAN', name: intl.formatMessage({ id: 'payoutToIban' }) },
    { isoCode: 'NGO01', name: intl.formatMessage({ id: 'dtNGO01' }) },
  ];

  const deleteQuota = () => {
    if (!window.confirm("DELETING QUOTA DATA. Are you sure?")) {
      return false;
    }

    dispatch(deleteVehicleAsync({ co2saverId }))
  }

  const tryFetchExistingSeller = (event) => {
    console.log(event.target.value);
    dispatch(fetchSellerByEmailAsync({ email: event.target.value }));
  }

  useEffect(() => {
    console.log({ sellerByEmail });

    if (sellerByEmail && sellerByEmail.id) {
      //formRef.setFormContents(sellerByEmail);
      console.log(formRef);
      resetForm(sellerByEmail);
      seller.id = sellerByEmail.id;
      setWayOfPayment(sellerByEmail.wayOfPayment);
    }

  }, [sellerByEmail]);

  const onSubmit = (updatedData) => {
    if (selectedStatus === 'Paid') {
      if (!window.confirm("Are you sure?")) {
        return false;
      }
    }

    updatedData.status = selectedStatus;
    updatedData.year = year;
    updatedData.objType = data.objType || 'vehicle';
    updatedData.registrationDate = regDate;

    if (adminEditAllData) {
      updatedData.seller = getSellerData(updatedData);
      updatedData.seller.id = seller.id;
    }

    if (isNew) {
      updatedData.seller.type = sellerTypeChoice;
      if (sellerByEmail && sellerByEmail.id) {
        updatedData.seller.type = sellerByEmail.type;
        updatedData.seller.id = sellerByEmail.id;
      } else {
        updatedData.seller.type = sellerTypeChoice;
      }
      updatedData.year = year;
    }

    dispatch(saveVehicleDataAsync({ co2saverId, data: updatedData }))
  };

  function getSellerData(updatedData) {
    console.log({ updatedData });
    return {
      email: updatedData.email,
      firstName: updatedData.firstName,
      lastName: updatedData.lastName,
      companyName: updatedData.companyName,
      companyIdentNum: updatedData.companyIdentNum,
      companyVatNumber: updatedData.companyVatNumber,
      companyAddress: updatedData.companyAddress,
      companyCity: updatedData.companyCity,
      companyZip: updatedData.companyZip,
      wayOfPayment,
      ibanHolderName: updatedData.ibanHolderName,
      iban: updatedData.iban,
      bankName: updatedData.bankName
    }
  }

  useEffect(() => {
    console.log(saveVehicleStatus);
    if (saveVehicleStatus === 'idle' && saveButtonClicked) {
      saveButtonClicked = false;
      closePopup();
      return;
    }

    if (saveVehicleStatus === 'loading') {
      saveButtonClicked = true;
    }
  });

  useEffect(() => {
    dispatch(fetchAdminSettingsAsync())
  }, []);

  return (
    <div>

      <Form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <div className="flexbox-container-horiz-no-border">
          <h2>{isNew ? 'Add' : 'Edit'} Data&nbsp;&nbsp;</h2>

          <div className="flexbox-container-horiz-no-border" style={{ width: '250px' }}>
            <div className="flexbox-container-vert-no-border" style={{ width: '50%' }}>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </div>
            {!isNew &&
              <>
                <div className="flexbox-container-vert-no-border" style={{ width: '50%', display: deleteButtonShown ? 'inherit' : 'none' }} >

                  <Button variant="primary" style={{ borderColor: 'red', backgroundColor: 'red' }} type="button" onClick={deleteQuota}>
                    Delete
                  </Button>

                </div>
                <div className="flexbox-container-vert-no-border" style={{ width: '50%', display: !deleteButtonShown ? 'inherit' : 'none' }} >
                  <div className="flexbox-container-horiz-no-border" style={{ flexDirection: 'row-reverse' }}>
                    <Button variant="primary" type="button" style={{ borderColor: 'gray', backgroundColor: 'gray' }}
                      onClick={() => setDeleteButtonShown(true)}>
                      !
                    </Button>
                  </div>
                </div>
              </>
            }
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </div>


        <hr />

        {isVehicle &&
          <><h4>
            <table>
              <tr>
                <td>Vehicle {data.generatedId || ' - New'}</td>
                <td style={{ paddingTop: '20px', paddingLeft: '20px' }}>
                  {isNew &&
                    <Form.Group controlId="sellerTCGroup">
                      <Form.Control
                        as="select"
                        name="sellerTypeChoice"
                        value={sellerTypeChoice}
                        onChange={(event) => setSellerTypeChoice(event.target.value)}
                      >
                        {sellerTypeOptions.map(({ isoCode, name }) => (
                          <option value={isoCode} key={isoCode}>
                            {name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  }</td>
              </tr>
            </table>
          </h4>
          </>
        }
        {isChPoint &&
          <h4>Charging Point {data.generatedId || ' - New'}</h4>
        }

        <hr />
        <div className="flexbox-container-horiz-no-border" >
          {adminEditAllData &&
            <div style={{ paddingLeft: '10px', paddingRight: '15px', width: '420px' }} >

              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  defaultValue={seller.email}
                  readOnly={!isNew}
                  autoComplete="off"
                  ref={
                    register({
                      required: "Email is required.",
                      pattern: {
                        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        message: "Email is not valid.",
                      },
                    })
                  }
                  onBlur={tryFetchExistingSeller}
                />
              </Form.Group>
              <Form.Group controlId="firstName">
                <Form.Label><FormattedMessage id="fname" /></Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  defaultValue={seller.firstName}
                  //placeholder="Enter your first name"
                  autoComplete="off"
                  ref={register({
                    required: 'First name is required.',
                    pattern: {
                      message: 'First name should contain only characters.'
                    }
                  })}
                  className={`${errors.firstName ? 'input-error' : ''}`}
                />
                {errors.firstName && (
                  <p className="errorMsg">{errors.firstName.message}</p>
                )}
              </Form.Group>

              <Form.Group controlId="lastName">
                <Form.Label><FormattedMessage id="lname" /></Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  defaultValue={seller.lastName}
                  //placeholder="Enter your last name"
                  autoComplete="off"
                  ref={register({
                    required: 'Last name is required.',
                    pattern: {
                      message: 'Last name should contain only characters.'
                    }
                  })}
                  className={`${errors.lastName ? 'input-error' : ''}`}
                />
                {errors.lastName && (
                  <p className="errorMsg">{errors.lastName.message}</p>
                )}
              </Form.Group>
              <Form.Group controlId="wayOfPayment">
                <Form.Label><FormattedMessage id="wop" /></Form.Label>
                <Form.Control
                  as="select"
                  name="wayOfPayment"
                  value={wayOfPayment}
                  ref={register({})}
                  onChange={(event) => setWayOfPayment(event.target.value)} >
                  {waysOfPayment.map(({ isoCode, name }) => (
                    <option value={isoCode} key={isoCode}>
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {(wayOfPayment === 'IBAN') &&
                <Form.Group controlId="ibanHolderName">
                  <Form.Label><FormattedMessage id="recipientName" /></Form.Label>
                  <Form.Control
                    type="text"
                    name="ibanHolderName"
                    defaultValue={seller.ibanHolderName}
                    // placeholder="Enter your IBAN's Holder Name"
                    autoComplete="off"
                    ref={register({
                      required: 'IBAN Holder Name is required.'
                    })}
                    className={`${errors.ibanHolderName ? 'input-error' : ''}`}
                  />
                  {errors.ibanHolderName && (
                    <p className="errorMsg">{errors.ibanHolderName.message}</p>
                  )}
                </Form.Group>
              }
              {(wayOfPayment === 'IBAN') &&
                <Form.Group controlId="iban">
                  <Form.Label>IBAN</Form.Label>
                  <Form.Control
                    type="text"
                    name="iban"
                    defaultValue={seller.iban}
                    // placeholder="Enter your IBAN"
                    autoComplete="off"
                    ref={register({
                      required: 'IBAN is required.',
                      pattern: {
                        value: /^[a-zA-Z0-9]+$/,
                        message: 'IBAN should contain only characters and numbers.'
                      }
                    })}
                    className={`${errors.iban ? 'input-error' : ''}`}
                  />
                  {errors.iban && (
                    <p className="errorMsg">{errors.iban.message}</p>
                  )}
                </Form.Group>
              }
              {(wayOfPayment === 'IBAN') &&
                <Form.Group controlId="bankName">
                  <Form.Label><FormattedMessage id="bankName" /></Form.Label>
                  <Form.Control
                    type="text"
                    name="bankName"
                    defaultValue={seller.bankName}
                    // placeholder="Enter your Bank's name"
                    autoComplete="off"
                    ref={register({
                      required: 'Bank name is required.'
                    })}
                    className={`${errors.bankName ? 'input-error' : ''}`}
                  />
                  {errors.bankName && (
                    <p className="errorMsg">{errors.bankName.message}</p>
                  )}
                </Form.Group>
              }
            </div>
          }
          {adminEditAllData && isCompany &&
            <div style={{ paddingLeft: '10px', paddingRight: '15px', width: '420px' }} >

              {isCompany &&
                <Form.Group controlId="companyName">
                  <Form.Label><FormattedMessage id="companyName" /></Form.Label>
                  <Form.Control
                    type="text"
                    name="companyName"
                    defaultValue={seller.companyName}
                    autoComplete="off"
                    ref={register({
                      required: 'Company Name is required.',
                    })}
                    className={`${errors.companyName ? 'input-error' : ''}`}
                  />
                  {errors.companyName && (
                    <p className="errorMsg">{errors.companyName.message}</p>
                  )}
                </Form.Group>
              }
              {isCompany &&
                <Form.Group controlId="companyIdentNum">
                  <Form.Label><FormattedMessage id="companyId" /></Form.Label>
                  <Form.Control
                    type="text"
                    name="companyIdentNum"
                    defaultValue={seller.companyIdentNum}
                    //placeholder="Enter the company ID"
                    autoComplete="off"
                    ref={register({})}
                    className={`${errors.companyIdentNum ? 'input-error' : ''}`}
                  />
                  {errors.companyIdentNum && (
                    <p className="errorMsg">{errors.companyIdentNum.message}</p>
                  )}
                </Form.Group>
              }
              {isCompany &&
                <Form.Group controlId="companyVatNumber">
                  <Form.Label><FormattedMessage id="vatNum" /></Form.Label>
                  <Form.Control
                    type="text"
                    name="companyVatNumber"
                    defaultValue={seller.companyVatNumber}
                    //placeholder="Enter the company VAT Number"
                    autoComplete="off"
                    ref={register({})}
                    className={`${errors.companyVatNumber ? 'input-error' : ''}`}
                  />
                  {errors.companyVatNumber && (
                    <p className="errorMsg">{errors.companyVatNumber.message}</p>
                  )}
                </Form.Group>
              }
              {isCompany &&
                <Form.Group controlId="companyAddress">
                  <Form.Label><FormattedMessage id="address" /></Form.Label>
                  <Form.Control
                    type="text"
                    name="companyAddress"
                    defaultValue={seller.companyAddress}
                    //placeholder="Enter the company Address"
                    autoComplete="off"
                    ref={register({
                      required: 'Company Address is required.',
                    })}
                    className={`${errors.companyAddress ? 'input-error' : ''}`}
                  />
                  {errors.companyAddress && (
                    <p className="errorMsg">{errors.companyAddress.message}</p>
                  )}
                </Form.Group>
              }
              {isCompany &&
                <Form.Group controlId="companyCity" style={{ paddingRight: '15px' }}>
                  <Form.Label><FormattedMessage id="city" /></Form.Label>
                  <Form.Control
                    type="text"
                    name="companyCity"
                    defaultValue={seller.companyCity}
                    //placeholder="Enter the company City"
                    autoComplete="off"
                    ref={register({
                      required: 'Company City is required.',
                    })}
                    className={`${errors.companyCity ? 'input-error' : ''}`}
                  />
                  {errors.companyCity && (
                    <p className="errorMsg">{errors.companyCity.message}</p>
                  )}
                </Form.Group>
              }
              {isCompany &&
                <Form.Group controlId="companyZip">
                  <Form.Label><FormattedMessage id="postcode" /></Form.Label>
                  <Form.Control
                    type="text"
                    name="companyZip"
                    defaultValue={seller.companyZip}
                    //placeholder="Enter the company Postcode"
                    autoComplete="off"
                    ref={register({
                      required: 'Company Postcode is required.',
                    })}
                    className={`${errors.companyZip ? 'input-error' : ''}`}
                  />
                  {errors.companyZip && (
                    <p className="errorMsg">{errors.companyZip.message}</p>
                  )}
                </Form.Group>
              }

            </div>
          }
          <div style={{ paddingLeft: '15px', paddingRight: '15px', width: '420px' }} >


            {isVehicle &&
              <>
                <Form.Group controlId="vinGroup">
                  <Form.Label>VIN</Form.Label>
                  <Form.Control
                    type="text"
                    name="vin"
                    placeholder=""
                    autoComplete="off"
                    ref={register({
                      required: false,
                      pattern: {
                        value: /^[a-zA-Z0-9]+$/,
                        message: 'VIN should contain only characters and numbers.'
                      }
                    })}
                    className={`${errors.vin ? 'input-error' : ''}`}
                  />
                  {errors.vin && (
                    <p className="errorMsg">{errors.vin.message}</p>
                  )}
                </Form.Group>

                <Form.Group controlId="lpGroup">
                  <Form.Label><FormattedMessage id="licplate" /></Form.Label>
                  <Form.Control
                    type="text"
                    name="licencePlate"
                    autoComplete="off"
                    ref={register({
                      required: intl.formatMessage({ id: 'licPlateReq' }),
                      minLength: {
                        value: 4,
                        message: 'Licence plate should have at-least 4 characters.'
                      },
                      pattern: {
                        value: /^[a-zA-Z0-9\\-]+$/,
                        message: 'Licence plate should contain only characters and numbers.'
                      }
                    })}
                    className={`${errors.licencePlate ? 'input-error' : ''}`}
                  />
                  {errors.licencePlate && (
                    <p className="errorMsg">{errors.licencePlate.message}</p>
                  )}
                </Form.Group>


                <Form.Group controlId={`regDateGroup`}>
                  <Form.Label>
                    <FormattedMessage id='regDate' />
                  </Form.Label>
                  <div style={{ width: '200px' }}>

                    <DatePicker placeholder="Pick date" withAsterisk
                      onChange={(e, c) => {
                        setRegDate(e);
                        console.log(c);
                      }}
                      value={regDate}
                    />
                  </div>
                </Form.Group>

              </>
            }
            <Form.Group controlId="PaymentAmountGroup">
              <Form.Label>Payment Amount</Form.Label>
              <Form.Control
                type="text"
                name="paymentAmount"
                placeholder=""
                autoComplete="off"
                ref={register({
                  required: false,
                  pattern: {
                    value: /^[0-9\\.]+$/,
                    message: 'Payment Amount can contain numbers only.'
                  }
                })}
                className={`${errors.paymentAmount ? 'input-error' : ''}`}
              />
              {errors.paymentAmount && (
                <p className="errorMsg">{errors.paymentAmount.message}</p>
              )}
            </Form.Group>
            <Form.Group controlId="yearGroup">
              <Form.Label>Year</Form.Label>
              <Form.Control
                as="select"
                name="year"
                value={year}
                onChange={(event) => setYear(event.target.value)}
              >
                {years.map(({ isoCode, name }) => (
                  <option value={isoCode} key={isoCode}>
                    {name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="tco2SavedGroup">
              <Form.Label>tCO2 Saved</Form.Label>
              <Form.Control
                type="text"
                name="tco2Saved"
                placeholder=""
                autoComplete="off"
                defaultValue={adminSettings ? adminSettings.pvtCarTCo2Saved : '0.86'}
                ref={register({
                  required: false,
                  pattern: {
                    value: /^[0-9\\.]+$/,
                    message: 'tCO2 Saved can contain numbers only.'
                  }
                })}
                className={`${errors.tco2Saved ? 'input-error' : ''}`}
              />
              {errors.tco2Saved && (
                <p className="errorMsg">{errors.tco2Saved.message}</p>
              )}
            </Form.Group>

            {isChPoint &&
              <Form.Group controlId="tco2SavedGroup">
                <Form.Label>tCO2 Saved</Form.Label>
                <Form.Control
                  type="text"
                  name="tco2Saved"
                  placeholder=""
                  autoComplete="off"
                  ref={register({
                    required: false,
                    pattern: {
                      value: /^[0-9\\.]+$/,
                      message: 'tCO2 Saved should contain only numbers.'
                    }
                  })}
                  className={`${errors.tco2Saved ? 'input-error' : ''}`}
                />
                {errors.tco2Saved && (
                  <p className="errorMsg">{errors.tco2Saved.message}</p>
                )}
              </Form.Group>
            }
            {isSuperAdmin &&
              <Form.Group controlId="statusGroup">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  value={selectedStatus}
                  onChange={(event) => setSelectedStatus(event.target.value)}
                >
                  {statuses.map(({ isoCode, name }) => (
                    <option value={isoCode} key={isoCode}>
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            }
          </div>
          {adminEditAllData && isVehicle &&
            <div style={{ paddingLeft: '15px', paddingRight: '15px', width: '420px' }} >

              {isVehicle && <>
                <Form.Label>Documents</Form.Label>
                <UploadFrontAndBack filenamePrefix={`${seller.id}-${co2saverId}`} setAttachsOk={() => { }}
                  isEdit={true} allAttachmentsOptional={true} vertStyle={true} uploadThirdDoc={true} hidePreviewPane={true} />
              </>
              }
            </div>
          }
        </div>
      </Form>
    </div>

  );
};

export default EditVehicleByAdmin;
