import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, selectIsLoggedIn } from '../../slices/auth';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import EditRowButtonRenderer from './editButtonRenderer';
import DocsIconsListRenderer from './iconsListRenderer';

import {
  fetchCO2SaversValidOrPaidUnboughtAsync,
  selectVehicles,
  saveBuyerInvoiceAsync,
  selectSaveBuyerInvoiceResult,
  selectSaveBuyerInvoiceStatus,
} from './adminHomeSlice';

let needsReloading = true;
var saveButtonClicked = false;

export default function SellQuotas(props) {
  const vehicles = useSelector(selectVehicles);
  const saveBuyerInvoiceStatus = useSelector(selectSaveBuyerInvoiceStatus);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const vatPercentage = 0.19;
  const [totalCO2, setTotalCO2] = useState(0);
  const [totalVAT, setTotalVAT] = useState(0);
  const [totalWithoutVAT, setTotalWithoutVAT] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [tCO2Price, setTCO2Price] = useState(0);
  const [rowIdsSelected, setRowIdsSelected] = useState([]);
  const [country, setCountry] = useState('Deutschland');

  useEffect(() => {
    if (needsReloading) {
      dispatch(fetchCO2SaversValidOrPaidUnboughtAsync());
      needsReloading = false;
    }
  });
  
  if (!user) {
    props.history.push('/login');
  }

  const cellLeftAlign = { 'text-align': "left", padding: '2px' };
  const cellRightAlign = { 'text-align': "right", padding: '2px' };
  const cellCenterAlign = { 'text-align': "center", padding: '2px' };

  function headerHeightSetter() {
    var padding = 2;
    var height = 50 + padding;
    this.api.setHeaderHeight(height);
    this.api.resetRowHeights();
  }

  function dateFormatter(params) {
    var newDate = new Date();
    newDate.setTime(Date.parse(params.value));
    return newDate.toLocaleDateString();
  }

  //var gridApi;
  //var gridColumnApi;

  //const onGridReady = params => {
  //  gridApi = params.api;
  //  gridColumnApi = params.columnApi;
  //};

  const { register, errors, handleSubmit, reset } = useForm({
    defaultValues: {}
  });

  const getSelectedRowData = (gridApi) => {
    let selectedNodes = gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data);
    console.log(selectedData);
    return selectedData;
  };

  const onGridSelectionChanged = ({ api, columnApi, type }) => {
    const selectedRowsDataArray = getSelectedRowData(api);
    var totalCalcCO2 = 0;
    const rowIds = [];
    selectedRowsDataArray.forEach(rowData => {
      totalCalcCO2 += rowData.tco2Saved;
      rowIds.push(rowData.id);
    });
    console.log(totalCalcCO2);
    console.log(rowIds);
    setRowIdsSelected(rowIds);
    setTotalCO2(totalCalcCO2)
  }

  const onSubmit = async (data, event) => {
    event.preventDefault();
    var invoice = { ...data };
    invoice.co2SaversIds = rowIdsSelected;
    invoice.country = country;
    dispatch(saveBuyerInvoiceAsync({ data: invoice }));
  };

  function round2dec(num) {
    return Math.round(num * 100) / 100
  }

  var countries = [
    { isoCode: 'Deutschland', name: 'Deutschland' },
  ];

  useEffect(() => {
    setTotalPrice(round2dec(tCO2Price * totalCO2 * (1 + vatPercentage)));
    setTotalVAT(round2dec(tCO2Price * totalCO2 * vatPercentage));
    setTotalWithoutVAT(round2dec(tCO2Price * totalCO2));
  }, [tCO2Price, totalCO2]);

  useEffect(() => {
    console.log(saveBuyerInvoiceStatus);
    if (saveBuyerInvoiceStatus === 'idle' && saveButtonClicked) {
      saveButtonClicked = false;
      Swal.fire('Awesome!', "Invoice created and sent!", 'success').then(
        (result) => {
          if (result.isConfirmed || result.isDismissed) {
            props.history.push('/buyersInvoices');
          }
        }
      );
      return;
    }

    if (saveBuyerInvoiceStatus === 'loading') {
      saveButtonClicked = true;
    }
  });

  return (
    <div>
      <header className="">
        <h3>New Buyer's Invoices</h3>
        You can use this page to create a new invoice towards a buyer.
      </header>
      <Form className="input-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="flexbox-container-horiz">
          <div className="col-md-6 offset-md-0" >
            <Form.Group controlId="emailGroup">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                placeholder="Enter Email"
                autoComplete="off"
                ref={register({
                  required: 'Email is required.',
                })}
                className={`${errors.email ? 'input-error' : ''}`}
                style={{ textAlign: 'left' }}
              />
              {errors.email && (
                <p className="errorMsg">{errors.email.message}</p>
              )}
            </Form.Group>
            <Form.Group controlId="companyNameGroup">
              <Form.Label>Company name</Form.Label>
              <Form.Control
                type="text"
                name="companyName"
                placeholder="Enter Company name"
                autoComplete="off"
                ref={register({
                  required: 'Company name is required.',
                })}
                className={`${errors.companyName ? 'input-error' : ''}`}
                style={{ textAlign: 'left' }}
              />
              {errors.companyName && (
                <p className="errorMsg">{errors.companyName.message}</p>
              )}
            </Form.Group>
            <Form.Group controlId="vatNumberGroup">
              <Form.Label>VAT ID</Form.Label>
              <Form.Control
                type="text"
                name="vatNumber"
                placeholder="Enter VAT ID"
                autoComplete="off"
                ref={register({
                  required: 'VAT ID is required.',
                })}
                className={`${errors.vatNumber ? 'input-error' : ''}`}
                style={{ textAlign: 'left' }}
              />
              {errors.vatNumber && (
                <p className="errorMsg">{errors.vatNumber.message}</p>
              )}
            </Form.Group>
            <Form.Group controlId="addressGroup">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                placeholder="Enter Address"
                autoComplete="off"
                ref={register({
                  required: 'Address is required.',
                })}
                className={`${errors.address ? 'input-error' : ''}`}
                style={{ textAlign: 'left' }}
              />
              {errors.address && (
                <p className="errorMsg">{errors.address.message}</p>
              )}
            </Form.Group>
            <div className="flexbox-container-horiz-no-border">
              <Form.Group controlId="addressGroup" style={{ paddingRight: '15px' }}>
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  placeholder="Enter City"
                  autoComplete="off"
                  ref={register({
                    required: 'City is required.',
                  })}
                  className={`${errors.city ? 'input-error' : ''}`}
                  style={{ textAlign: 'left' }}
                />
                {errors.city && (
                  <p className="errorMsg">{errors.city.message}</p>
                )}
              </Form.Group>
              <Form.Group controlId="postcodeGroup">
                <Form.Label>Postcode</Form.Label>
                <Form.Control
                  type="text"
                  name="postcode"
                  placeholder="Enter Postcode"
                  autoComplete="off"
                  ref={register({
                    required: 'Postcode is required.',
                  })}
                  className={`${errors.postcode ? 'input-error' : ''}`}
                  style={{ textAlign: 'left' }}
                />
                {errors.postcode && (
                  <p className="errorMsg">{errors.postcode.message}</p>
                )}
              </Form.Group>
            </div>
            <Form.Group controlId="countryGroup">
              <Form.Label>Country</Form.Label>
              <Form.Control className="form-control"
                as="select"
                name="country"
                value={country}
                ref={register({})}
                onChange={(event) => setCountry(event.target.value)} >
                {countries.map(({ isoCode, name }) => (
                  <option value={isoCode} key={isoCode}>
                    {name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="col-md-6 offset-md-0" >
            <Form.Group controlId="pricePerTCo2Group">
              <Form.Label>Price Per tCO2</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>€</InputGroup.Text>
                <Form.Control
                  type="text"
                  name="pricePerTCo2"
                  placeholder="Enter Price Per tCO2"
                  autoComplete="off"
                  onChange={e => setTCO2Price(e.target.value)}
                  ref={register({
                    required: 'Price Per tCO2 is required.',
                  })}
                  className={`${errors.pricePerTCo2 ? 'input-error' : ''}`}
                  style={{ textAlign: 'right' }}
                />
              </InputGroup>
              {errors.pricePerTCo2 && (
                <p className="errorMsg">{errors.pricePerTCo2.message}</p>
              )}
            </Form.Group>
            <Form.Group controlId="totalTCo2SoldGroup">
              <Form.Label>Total tCO2 Sold</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>tCO2</InputGroup.Text>
                <Form.Control
                  type="text"
                  name="totalTCo2Sold"
                  value={totalCO2}
                  placeholder="Calculated Total tCO2 Sold"
                  autoComplete="off"
                  ref={register({
                    required: 'Total tCO2 Sold is required.',
                  })}
                  className={`${errors.totalTCo2Sold ? 'input-error' : ''}`}
                  style={{ textAlign: 'right' }}
                  readOnly={true}
                />
              </InputGroup>
              {errors.totalTCo2Sold && (
                <p className="errorMsg">{errors.totalTCo2Sold.message}</p>
              )}
            </Form.Group>
            <Form.Group controlId="totalVatGroup">
              <Form.Label>Total Without VAT</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>€</InputGroup.Text>
                <Form.Control
                  type="text"
                  name="totalWithoutVat"
                  value={totalWithoutVAT}
                  placeholder="Calculated Total Without VAT"
                  autoComplete="off"
                  ref={register({})}
                  className={`${errors.totalVat ? 'input-error' : ''}`}
                  style={{ textAlign: 'right' }}
                  readOnly={true}
                />
              </InputGroup>
              {errors.totalVat && (
                <p className="errorMsg">{errors.totalVat.message}</p>
              )}
            </Form.Group>
            <Form.Group controlId="totalVatGroup">
              <Form.Label>Total VAT</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>€</InputGroup.Text>
                <Form.Control
                  type="text"
                  name="totalVat"
                  value={totalVAT}
                  placeholder="Calculated Total VAT"
                  autoComplete="off"
                  ref={register({
                    required: 'Total VAT is required.',
                  })}
                  className={`${errors.totalVat ? 'input-error' : ''}`}
                  style={{ textAlign: 'right' }}
                  readOnly={true}
                />
              </InputGroup>
              {errors.totalVat && (
                <p className="errorMsg">{errors.totalVat.message}</p>
              )}
            </Form.Group>
            <Form.Group controlId="totalPriceGroup">
              <Form.Label>Total Price</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>€</InputGroup.Text>
                <Form.Control
                  type="text"
                  name="totalPrice"
                  placeholder="Calculated Total Price"
                  autoComplete="off"
                  value={totalPrice}
                  readOnly={true}
                  ref={register({
                    required: 'Total Price is required.',
                  })}
                  className={`${errors.totalPrice ? 'input-error' : ''}`}
                  style={{ textAlign: 'right' }}
                />
              </InputGroup>
              {errors.totalPrice && (
                <p className="errorMsg">{errors.totalPrice.message}</p>
              )}
            </Form.Group>

            <Button variant="primary" type="submit">Save</Button>
          </div>
        </div>
      </Form >

      <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
        <AgGridReact style={{ height: 800, width: '100%' }}
          //onGridReady={onGridReady}
          onFirstDataRendered={headerHeightSetter}
          rowData={vehicles}
          frameworkComponents={{
            editRowButtonRenderer: EditRowButtonRenderer,
            docsIconsListRenderer: DocsIconsListRenderer
          }}
          defaultColDef={{
            flex: 1,
            editable: true,
            cellStyle: cellLeftAlign,
            headerClass: "text-center",
            wrapText: true
          }}
          default key="id" rowSelection='multiple'
          onSelectionChanged={onGridSelectionChanged}
        >
          <AgGridColumn headerName="QID" field="id" maxWidth="60" cellStyle={cellCenterAlign} filter={true} />
          <AgGridColumn headerName="First name" field="seller.firstName" filter={true} />
          <AgGridColumn headerName="Last name" field="seller.lastName" filter={true} />
          {/*<AgGridColumn headerName="Brand" valueGetter={(params) =>
          ((params.data.model || {})['brand'] || '').concat(' ', (params.data.model || {})['model'])} >
        </AgGridColumn>*/}
          <AgGridColumn field="creationDate" headerName="Submission date" valueFormatter={dateFormatter}
            sortable={true} />
          <AgGridColumn headerName="Documents" cellRendererParams={{ "rendererImage": "icon-doc.png" }}
            cellRenderer="docsIconsListRenderer" field="fileEvidences" />
          <AgGridColumn headerName="Status" field="status" maxWidth="100" filter={true} />
          <AgGridColumn field="tCo2Saved" cellStyle={cellRightAlign} valueGetter={(params) =>
            params.data.tco2Saved ? ('' + params.data.tco2Saved).concat(` t`) : ''}
            maxWidth="70" headerName="tCO2" headerClass="text-right" />
          <AgGridColumn headerName="" checkboxSelection={true} headerCheckboxSelection={true}
            minWidth="25" maxWidth="25" cellStyle={cellRightAlign} />

        </AgGridReact>
      </div >

    </div>
  );

}
