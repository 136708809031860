import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, selectIsLoggedIn, selectSeller } from '../../slices/auth';
import { setSeller } from '../../slices/auth';
import sellerType from '../sellerType';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl'

import {
  saveSellerAccountAsync,
  selectSaveSellerAccountResult,
  selectSaveSellerAccountStatus
} from './homeSlice';

var saveButtonClicked = false;

const EditSellerAccount = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const saveSellerAccountStatus = useSelector(selectSaveSellerAccountStatus);
  const saveSellerAccountResult = useSelector(selectSaveSellerAccountResult);
  const seller = useSelector(selectSeller);
  const intl = useIntl(); //intl.formatMessage({ id: 'certId' })
  const user = useSelector(selectUser);

  if (!user) {
    props.history.push('/login');
  }

  const { isPrivate, isFleet, isChargeSt, isWithVehicles, isCompany } = sellerType(seller.sellerType);

  const [wayOfPayment, setWayOfPayment] = useState(seller.wayOfPayment);

  var waysOfPayment = [
    { isoCode: 'IBAN', name: intl.formatMessage({ id: 'payoutToIban' }) },
    { isoCode: 'NGO01', name: intl.formatMessage({ id: 'dtNGO01' }) },
  ];

  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      ...seller
    }
  });

  const onSubmit = async (data, event) => {
    event.preventDefault();
    const updatedUser = { ...seller, ...data };
    dispatch(saveSellerAccountAsync({ data: updatedUser }));
  };


  useEffect(() => {
    console.log(saveSellerAccountStatus);
    if (saveSellerAccountStatus === 'idle' && saveButtonClicked) {
      saveButtonClicked = false;
      dispatch(setSeller(saveSellerAccountResult));
      Swal.fire('Awesome!', "You've updated your account settings!", 'success').then(
        (result) => {
          if (result.isConfirmed || result.isDismissed) {
            props.history.push('/profile');
          }
        }
      );
      return;
    }

    if (saveSellerAccountStatus === 'loading') {
      saveButtonClicked = true;
    }
  });

  return (
    <div>
      <div className="col-md-6 offset-md-3" >
        <Form className="input-form" onSubmit={handleSubmit(onSubmit)}>
          <header className="">
            <h3><FormattedMessage id="accInfo" /></h3>
            You can use this page to edit your account's information.
          </header>
          <br />
          <Form.Group controlId="ibanHolderName">
            <Form.Label>{`Email:  ${seller.email}`}</Form.Label>
          </Form.Group>
          <hr />
          <Form.Group controlId="ibanHolderName">
            <div className="flexbox-container-horiz-no-border">
              <Form.Label><FormattedMessage id="toChangePassClick" />&nbsp;&nbsp;&nbsp;</Form.Label>
              <Button variant="primary" type="button" onClick={() => { props.history.push('/setNewPassword'); }}>
                <FormattedMessage id="changePassword" />
              </Button>
            </div>
          </Form.Group>
          <hr />
          <Form.Group controlId="firstName">
            <Form.Label><FormattedMessage id="fname" /></Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              //placeholder="Enter your first name"
              autoComplete="off"
              ref={register({
                required: 'First name is required.',
                pattern: {
                  value: /^[a-zA-Z]+$/,
                  message: 'First name should contain only characters.'
                }
              })}
              className={`${errors.firstName ? 'input-error' : ''}`}
            />
            {errors.firstName && (
              <p className="errorMsg">{errors.firstName.message}</p>
            )}
          </Form.Group>

          <Form.Group controlId="lastName">
            <Form.Label><FormattedMessage id="lname" /></Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              //placeholder="Enter your last name"
              autoComplete="off"
              ref={register({
                required: 'Last name is required.',
                pattern: {
                  value: /^[a-zA-Z]+$/,
                  message: 'Last name should contain only characters.'
                }
              })}
              className={`${errors.lastName ? 'input-error' : ''}`}
            />
            {errors.lastName && (
              <p className="errorMsg">{errors.lastName.message}</p>
            )}
          </Form.Group>

          {isCompany &&
            <Form.Group controlId="companyName">
            <Form.Label><FormattedMessage id="companyName" /></Form.Label>
              <Form.Control
                type="text"
                name="companyName"
                placeholder="Enter the company name"
                autoComplete="off"
                ref={register({
                  required: 'Company Name is required.',
                })}
                className={`${errors.companyName ? 'input-error' : ''}`}
              />
              {errors.companyName && (
                <p className="errorMsg">{errors.companyName.message}</p>
              )}
            </Form.Group>
          }
          {isCompany &&
            <Form.Group controlId="companyIdentNum">
            <Form.Label><FormattedMessage id="companyId" /></Form.Label>
              <Form.Control
                type="text"
                name="companyIdentNum"
                //placeholder="Enter the company ID"
                autoComplete="off"
                ref={register({})}
                className={`${errors.companyIdentNum ? 'input-error' : ''}`}
              />
              {errors.companyIdentNum && (
                <p className="errorMsg">{errors.companyIdentNum.message}</p>
              )}
            </Form.Group>
          }
          {isCompany &&
            <Form.Group controlId="companyVatNumber">
            <Form.Label><FormattedMessage id="vatNum" /></Form.Label>
              <Form.Control
                type="text"
                name="companyVatNumber"
                //placeholder="Enter the company VAT Number"
                autoComplete="off"
                ref={register({})}
                className={`${errors.companyVatNumber ? 'input-error' : ''}`}
              />
              {errors.companyVatNumber && (
                <p className="errorMsg">{errors.companyVatNumber.message}</p>
              )}
            </Form.Group>
          }
          {isCompany &&
            <Form.Group controlId="companyAddress">
            <Form.Label><FormattedMessage id="address" /></Form.Label>
              <Form.Control
                type="text"
                name="companyAddress"
                //placeholder="Enter the company Address"
                autoComplete="off"
                ref={register({
                  required: 'Company Address is required.',
                })}
                className={`${errors.companyAddress ? 'input-error' : ''}`}
              />
              {errors.companyAddress && (
                <p className="errorMsg">{errors.companyAddress.message}</p>
              )}
            </Form.Group>
          }
          <div className="flexbox-container-horiz-no-border">
            {isCompany &&
              <Form.Group controlId="companyCity" style={{ paddingRight: '15px' }}>
              <Form.Label><FormattedMessage id="city" /></Form.Label>
                <Form.Control
                  type="text"
                  name="companyCity"
                  //placeholder="Enter the company City"
                  autoComplete="off"
                  ref={register({
                    required: 'Company City is required.',
                  })}
                  className={`${errors.companyCity ? 'input-error' : ''}`}
                />
                {errors.companyCity && (
                  <p className="errorMsg">{errors.companyCity.message}</p>
                )}
              </Form.Group>
            }
            {isCompany &&
              <Form.Group controlId="companyZip">
              <Form.Label><FormattedMessage id="postcode" /></Form.Label>
                <Form.Control
                  type="text"
                  name="companyZip"
                  //placeholder="Enter the company Postcode"
                  autoComplete="off"
                  ref={register({
                    required: 'Company Postcode is required.',
                  })}
                  className={`${errors.companyZip ? 'input-error' : ''}`}
                />
                {errors.companyZip && (
                  <p className="errorMsg">{errors.companyZip.message}</p>
                )}
              </Form.Group>
            }
          </div>
          <Form.Group controlId="wayOfPayment">
            <Form.Label><FormattedMessage id="plsChooseWayOP" /></Form.Label>
            <Form.Control
              as="select"
              name="wayOfPayment"
              value={wayOfPayment}
              ref={register({})}
              onChange={(event) => setWayOfPayment(event.target.value)} >
              {waysOfPayment.map(({ isoCode, name }) => (
                <option value={isoCode} key={isoCode}>
                  {name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {(wayOfPayment === 'IBAN') &&
            <Form.Group controlId="ibanHolderName">
            <Form.Label><FormattedMessage id="recipientName" /></Form.Label>
              <Form.Control
                type="text"
                name="ibanHolderName"
                placeholder="Enter your IBAN's Holder Name"
                autoComplete="off"
                ref={register({
                  required: 'IBAN Holder Name is required.'
                })}
                className={`${errors.ibanHolderName ? 'input-error' : ''}`}
              />
              {errors.ibanHolderName && (
                <p className="errorMsg">{errors.ibanHolderName.message}</p>
              )}
            </Form.Group>
          }
          {(wayOfPayment === 'IBAN') &&
            <Form.Group controlId="iban">
              <Form.Label>IBAN</Form.Label>
              <Form.Control
                type="text"
                name="iban"
                placeholder="Enter your IBAN"
                autoComplete="off"
                ref={register({
                  required: 'IBAN is required.',
                  pattern: {
                    value: /^[a-zA-Z0-9]+$/,
                    message: 'IBAN should contain only characters and numbers.'
                  }
                })}
                className={`${errors.iban ? 'input-error' : ''}`}
              />
              {errors.iban && (
                <p className="errorMsg">{errors.iban.message}</p>
              )}
            </Form.Group>
          }
          {(wayOfPayment === 'IBAN') &&
            <Form.Group controlId="bankName">
            <Form.Label><FormattedMessage id="bankName" /></Form.Label>
              <Form.Control
                type="text"
                name="bankName"
                placeholder="Enter your Bank's name"
                autoComplete="off"
                ref={register({
                  required: 'Last name is required.'
                })}
                className={`${errors.bank_name ? 'input-error' : ''}`}
              />
              {errors.lastName && (
                <p className="errorMsg">{errors.bank_name.message}</p>
              )}
            </Form.Group>
          }
          <Button variant="primary" type="submit"><FormattedMessage id="save" /></Button>
        </Form >
      </div>
    </div>
  );
};

export default EditSellerAccount;
