import React, { useState, useEffect, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import Swal from 'sweetalert2';
import qs from "qs";
import { BASE_API_URL } from '../../utils/constants';
import { useDispatch, useSelector } from "react-redux";

import {
  logout,
  selectEmailNotAlreadyUsedResponse,
  selectEmailNotAlreadyUsedStatus,
  emailNotAlreadyUsed,
} from "../../slices/auth";

import {
  selectPartner,
  fetchPartnerByPpcAsync
} from '../admin-home/adminHomeSlice';

const RegAgent = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const lang = props.lang;
  const [user, setUser] = useState(false);
  const [adminSettings, setAdminSettings] = useState(null);
  const intl = useIntl(); //intl.formatMessage({ id: 'certId' })
  const [country, setCountry] = useState("Deutschland");
  const [loading, setLoading] = useState(false);
  const emailNotAlreadyUsedResponse = useSelector(
    selectEmailNotAlreadyUsedResponse
  );
  const emailNotAlreadyUsedStatus = useSelector(
    selectEmailNotAlreadyUsedStatus
  );
  const partner = useSelector(
    selectPartner
  );
  async function fetchAdminSettings() {
    const { data } = await axios.get(`${BASE_API_URL}/adminSettings/public`);
    setAdminSettings(data);
  }

  const queryStrings = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });

  const partnerPublicCode = queryStrings.ppc;

  const dispatch = useDispatch();
  dispatch(logout());

  useEffect(() => {
    if (!adminSettings) {
      fetchAdminSettings();
      dispatch(fetchPartnerByPpcAsync({ partnerPublicCode }));
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { register, errors, handleSubmit, watch } = useForm({
    defaultValues: {
    },
  });


  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    if (emailNotAlreadyUsedStatus === "loading") {
      setLoading(true);
    }
    if (loading && emailNotAlreadyUsedStatus === "idle") {
      setLoading(false);
      if (emailNotAlreadyUsedResponse.outcome) {
        submitForm();
      } else {
        errors.email = { message: intl.formatMessage({ id: "emailAInUse" }) };
      }
    }
  });

  const onSubmit = async (data, event) => {
    event.preventDefault();

    console.log({ data });
    data.sellerType = 'Agent';
    data.userType = 'Agent';
    data.lang = lang;

    data.agentData = {};
    data.agentData.agentId = data.agentId;
    data.agentData.officeId = data.officeId;
    data.agentData.partnerId = partner.id;
    data.country = country;
    delete (data.agentId);
    delete (data.officeId);

    console.log({ data });
    setUser(data);

    dispatch(emailNotAlreadyUsed(data));
  };

  async function submitForm() {
    try {
      await axios.post(`${BASE_API_URL}/register`, {
        ...user,
        //...updatedData
      });
      Swal.fire(
        intl.formatMessage({ id: 'succReg' }),
        intl.formatMessage({ id: 'succReg2' }),
        'success'
      ).then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          props.history.push('/login');
        }
      });
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data, //"Email already exists" //
        });
        console.log('error', error.response.data);
      }
    }

  }

  return (
    <div className="content-container">

      <Form className='form-horizontal' onSubmit={handleSubmit(onSubmit)}>
        <motion.div
          className='col-md-12'
          initial={{ x: '-100vw' }}
          animate={{ x: 0 }}
          transition={{ stiffness: 150 }}>
          <div style={{ textAlign: 'center' }}>

            <div style={{
              maxWidth: '300px',
              display: 'inline-block'
            }}>
              <img height="auto" style={{ width: '100%', height: 'auto', maxHeight: '200px' }} src={`https://eco2wo.com/api/admin/partners/logoFile/byPPC/${partnerPublicCode}`} />
            </div>

            <h1>
              <FormattedMessage id="registerAgent" />
            </h1>
            <br />
          </div>
          <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'agentRegText1' }) }} />
          <FormattedMessage id="agentRegText2" values={{ what: '1' }} />
          <a href={`https://eco2wo.com/api/admin/partners/tncFile/byPPC/${partnerPublicCode}`}>
            <FormattedMessage id={partner ? partner.partnerCommType==='Marketing'? 'agentRegText3m' : 'agentRegText3' : 'agentRegText3'} />
          </a>
          <br />
          <br />
          <Form.Group controlId="firstName">
            <Form.Label>
              <FormattedMessage id="fname" />
            </Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              autoComplete="off"
              ref={register({
                required: "First name is required.",
              })}
              className={`${errors.firstName ? "input-error" : ""}`}
            />
            {errors.firstName && (
              <p className="errorMsg">{errors.firstName.message}</p>
            )}
          </Form.Group>

          <Form.Group controlId="lastName">
            <Form.Label>
              <FormattedMessage id="lname" />
            </Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              //placeholder={intl.formatMessage({ id: 'enterYourLastName' })}
              autoComplete="off"
              ref={register({
                required: "Last name is required.",
              })}
              className={`${errors.lastName ? "input-error" : ""}`}
            />
            {errors.lastName && (
              <p className="errorMsg">{errors.lastName.message}</p>
            )}
          </Form.Group>

          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              //placeholder={intl.formatMessage({ id: 'enterYourEmail' })}
              autoComplete="off"
              ref={register({
                required: "Email is required.",
                pattern: {
                  value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                  message: "Email is not valid.",
                },
              })}
              className={`${errors.email ? "input-error" : ""}`}
            />
            {errors.email && <p className="errorMsg">{errors.email.message}</p>}
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>
              <FormattedMessage id="password" />
            </Form.Label>
            <Form.Control
              type="password"
              name="password"
              //placeholder={intl.formatMessage({ id: 'choosePassword' })}
              autoComplete="off"
              ref={register({
                required: "Password is required.",
                minLength: {
                  value: 6,
                  message: "Password should have at-least 6 characters.",
                },
              })}
              className={`${errors.password ? "input-error" : ""}`}
            />
            {errors.password && (
              <p className="errorMsg">{errors.password.message}</p>
            )}
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>
              <FormattedMessage id="confirmPassword" />
            </Form.Label>
            <Form.Control
              type="password"
              name="confirmPassword"
              //placeholder={intl.formatMessage({ id: 'choosePassword' })}
              autoComplete="off"
              ref={register({
                required: "Repeat password is required.",
                validate: (value) =>
                  value === password.current || "The passwords do not match",
              })}
              className={`${errors.confirmPassword ? "input-error" : ""}`}
            />
            {errors.confirmPassword && (
              <p className="errorMsg">{errors.confirmPassword.message}</p>
            )}
          </Form.Group>
          <Form.Group controlId="companyName">
            <Form.Label>
              <FormattedMessage id="companyName" />
            </Form.Label>
            <Form.Control
              type="text"
              name="companyName"
              disabled={true}
              value={partner ? partner.companyName : ''}
              autoComplete="off"
              ref={register({
                required: "Company Name is required.",
              })}
              className={`${errors.companyName ? "input-error" : ""}`}
            />
            {errors.companyName && (
              <p className="errorMsg">{errors.companyName.message}</p>
            )}
          </Form.Group>
          <Form.Group controlId="groupAgentId">
            <Form.Label>
              <FormattedMessage id="agentId" />
            </Form.Label>
            <Form.Control
              type="text"
              name="agentId"
              autoComplete="off"
              ref={register({
                required: "Agent ID is required.",
              })}
              className={`${errors.agentId ? "input-error" : ""}`}
            />
            {errors.agentId && (
              <p className="errorMsg">{errors.agentId.message}</p>
            )}
          </Form.Group>
          <Form.Group controlId="groupOfficeId">
            <Form.Label>
              <FormattedMessage id="officeId" />
            </Form.Label>
            <Form.Control
              type="text"
              name="officeId"
              autoComplete="off"
              ref={register({
                required: "office Id is required.",
              })}
              className={`${errors.officeId ? "input-error" : ""}`}
            />
            {errors.officeId && (
              <p className="errorMsg">{errors.officeId.message}</p>
            )}
          </Form.Group>
          {partner && (partner.payPartnerNotAgents === false) &&
            <>
              <Form.Group controlId='iban'>
                <Form.Label>IBAN</Form.Label>
                <Form.Control
                  type='text'
                  name='iban'
                  //placeholder='Enter your IBAN'
                  autoComplete='off'
                  ref={register({
                    required: 'IBAN is required.',
                    pattern: {
                      value: /^[a-zA-Z0-9]+$/,
                      message: 'IBAN should contain only characters and numbers.',
                    },
                  })}
                  className={`${errors.iban ? 'input-error' : ''}`}
                />
                {errors.iban && (
                  <p className='errorMsg'>{errors.iban.message}</p>
                )}
              </Form.Group>
              <Form.Group controlId='ibanHolderName'>
                <Form.Label>
                  <FormattedMessage id='recipientName' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='ibanHolderName'
                  //placeholder="Enter your IBAN's Holder Name"
                  autoComplete='off'
                  ref={register({
                    required: 'IBAN Holder Name is required.',
                  })}
                  className={`${errors.ibanHolderName ? 'input-error' : ''}`}
                />
                {errors.ibanHolderName && (
                  <p className='errorMsg'>{errors.ibanHolderName.message}</p>
                )}
              </Form.Group>
              <Form.Group controlId='bankName'>
                <Form.Label>
                  <FormattedMessage id='bankName' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='bankName'
                  //placeholder="Enter your Bank's name"
                  autoComplete='off'
                  ref={register({
                    required: 'Bank name is required.',
                  })}
                  className={`${errors.bank_name ? 'input-error' : ''}`}
                />
                {errors.bank_name && (
                  <p className='errorMsg'>{errors.bank_name.message}</p>
                )}
              </Form.Group>
            </>
          }

          <Form.Group controlId="agree01">
            <Form.Check
              type="checkbox"
              name="agree01"
              feedback="You must agree before submitting."
              feedbackType="invalid">
              <Form.Check.Input required />
              <Form.Check.Label style={{ paddingTop: '5px' }}>
                <FormattedMessage id="agreeTo" />


                <a href={`https://eco2wo.com/api/admin/partners/tncFile/byPPC/${partnerPublicCode}`}>
                  <FormattedMessage id={partner ? partner.partnerCommType==='Marketing'? 'markTnc' : 'salesTnc' : 'salesTnc'} />
                </a>
              </Form.Check.Label>
              <Form.Control.Feedback type="invalid" tooltip={false}>
                You must agree before submitting.
              </Form.Control.Feedback>
            </Form.Check>
          </Form.Group>
          <div style={{ height: '10px' }}></div>
          <Button variant='primary' type='submit'>
            &nbsp;&nbsp;<FormattedMessage id='register' />&nbsp;&nbsp;
          </Button>
        </motion.div>
      </Form>
    </div>
  );
};

export default RegAgent;
