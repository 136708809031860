import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, selectIsLoggedIn } from '../../slices/auth';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { BASE_API_URL } from '../../utils/constants';
import fileDownload from 'js-file-download'
import http, { newAxiosWithJwt } from "../../http-common";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import {
  selectStats,
  fetchAdminStatsAsync,
  createCreditNotesAsync
} from './adminHomeSlice';

let needsReloading = true;

export default function AdminStats(props) {
  const stats2 = useSelector(selectStats);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (needsReloading) {
      dispatch(fetchAdminStatsAsync());
      needsReloading = false;
    }
    console.log({ stats2 })
  });

  const cellLeftAlign = { 'text-align': "left", padding: '2px' };
  const cellRightAlign = { 'text-align': "right", padding: '2px' };
  const cellCenterAlign = { 'text-align': "center", padding: '2px' };

  function headerHeightSetter() {
    var padding = 2;
    var height = 50 + padding;
    this.api.setHeaderHeight(height);
    this.api.resetRowHeights();
  }

  if (!user) {
    props.history.push('/login');
  }

  function downloadCommercialFleetPaid() {
    download('CommercialFleet', 'Paid')
  }

  function downloadCommercialFleetVal() {
    download('CommercialFleet', 'Validated')
  }

  function download(selType, certStatus) {
    const href = BASE_API_URL + `/admin/co2savers/cfcnzip?sellerType=${selType}&certStatus=${certStatus}`;
    handleDownload(href, `CreditNotes-${certStatus}.zip`);
  }

  function handleDownload(url, filename) {
    newAxiosWithJwt(user).get(url, {
      responseType: 'blob',
    })
      .then((res) => {
        fileDownload(res.data, filename)
      })
  }

  return (

    <div>
      <header className="">
        <h3>Stats</h3>
        On this page you can view various statistics about the platform.
      </header>
      <br />
      <div className="ag-theme-alpine" style={{ height: 200, width: '100%' }}>
        <AgGridReact style={{ height: 800, width: '100%' }}
          onFirstDataRendered={headerHeightSetter}
          rowData={stats2}
          defaultColDef={{
            flex: 1,
            editable: true,
            cellStyle: cellCenterAlign,
            headerClass: "header-text-center",
            wrapText: true
          }}
          default key="id" rowSelection='multiple'
        >
          <AgGridColumn field="countUsersPrivate" title="Total Pvt. Users" />
          <AgGridColumn field="countUsersComFleet" title="Total Fleet Users" />
          <AgGridColumn field="countUsersChStations" title="Total Ch. Stations Users" />
          <AgGridColumn field="totalCO2" title="Total Valid tCO2" />
          <AgGridColumn field="totalCO2Sold" title="Total Sold tCO2" />
          <AgGridColumn field="totalQuotaPayments" title="Total Payments" />
          <AgGridColumn field="totalQuotaPaid" title="Total Paid-out" />
        </AgGridReact>
      </div >
      <br />
      <br />
      <div>
        <h2>Export Information&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>
        <hr />
        To proceed with downloading VALIDATED Credit Notes please click here &nbsp;&nbsp;&nbsp;
        <button className="btn btn-success btn-success-thin" onClick={downloadCommercialFleetVal} >VALIDATED Fleets Credit Notes</button>
        <br/><br/>
        To proceed with downloading PAID Credit Notes please click here &nbsp;&nbsp;&nbsp;
        <button className="btn btn-success btn-success-thin" onClick={downloadCommercialFleetPaid} >PAID Fleets Credit Notes</button>
      </div>
    </div>
  );

}
