import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link } from "react-router-dom";

import qs from "qs";

import { clearMessage } from "../slices/message";
import { FormattedMessage } from "react-intl";
import ECOwoCard from "./ECOwoCard";

import {
  selectPartner,
  fetchPartnerByPpcAsync
} from '../features/admin-home/adminHomeSlice';

import {
  logout,
} from "../slices/auth";
var partnerLoadSent = false;

const Landing = (props) => {

  const dispatch = useDispatch();
  // dispatch(logout());

  const partner = useSelector(
    selectPartner
  );

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const queryStrings = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });

  const partnerPublicCode = queryStrings.ppc;

  useEffect(() => {
    if (!partnerLoadSent) {
      dispatch(fetchPartnerByPpcAsync({ partnerPublicCode }));
      partnerLoadSent = true;
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const wlPartnerId = 23;

  return (
    <div className="row content-container" style={{ width: "1000px" }}>
      <div style={{ padding: '15px', paddingLeft: '30px', paddingRight: '30px', textAlign: 'center' }}>
        <h3>
          <FormattedMessage id="landingTitle" />
        </h3>
        {partner &&
          (
            <div>
              <div style={{
                maxWidth: '400px',
                display: 'inline-block'
              }}>
                <img height="auto" style={{ width: '100%', height: 'auto', maxHeight: '200px' }} src={`https://eco2wo.com/api/admin/partners/logoFile/${partner.id}`} />

              </div>
            </div>
          )
        }
        <br />
        <br />
        {partner &&
          <h6>
            {/* {(agentUser && agentUser.agent && agentUser.agent.partner && agentUser.agent.partner.additionalPaymentAmount > 0) && */}

            <FormattedMessage id={partner.id !== wlPartnerId ? "landingIntro" : "landingIntroWl"} 
                values={{
                  what: partner ? partner.companyName : '',
                  thisMany: partner ? partner.additionalPaymentAmount : '',
                  partnerPvtVBasePayAmount: partner ? partner.pvtCarPaymentAmount : ''
                }} />
            <br />
            <br />

            {partner.id !== wlPartnerId && partner.additionalPaymentAmount > 0 &&

              <FormattedMessage id="landingMain"
                values={{
                  what: partner ? partner.companyName : '',
                  thisMany: partner ? partner.additionalPaymentAmount : '',
                  partnerPvtVBasePayAmount: partner ? partner.pvtCarPaymentAmount : ''
                }} />
            }

            <FormattedMessage id={partner.id !== wlPartnerId ? "landingMain2" : "landingMainWl"}
              values={{
                what: partner ? partner.companyName : '',
                thisMany: partner ? partner.additionalPaymentAmount : '',
                partnerPvtVBasePayAmount: partner ? partner.pvtCarPaymentAmount : ''
              }} />
            {partner.id === wlPartnerId &&
              <>
                <br /><br />
                < FormattedMessage id="landingMainWl2"
                  values={{
                    what: partner ? partner.companyName : '',
                    thisMany: partner ? partner.additionalPaymentAmount : '',
                    partnerPvtVBasePayAmount: partner ? partner.pvtCarPaymentAmount : ''
                  }} />
              </>
            }
          </h6>
        }
        <br />
        <div className="flexbox-container-horiz-no-border">
          <ECOwoCard
            variation="primary"
            actionButtonText="Jetzt handeln!"
            handleAction={() => props.history.push("/first?ut=po")}
            title="Privater E-Car Besitzer"
            subtitle="Privat"
          >
          </ECOwoCard>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <ECOwoCard
            variation="secondary"
            actionButtonText="Jetzt handeln!"
            handleAction={() => props.history.push("/first?ut=cf")}
            title="Kommerzieller Flottenbetreiber"
            subtitle="Business">
          </ECOwoCard>
        </div>
      </div>
    </div >
  );
};

export default Landing;
