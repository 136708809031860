import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  adminFetchVehicles, adminSaveVehicle, adminFetchAdminSettings,
  adminSaveAdminSettings, adminFetchBuyersInvoices, adminSaveBuyerInvoice,
  adminFetchCO2SaversValidatedOrPaidUnbought,
  saveAdminReferrals, fetchAdminReferrals, fetchAdminStats, createCreditNotes,
  adminDeleteVehicle, adminFetchPartners, adminSavePartner,
  fetchPartnerByPpc, fetchAgentUserByPromoCode,
  adminFetchAgents, payPartner, disableAgent,
  saveAdminCommissions, adminFetchSellerByEmail, saveCo2SaversNewStatus
} from './adminHomeAPI';

const initialState = {

  vehicles: [],
  saveVehicleResult: null,
  saveVehicleStatus: null,

  saveMultVehiclesResult: null,
  saveMultVehiclesStatus: null,

  adminSettings: {},
  saveAdminSettingsResult: null,
  saveAdminSettingsStatus: null,

  buyersInvoices: [],
  saveBuyerInvoiceResult: null,
  saveBuyerInvoiceStatus: null,

  referrals: [],
  saveReferralResult: null,
  saveReferralStatus: null,

  partners: [],
  savePartnerResult: null,
  savePartnerStatus: null,

  partner: null,
  agentUser: null,

  agents: [],

  stats: [],

  disableAgentStatus: 'idle',
  disableAgentResult: null,

  payPartnerStatus: 'idle',
  payPartnerResult: null,
};

export const fetchVehiclesAsync = createAsyncThunk(
  'home/adminFetchVehicles',
  async ({ onlybyPartners, validatedOnly }, { getState }) => {
    const state = getState();
    const response = await adminFetchVehicles(state.auth.user, onlybyPartners, validatedOnly);
    return response.data;
  }
);

export const fetchCO2SaversValidOrPaidUnboughtAsync = createAsyncThunk(
  'home/fetchCO2SaversValidOrPaidUnboughtAsync',
  async (dummy, { getState }) => {
    const state = getState();
    const response = await adminFetchCO2SaversValidatedOrPaidUnbought(state.auth.user);
    return response.data;
  }
);

export const fetchPartnersAsync = createAsyncThunk(
  'home/fetchPartnersAsync',
  async (dummy, { getState }) => {
    const state = getState();
    const response = await adminFetchPartners(state.auth.user);
    return response.data;
  }
);

export const fetchAgentsAsync = createAsyncThunk(
  'home/fetchAgentsAsync',
  async (dummy, { getState }) => {
    const state = getState();
    const response = await adminFetchAgents(state.auth.user);
    return response.data;
  }
);

export const disableAgentAsync = createAsyncThunk(
  'home/disableAgentAsync',
  async ({ id, dis }, { getState }) => {
    const state = getState();
    const response = await disableAgent(id, dis, state.auth.user);
    return response.data;
  }
);

export const fetchPartnerByPpcAsync = createAsyncThunk(
  'home/fetchPartnerByPpcAsync',
  async ({ partnerPublicCode }, { getState }) => {
    const state = getState();
    const response = await fetchPartnerByPpc(partnerPublicCode);
    return response.data;
  }
);

export const fetchAgentUserByPromoCodeAsync = createAsyncThunk(
  'home/fetchAgentUserByPromoCodeAsync',
  async ({ promoCode }, { getState }) => {
    const response = await fetchAgentUserByPromoCode(promoCode);
    return response.data;
  }
);

export const fetchBuyersInvoicesAsync = createAsyncThunk(
  'home/adminBuyersInvoices',
  async (dummy, { getState }) => {
    const state = getState();
    const response = await adminFetchBuyersInvoices(state.auth.user);
    return response.data;
  }
);

export const fetchSellerByEmailAsync = createAsyncThunk(
  'home/sellerByEmailAsync',
  async ({ email }, { getState }) => {
    const state = getState();
    if (email !== '') {
      const response = await adminFetchSellerByEmail(state.auth.user, email);
      return response.data;
    } else {
      return {};
    }
  }
);

export const fetchAdminSettingsAsync = createAsyncThunk(
  'home/adminAdminSettings',
  async (dummy, { getState }) => {
    const state = getState();
    const response = await adminFetchAdminSettings(state.auth.user);
    return response.data;
  }
);

export const saveVehicleDataAsync = createAsyncThunk(
  'home/adminSaveVehicle',
  async ({ co2saverId, data }, { getState }) => {
    const state = getState();
    console.log({ ddhdkuhkdhdkd: data })
    var response = await adminSaveVehicle(co2saverId, data, state.auth.user);
    return response.data;
  }
);

export const payPartnerAsync = createAsyncThunk(
  'home/payPartnerAsync',
  async ({ partnerId, amount }, { getState }) => {
    const state = getState();
    var response = await payPartner(partnerId, amount, state.auth.user);
    return response.data;
  }
);

export const savePartnerAsync = createAsyncThunk(
  'home/adminSavePartner',
  async ({ id, data }, { getState }) => {
    const state = getState();
    var response = await adminSavePartner(id, data, state.auth.user);
    return response.data;
  }
);

export const deleteVehicleAsync = createAsyncThunk(
  'home/adminDeleteVehicle',
  async ({ co2saverId }, { getState }) => {
    const state = getState();
    var response = await adminDeleteVehicle(co2saverId, state.auth.user);
    return response.data;
  }
);

export const saveBuyerInvoiceAsync = createAsyncThunk(
  'home/adminSaveBuyerInvoice',
  async ({ data }, { getState }) => {
    const state = getState();
    const response = await adminSaveBuyerInvoice(data, state.auth.user);
    return response.data;
  }
);

export const saveAdminSettingsAsync = createAsyncThunk(
  'home/adminSaveAdminSettings',
  async ({ data }, { getState }) => {
    const state = getState();
    const response = await adminSaveAdminSettings(data, state.auth.user);
    return response.data;
  }
);

export const fetchAdminReferralsAsync = createAsyncThunk(
  'home/fetchReferral',
  async (dummy, { getState }) => {
    const state = getState();
    const response = await fetchAdminReferrals(state.auth.user);
    return response.data;
  }
);

export const fetchAdminStatsAsync = createAsyncThunk(
  'home/fetchAdminStats',
  async (dummy, { getState }) => {
    const state = getState();
    const response = await fetchAdminStats(state.auth.user);
    return response.data;
  }
);

export const createCreditNotesAsync = createAsyncThunk(
  'home/createCreditNotes',
  async ({ selType }, { getState }) => {
    const state = getState();
    const response = await createCreditNotes(state.auth.user, selType);
    return response.data;
  }
);

export const saveAdminReferralAsync = createAsyncThunk(
  'home/saveReferral',
  async ({ data, newStatus }, { getState }) => {
    const state = getState();
    const response = await saveAdminReferrals(data, newStatus, state.auth.user);
    return response.data;
  }
);
export const saveCo2SaversNewStatusAsync = createAsyncThunk(
  'home/saveCo2SaversNewStatus',
  async ({ data, newStatus }, { getState }) => {
    const state = getState();
    const response = await saveCo2SaversNewStatus(data, newStatus, state.auth.user);
    return response.data;
  }
);

export const saveAdminCommissionsAsync = createAsyncThunk(
  'home/saveAdminCommissionsAsync',
  async ({ data, newStatus }, { getState }) => {
    const state = getState();
    const response = await saveAdminCommissions(data, newStatus, state.auth.user);
    return response.data;
  }
);

export const adminHomeSlice = createSlice({
  name: 'adminHome',
  initialState,
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchVehiclesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVehiclesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log(action.payload.numberOfElements);
        state.vehicles = action.payload.content;
      });
    builder
      .addCase(fetchPartnersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPartnersAsync.rejected, (state) => {
        state.status = 'idle';
        state.partners = [];
      })
      .addCase(fetchPartnersAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.partners = action.payload;
      });
    builder
      .addCase(fetchAgentsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAgentsAsync.rejected, (state) => {
        state.status = 'idle';
        state.partners = [];
      })
      .addCase(fetchAgentsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.agents = action.payload;
      });
    builder
      .addCase(fetchCO2SaversValidOrPaidUnboughtAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCO2SaversValidOrPaidUnboughtAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log(action.payload.numberOfElements);
        state.vehicles = action.payload.content;
      });
    builder
      .addCase(saveVehicleDataAsync.pending, (state) => {
        state.saveVehicleStatus = 'loading';
      })
      .addCase(saveVehicleDataAsync.fulfilled, (state, action) => {
        state.saveVehicleStatus = 'idle';
        state.saveVehicleResult = action.payload;
      });
    builder
      .addCase(deleteVehicleAsync.pending, (state) => {
        state.saveVehicleStatus = 'loading';
      })
      .addCase(deleteVehicleAsync.fulfilled, (state, action) => {
        state.saveVehicleStatus = 'idle';
        state.saveVehicleResult = action.payload;
      });

    builder
      .addCase(fetchAdminSettingsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAdminSettingsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.adminSettings = action.payload;
      });

    builder
      .addCase(fetchPartnerByPpcAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPartnerByPpcAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.partner = action.payload;
      });

    builder
      .addCase(fetchAgentUserByPromoCodeAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAgentUserByPromoCodeAsync.rejected, (state) => {
        state.status = 'idle';
        state.agentUser = { error: 'notFound' };
      })
      .addCase(fetchAgentUserByPromoCodeAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.agentUser = action.payload;
      });

    builder
      .addCase(saveAdminSettingsAsync.pending, (state) => {
        state.saveAdminSettingsStatus = 'loading';
      })
      .addCase(saveAdminSettingsAsync.fulfilled, (state, action) => {
        state.saveAdminSettingsStatus = 'idle';
        state.saveAdminSettingsResult = action.payload;
      });

    builder
      .addCase(fetchBuyersInvoicesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBuyersInvoicesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log(action.payload);
        state.buyersInvoices = action.payload;
      });

    builder
      .addCase(fetchSellerByEmailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSellerByEmailAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log(action.payload);
        state.sellerByEmail = action.payload;
      });

    builder
      .addCase(saveBuyerInvoiceAsync.pending, (state) => {
        state.saveBuyerInvoiceStatus = 'loading';
      })
      .addCase(saveBuyerInvoiceAsync.fulfilled, (state, action) => {
        state.saveBuyerInvoiceStatus = 'idle';
        console.log(action.payload);
        state.saveBuyerInvoiceResult = action.payload;
      });
    builder
      .addCase(fetchAdminReferralsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAdminReferralsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.referrals = action.payload;
      });
    builder
      .addCase(saveAdminReferralAsync.pending, (state) => {
        state.saveReferralStatus = 'loading';
      })
      .addCase(saveAdminReferralAsync.rejected, (state) => {
        state.saveReferralStatus = 'idle';
        state.saveReferralResult = { error: "error" };
      })
      .addCase(saveAdminReferralAsync.fulfilled, (state, action) => {
        state.saveReferralStatus = 'idle';
        state.saveReferralResult = action.payload;
      });
    builder
      .addCase(saveCo2SaversNewStatusAsync.pending, (state) => {
        state.saveMultVehiclesStatus = 'loading';
      })
      .addCase(saveCo2SaversNewStatusAsync.rejected, (state) => {
        state.saveMultVehiclesStatus = 'idle';
        state.saveMultVehiclesStatus = { error: "error" };
      })
      .addCase(saveCo2SaversNewStatusAsync.fulfilled, (state, action) => {
        console.log('PRE-BBBBB')
        state.saveMultVehiclesStatus = 'idle';
        state.saveMultVehiclesResult = action.payload;
      });
    builder
      .addCase(saveAdminCommissionsAsync.pending, (state) => {
        state.saveReferralStatus = 'loading';
      })
      .addCase(saveAdminCommissionsAsync.rejected, (state) => {
        state.saveReferralStatus = 'idle';
        state.saveReferralResult = { error: "error" };
      })
      .addCase(saveAdminCommissionsAsync.fulfilled, (state, action) => {
        state.saveReferralStatus = 'idle';
        state.saveReferralResult = action.payload;
      });
    builder
      .addCase(savePartnerAsync.pending, (state) => {
        state.savePartnerStatus = 'loading';
      })
      .addCase(savePartnerAsync.rejected, (state) => {
        state.savePartnerStatus = 'idle';
        state.savePartnerResult = { error: "error" };
      })
      .addCase(savePartnerAsync.fulfilled, (state, action) => {
        state.savePartnerStatus = 'idle';
        state.savePartnerResult = action.payload;
      });
    builder
      .addCase(fetchAdminStatsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAdminStatsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log(action.payload);
        const a = [];
        a.push(action.payload);
        state.stats = a;
        console.log(state.stats);
      });
    builder
      .addCase(disableAgentAsync.pending, (state) => {
        state.disableAgentStatus = 'loading';
      })
      .addCase(disableAgentAsync.rejected, (state) => {
        state.disableAgentStatus = 'idle';
        state.disableAgentResult = { error: "error" };
      })
      .addCase(disableAgentAsync.fulfilled, (state, action) => {
        state.disableAgentStatus = 'idle';
        state.disableAgentResult = action.payload;
      });
    builder
      .addCase(payPartnerAsync.pending, (state) => {
        state.payPartnerStatus = 'loading';
      })
      .addCase(payPartnerAsync.rejected, (state) => {
        state.payPartnerStatus = 'idle';
        state.payPartnerResult = { error: "error" };
      })
      .addCase(payPartnerAsync.fulfilled, (state, action) => {
        state.payPartnerStatus = 'idle';
        state.payPartnerResult = action.payload;
      });
  },
});

export const selectPayPartnerStatus = (state) => state.adminHome.payPartnerStatus;

export const selectPayPartnerResult = (state) => state.adminHome.payPartnerResult;

export const selectDisableAgentStatus = (state) => state.adminHome.disableAgentStatus;

export const selectDisableAgentResult = (state) => state.adminHome.disableAgentResult;

export const selectVehicles = (state) => state.adminHome.vehicles;

export const selectSaveVehicleResult = (state) => state.adminHome.saveVehicleResult;

export const selectSaveVehicleStatus = (state) => state.adminHome.saveVehicleStatus;

export const selectAdminSettings = (state) => state.adminHome.adminSettings;

export const selectSaveAdminSettingsResult = (state) => state.adminHome.saveAdminSettingsResult;

export const selectSaveAdminSettingsStatus = (state) => state.adminHome.saveAdminSettingsStatus;

export const selectBuyersInvoices = (state) => state.adminHome.buyersInvoices;

export const selectSellerByEmail = (state) => state.adminHome.sellerByEmail;

export const selectSaveBuyerInvoiceResult = (state) => state.adminHome.saveBuyerInvoiceResult;

export const selectSaveBuyerInvoiceStatus = (state) => state.adminHome.saveBuyerInvoiceStatus;

export const selectReferrals = (state) => state.adminHome.referrals;

export const selectPartners = (state) => state.adminHome.partners;

export const selectAgents = (state) => state.adminHome.agents;

export const selectPartner = (state) => state.adminHome.partner;

export const selectAgentUser = (state) => state.adminHome.agentUser;

export const selectStats = (state) => state.adminHome.stats;

export const selectSaveReferralResult = (state) => state.adminHome.saveReferralResult;

export const selectSaveReferralStatus = (state) => state.adminHome.saveReferralStatus;

export const selectSavePartnerResult = (state) => state.adminHome.savePartnerResult;

export const selectSavePartnerStatus = (state) => state.adminHome.savePartnerStatus;

export const selectSaveMultVehiclesResult = (state) => state.adminHome.saveMultVehiclesResult;

export const selectSaveMultVehiclesStatus = (state) => state.adminHome.saveMultVehiclesStatus;

export default adminHomeSlice.reducer;
