import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { IntlProvider, useIntl } from "react-intl";
import { fetchVehiclesAsync, selectVehicles } from "./homeSlice";
import { selectUser, selectSeller } from "../../slices/auth";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import EditRowButtonRenderer from "./editButtonRenderer";
import DocsIconsListRenderer from "./iconsListRenderer";
import EditVehicleByOwner from "./EditVehicleByOwner";
import sellerType from "../sellerType";
import serverSideEnums from "../../common/serverSideEnums";

let needsReloading = true;

export default function Home2(props) {
  const vehicles = useSelector(selectVehicles);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const seller = useSelector(selectSeller);
  const intl = useIntl();
  const statusTranslations = serverSideEnums(intl).status;

  if (!user) {
    props.history.push('/login');
  }

  useEffect(() => {
    if (needsReloading) {
      dispatch(fetchVehiclesAsync());
      needsReloading = false;
    }
  });

  function onClosePopup() {
    dispatch(fetchVehiclesAsync());
  }

  const cellLeftAlign = { "text-align": "left", padding: "2px" };
  const cellRightAlign = { "text-align": "right", padding: "2px" };
  const cellCenterAlign = { "text-align": "center", padding: "2px" };

  const { isPrivate, isFleet, isChargeSt, isWithVehicles, isCompany } =
    sellerType(seller.sellerType);

  function headerHeightSetter() {
    var padding = 2;
    var height = 50 + padding;
    this.api.setHeaderHeight(height);
    this.api.resetRowHeights();
  }

  function dateFormatter(params) {
    var newDate = new Date();
    newDate.setTime(Date.parse(params.value));
    return newDate.toLocaleDateString();
  }

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
    onClosePopup();
  };

  var gridApi;
  var gridColumnApi;

  const onGridReady = (params) => {
    gridApi = params.api;
    gridColumnApi = params.columnApi;
    params.columnApi.getColumn("generatedId").setSort("asc");
  };

  const powerDescs = {
    LocalSustainable: "Local/Sust.",
    Public: "Public",
  };

  return (
    <div
      className="content-container"
      style={{ height: 1100, width: "100%", maxWidth: "1800px !important" }}>
      <header className="">
        {!isPrivate && (
          <h3>
            <FormattedMessage id="company" />: {seller.companyName}
          </h3>
        )}
        {isPrivate && (
          <div
            className="flexbox-container-horiz"
            style={{ alignItems: "left", justifyContent: "left", border: 0 }}>
            <h4>
              {" "}
              <FormattedMessage id="welcomeBack" /> {seller.firstName}
              !&nbsp;&nbsp;
            </h4>
            {/*<Link to={"/referrals"} className="nav-link" ><FormattedMessage id="referRFriend" /></Link>*/}
          </div>
        )}
      </header>
      <div
        className="ag-theme-alpine"
        style={{ height: 400, width: "100%", position: "relative" }}>
        <Modal open={open} onClose={onCloseModal} center>
          <EditVehicleByOwner
            data={{}}
            co2saverId={0}
            sellerId={seller.id}
            closePopup={onCloseModal}
          />
        </Modal>
        <div style={{ position: "absolute", top: 13, right: 6, zIndex: 1000 }}>
          <button
            className="btn btn-success btn-success-thin"
            onClick={onOpenModal}>
            <FormattedMessage id="add" />
          </button>
        </div>
        <AgGridReact
          style={{ height: 400, width: "100%" }}
          onFirstDataRendered={headerHeightSetter}
          rowData={vehicles}
          onGridReady={onGridReady}
          frameworkComponents={{
            editRowButtonRenderer: EditRowButtonRenderer,
            docsIconsListRenderer: DocsIconsListRenderer,
          }}
          defaultColDef={{
            flex: 1,
            editable: true,
            cellStyle: cellLeftAlign,
            headerClass: "text-center",
            wrapText: true,
          }}
          default
          sortingOrder={["asc"]}
          key="id">
          <AgGridColumn
            headerName={intl.formatMessage({ id: "certId" })}
            field="generatedId"
            minWidth="100"
            maxWidth="120"
            sortable={true}
            cellStyle={cellLeftAlign}
            filter={true}
          />
          {isFleet && (
            <AgGridColumn
              field="type"
              cellStyle={cellLeftAlign}
              maxWidth="60"
              headerClass="text-left"
              headerName={intl.formatMessage({ id: "type" })}
            />
          )}
          {isWithVehicles && (
            <AgGridColumn
              field="licencePlate"
              cellStyle={cellLeftAlign}
              headerClass="text-left"
              headerName={intl.formatMessage({ id: "licplate" })}
            />
          )}
          {isChargeSt && (
            <AgGridColumn
              field="seller.companyName"
              maxWidth="100"
              cellStyle={cellLeftAlign}
              headerName={intl.formatMessage({ id: "companyName" })}
              headerClass="text-left"
            />
          )}
          {isChargeSt && (
            <AgGridColumn
              field="name"
              maxWidth="100"
              cellStyle={cellLeftAlign}
              headerClass="text-left"
            />
          )}
          {isChargeSt && (
            <AgGridColumn
              field="number"
              maxWidth="40"
              cellStyle={cellRightAlign}
              headerName="Num."
            />
          )}
          {isChargeSt && (
            <AgGridColumn
              field="address"
              maxWidth="120"
              cellStyle={cellLeftAlign}
              headerClass="text-left"
            />
          )}
          {isChargeSt && (
            <AgGridColumn
              field="city"
              maxWidth="100"
              cellStyle={cellLeftAlign}
              headerClass="text-left"
            />
          )}
          {isChargeSt && (
            <AgGridColumn
              field="postcode"
              maxWidth="50"
              cellStyle={cellRightAlign}
              headerName="Post code"
            />
          )}
          {isChargeSt && (
            <AgGridColumn
              field="powerSourceType"
              maxWidth="120"
              cellStyle={cellLeftAlign}
              valueGetter={(params) => {
                return params.data.powerSourceType
                  ? powerDescs[params.data.powerSourceType]
                  : "";
              }}
              headerClass="text-left"
            />
          )}
          <AgGridColumn
            field="creationDate"
            headerName={intl.formatMessage({ id: "submDate" })}
            valueFormatter={dateFormatter}
          />
          <AgGridColumn
            field="year"
            headerName={intl.formatMessage({ id: "year" })}
          />
          {isChargeSt && (
            <AgGridColumn
              field="amountMWGenerated"
              maxWidth="100"
              cellStyle={cellRightAlign}
              headerName="MWH Generated"
            />
          )}
          {isWithVehicles && (
            <AgGridColumn
              headerName={intl.formatMessage({ id: "docs" })}
              cellRendererParams={{ rendererImage: "icon-doc.png" }}
              cellRenderer="docsIconsListRenderer"
              field="fileEvidences"
              maxWidth="120"
            />
          )}
          <AgGridColumn
            field="tco2Saved"
            maxWidth="100"
            cellStyle={cellCenterAlign}
            headerName={intl.formatMessage({ id: "tco2Saved" })}
            valueGetter={(params) => {
              console.log({ params });
              return params.data.tco2Saved
                ? ("" + params.data.tco2Saved).concat(` tCO2`)
                : "";
            }}
          />
          <AgGridColumn
            field="paymentAmount"
            cellStyle={cellCenterAlign}
            headerName={intl.formatMessage({ id: "paymentAmount" })}
            valueGetter={(params) => {
              console.log({ params });
              return params.data.paymentAmount
                ? ("" + params.data.paymentAmount).concat(` €`)
                : "";
            }}
            maxWidth="135"
          />
          <AgGridColumn
            headerName="Status"
            field="status"
            maxWidth="100"
            valueGetter={(params) => {
              return statusTranslations[params.data.status];
            }}
          />
          <AgGridColumn
            headerName=""
            cellRenderer="editRowButtonRenderer"
            cellRendererParams={{ parentOnClosePopup: () => onClosePopup }}
            cellStyle={cellRightAlign}
          />
        </AgGridReact>

        <br />
        <br />
        <h4>
          <FormattedMessage id="nextStepsHeader" />
        </h4>
        <table>
          <tbody>
            <tr>
              <td width="70" align="center">
                <img src="email.png" width="50" height="50" alt="D" />
              </td>
              <td colSpan="2" style={{ paddingLeft: "10px" }}>
                <FormattedMessage id="homeInstrLine1" />
              </td>
            </tr>
            <tr>
              <td align="center">
                <img src="search.png" width="50" height="50" alt="D" />
              </td>
              <td colSpan="2" style={{ paddingLeft: "10px" }}>
                <FormattedMessage id="homeInstrLine2" />
              </td>
            </tr>
            {isPrivate && (
              <tr>
                <td align="center">
                  <img src="car.png" width="70" height="70" alt="D" />
                </td>
                <td colSpan="2" style={{ paddingLeft: "10px" }}>
                  <FormattedMessage id="homeInstrLine3" />
                </td>
              </tr>
            )}
            <tr>
              <td colSpan="3">&nbsp;</td>
            </tr>
            <tr>
              <td colSpan="3">
                <b>
                  <FormattedMessage id="homeInstrLine4" />
                </b>
              </td>
            </tr>
            <tr height="70">
              <td align="center">
                <img src="engineering.png" width="50" height="50" alt="D" />
              </td>
              <td>
                <h6>
                  <FormattedMessage id="statusInProcess" />
                </h6>
              </td>
              <td style={{ paddingLeft: "10px" }}>
                <FormattedMessage id="homeInstrLine5" />
              </td>
            </tr>
            <tr height="70">
              <td align="center">
                <img src="complaint.png" width="50" height="50" alt="D" />
              </td>
              <td>
                <h6>
                  <FormattedMessage id="statusReviewing" />
                </h6>
              </td>
              <td style={{ paddingLeft: "10px" }}>
                <FormattedMessage id="homeInstrLine6" />
              </td>
            </tr>
            <tr height="70">
              <td align="center">
                <img src="file.png" width="50" height="50" alt="D" />
              </td>
              <td>
                <h6>
                  <FormattedMessage id="statusRejected" />
                </h6>
              </td>
              <td style={{ paddingLeft: "10px" }}>
                <FormattedMessage id="homeInstrLine7" />
              </td>
            </tr>
            <tr height="70">
              <td align="center">
                <img src="valid.png" width="50" height="50" alt="D" />
              </td>
              <td>
                <h6>
                  <FormattedMessage id="statusValidated" />
                </h6>
              </td>
              <td style={{ paddingLeft: "10px" }}>
                <FormattedMessage id="homeInstrLine8" />
              </td>
            </tr>
            <tr height="70">
              <td align="center">
                <img src="paid.png" width="50" height="50" alt="D" />
              </td>
              <td>
                <h6>
                  <FormattedMessage id="statusPaid" />
                </h6>
              </td>
              <td style={{ paddingLeft: "10px" }}>
                <FormattedMessage id="homeInstrLine9" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
