import React, { useEffect, useState } from 'react';
import EditVehicleByOwner from './EditVehicleByOwner';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import {
  duplicateVehicleAsync,
  selectSaveVehicleResult,
  selectSaveVehicleStatus
} from './homeSlice';
import DuplicateVehicleByOwner from './DuplicateVehicleByOwner';

var saveButtonClicked = false;

const EditButtonRenderer = (props) => {
  const rowData = props.data;
  const parentOnClosePopup = props.parentOnClosePopup();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openDupl, setOpenDupl] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onOpenDuplModal = () => setOpenDupl(true);


  const onCloseModal = () => {
    setOpen(false);
    if (parentOnClosePopup) parentOnClosePopup();
  }
  const onCloseDuplModal = () => {
    setOpenDupl(false);
    if (parentOnClosePopup) parentOnClosePopup();
  }

  return (
    <>
      {(rowData.status === 'InProcess' || rowData.status === 'Rejected') && (rowData.year === new Date().getFullYear()) &&
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', top: -4, right: 0 }}>
            <button className="btn btn-success btn-success-thin"
              onClick={onOpenModal}><FormattedMessage id="edit" />
            </button>
          </div>
          <Modal open={open} onClose={onCloseModal} center>
            <EditVehicleByOwner data={rowData} sellerId={rowData.seller.id} co2saverId={rowData.id} closePopup={onCloseModal} />
          </Modal>
        </div>
      }
      {(rowData.status !== 'Rejected') && (rowData.year !== new Date().getFullYear()) && (!rowData.movedToNextYear) &&
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', top: -4, right: 0 }}>
            <button className="btn btn-success btn-success-thin"
              onClick={onOpenDuplModal}><FormattedMessage id="moveToYear" values={{ year: new Date().getFullYear() }} />
            </button>
          </div>
          <Modal open={openDupl} onClose={onCloseDuplModal} center>
            <DuplicateVehicleByOwner data={rowData} sellerId={rowData.seller.id} co2saverId={rowData.id} closePopup={onCloseDuplModal} />
          </Modal>
        </div>
      }
    </>
  );
};

export default EditButtonRenderer;