import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, selectIsLoggedIn, selectSeller } from '../../slices/auth';
import { setSeller } from '../../slices/auth';
import sellerType from '../sellerType';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl'

import {
  saveSellerAccountAsync,
  selectSaveSellerAccountResult,
  selectSaveSellerAccountStatus
} from '../home/homeSlice';

var saveButtonClicked = false;

const EditAgentAccount = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const saveSellerAccountStatus = useSelector(selectSaveSellerAccountStatus);
  const saveSellerAccountResult = useSelector(selectSaveSellerAccountResult);
  const agentUser = useSelector(selectSeller);
  const intl = useIntl(); //intl.formatMessage({ id: 'certId' })
  const user = useSelector(selectUser);

  if (!user) {
    props.history.push('/login');
  }

  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      ...agentUser
    }
  });

  const onSubmit = async (data, event) => {
    event.preventDefault();
    data = { ...agentUser, ...data };
    data.sellerType = 'Agent';
    data.userType = 'Agent';

    data.agentData = {};
    data.agentData.agentId = data.agentId;
    data.agentData.officeId = data.officeId;
    delete (data.agentId);
    delete (data.officeId);
    dispatch(saveSellerAccountAsync({ data }));
  };


  useEffect(() => {
    console.log(saveSellerAccountStatus);
    if (saveSellerAccountStatus === 'idle' && saveButtonClicked) {
      saveButtonClicked = false;
      dispatch(setSeller(saveSellerAccountResult));
      Swal.fire('Awesome!', "You've updated your account settings!", 'success').then(
        (result) => {
          if (result.isConfirmed || result.isDismissed) {
            props.history.push('/profile');
          }
        }
      );
      return;
    }

    if (saveSellerAccountStatus === 'loading') {
      saveButtonClicked = true;
    }
  });

  return (
    <div>
      <div className="col-md-6 offset-md-3" >
        <Form className="input-form" onSubmit={handleSubmit(onSubmit)}>
          <header className="">
            <h3><FormattedMessage id="accInfo" /></h3>
            You can use this page to edit your account's information.
          </header>
          <br />
          <Form.Group controlId="emailGroup">
            <Form.Label>{`Email:  ${agentUser.email}`}</Form.Label>
          </Form.Group>
          <hr />
          <Form.Group controlId="passGroup">
            <div className="flexbox-container-horiz-no-border">
              <Form.Label><FormattedMessage id="toChangePassClick" />&nbsp;&nbsp;&nbsp;</Form.Label>
              <Button variant="primary" type="button" onClick={() => { props.history.push('/setNewPassword'); }}>
                <FormattedMessage id="changePassword" />
              </Button>
            </div>
          </Form.Group>
          <hr />
          <Form.Group controlId="firstName">
            <Form.Label><FormattedMessage id="fname" /></Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              //placeholder="Enter your first name"
              autoComplete="off"
              ref={register({
                required: 'First name is required.',
                pattern: {
                  value: /^[a-zA-Z]+$/,
                  message: 'First name should contain only characters.'
                }
              })}
              className={`${errors.firstName ? 'input-error' : ''}`}
            />
            {errors.firstName && (
              <p className="errorMsg">{errors.firstName.message}</p>
            )}
          </Form.Group>

          <Form.Group controlId="lastName">
            <Form.Label><FormattedMessage id="lname" /></Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              autoComplete="off"
              ref={register({
                required: 'Last name is required.',
                pattern: {
                  value: /^[a-zA-Z]+$/,
                  message: 'Last name should contain only characters.'
                }
              })}
              className={`${errors.lastName ? 'input-error' : ''}`}
            />
            {errors.lastName && (
              <p className="errorMsg">{errors.lastName.message}</p>
            )}
          </Form.Group>
          <Form.Group controlId="companyName">
            <Form.Label><FormattedMessage id="companyName" /></Form.Label>
            <Form.Control
              type="text"
              name="companyName"
              defaultValue={agentUser.agent ? agentUser.agent.partner.companyName : ''}
              disabled={true}
              autoComplete="off"
              className={`${errors.companyName ? 'input-error' : ''}`}
            />
          </Form.Group>
          <Form.Group controlId="officeIdGr">
            <Form.Label><FormattedMessage id="officeId" /></Form.Label>
            <Form.Control
              type="text"
              name="officeId"
              defaultValue={agentUser.agent ? agentUser.agent.officeId : ''}
              autoComplete="off"
              ref={register({
                required: 'Office ID is required.',
              })}
              className={`${errors.officeId ? 'input-error' : ''}`}
            />
            {errors.officeId && (
              <p className="errorMsg">{errors.officeId.message}</p>
            )}
          </Form.Group>
          <Form.Group controlId="agentIdGr">
            <Form.Label><FormattedMessage id="agentId" /></Form.Label>
            <Form.Control
              type="text"
              name="agentId"
              defaultValue={agentUser.agent ? agentUser.agent.agentId : ''}
              autoComplete="off"
              ref={register({
                required: 'Agent ID is required.',
              })}
              className={`${errors.agentId ? 'input-error' : ''}`}
            />
            {errors.agentId && (
              <p className="errorMsg">{errors.agentId.message}</p>
            )}
          </Form.Group>
          {agentUser.agent && agentUser.agent.partner && (agentUser.agent.partner.payPartnerNotAgents === false) &&
            <>
              <Form.Group controlId='iban'>
                <Form.Label>IBAN</Form.Label>
                <Form.Control
                  type='text'
                  name='iban'
                  autoComplete='off'
                  ref={register({
                    required: 'IBAN is required.',
                    pattern: {
                      value: /^[a-zA-Z0-9]+$/,
                      message: 'IBAN should contain only characters and numbers.',
                    },
                  })}
                  className={`${errors.iban ? 'input-error' : ''}`}
                />
                {errors.iban && (
                  <p className='errorMsg'>{errors.iban.message}</p>
                )}
              </Form.Group>
              <Form.Group controlId='ibanHolderName'>
                <Form.Label>
                  <FormattedMessage id='recipientName' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='ibanHolderName'
                  autoComplete='off'
                  ref={register({
                    required: 'IBAN Holder Name is required.',
                  })}
                  className={`${errors.ibanHolderName ? 'input-error' : ''}`}
                />
                {errors.ibanHolderName && (
                  <p className='errorMsg'>{errors.ibanHolderName.message}</p>
                )}
              </Form.Group>
              <Form.Group controlId='bankName'>
                <Form.Label>
                  <FormattedMessage id='bankName' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='bankName'
                  autoComplete='off'
                  ref={register({
                    required: 'Bank name is required.',
                  })}
                  className={`${errors.bank_name ? 'input-error' : ''}`}
                />
                {errors.bank_name && (
                  <p className='errorMsg'>{errors.bank_name.message}</p>
                )}
              </Form.Group>
            </>
          }
          <Form.Group controlId="promoCodeGr">
            <Form.Label><FormattedMessage id="promoCode" /></Form.Label>
            <Form.Control
              type="text"
              name="promoCode"
              defaultValue={agentUser.agent.promoCode}
              disabled={true}
              autoComplete="off"
              className={`${errors.companyName ? 'input-error' : ''}`}
            />
          </Form.Group>
          <Button variant="primary" type="submit"><FormattedMessage id="save" /></Button>
          <br /><br />
          <FormattedMessage id="yourAccepted" /> & nbsp;
          <a href={'https://eco2wo.com/api/admin/partners/tncFile/' + agentUser.agent.partnerId}><FormattedMessage id="salesTnc" /></a>
        </Form >
      </div>
    </div>
  );
};

export default EditAgentAccount;
