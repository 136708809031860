import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchVehiclesAsync,
  selectVehicles
} from './agentHomeSlice';
import { selectUser, selectSeller } from '../../slices/auth';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import serverSideEnums from '../../common/serverSideEnums';
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl';
import { Button } from 'react-bootstrap';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Label } from '@material-ui/icons';

let needsReloading = true;

export default function AgentHome(props) {
  const vehicles = useSelector(selectVehicles);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const intl = useIntl();
  const statusTranslations = serverSideEnums(intl).status;
  const refStatusTranslations = serverSideEnums(intl).referralStatus;
  const agentUser = useSelector(selectSeller);

  if (!user) {
    props.history.push('/login');
  }

  useEffect(() => {
    if (needsReloading) {
      dispatch(fetchVehiclesAsync());
      needsReloading = false;
    }
  });

  const cellLeftAlign = { 'text-align': "left", padding: '2px' };
  const cellRightAlign = { 'text-align': "right", padding: '2px' };
  const cellCenterAlign = { 'text-align': "center", padding: '2px' };

  function headerHeightSetter() {
    var padding = 2;
    var height = 50 + padding;
    this.api.setHeaderHeight(height);
    this.api.resetRowHeights();
  }

  function dateFormatter(params) {
    var newDate = new Date();
    newDate.setTime(Date.parse(params.value));
    return newDate.toLocaleDateString();
  }

  var gridApi;
  var gridColumnApi;

  const onGridReady = params => {
    gridApi = params.api;
    gridColumnApi = params.columnApi;
    params.columnApi.getColumn('generatedId').setSort("asc")
  };

  function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
  }

  return (
    <div className="" style={{ height: 1100, width: '100%', position: 'relative' }}>
      <header className="flexbox-container-horiz-no-border">
        <div>
          <h4>
            {" "}
            <FormattedMessage id="welcomeBack" /> {agentUser.firstName}
            !&nbsp;&nbsp;<br />
          </h4>
          <h6>
            <FormattedMessage id="agentId" />: {agentUser.agent.agentId}
          </h6>
          <h6>
            <FormattedMessage id="yourPromoCode" />: {agentUser.agent.promoCode}
          </h6>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <table>
          <tr>
            <td><FormattedMessage id="privateECars" />:&nbsp;&nbsp;
            </td>
            <td>
              {agentUser &&
                <Button variant="primary" type="button"
                  // onClick={() => { navigator.clipboard.writeText(props.hostUri + '/first?ut=po&apc=' + agentUser.agent.promoCode) }}
                  onClick={() => { copyToClipboard(props.hostUri + '/first?ut=po&apc=' + agentUser.agent.promoCode) }}
                >
                  Copy Registration Link
                </Button>
              }
            </td>
            <td>&nbsp;&nbsp;
              {agentUser &&
                <a href={`https://eco2wo.com/api/agent/qrCodePrivateOwners/${agentUser.id}`} download="QR-code-pvt-vehicles.jpg" target='_blank'>
                  <Button variant="primary" type="button">Download QR Code</Button>
                </a>
              }
            </td>
          </tr>
          <tr>
            <td><FormattedMessage id="commFleets" />:&nbsp;&nbsp;
            </td><td>
              {agentUser &&
                <Button variant="primary" type="button"
                  // onClick={() => { navigator.clipboard.writeText(props.hostUri + '/first?ut=cf&apc=' + agentUser.agent.promoCode) }}>
                  onClick={() => { copyToClipboard(props.hostUri + '/first?ut=cf&apc=' + agentUser.agent.promoCode) }}
                >
                  Copy Registration Link
                </Button>
              }
            </td>
            <td>&nbsp;&nbsp;
              {agentUser &&
                <a href={`https://eco2wo.com/api/agent/qrCodeCommFleet/${agentUser.id}`} download="QR-code-commerc-fleet.jpg" target='_blank'>
                  <Button variant="primary" type="button">Download QR Code</Button>
                </a>
              }
            </td>
          </tr>
        </table>
      </header>

      <br />

      <h4>
        <FormattedMessage id="agentHomeInstrTitle" />
      </h4>
      <table>
        <tr>
          <td width="70" align="center" style={{ paddingBottom: '10px' }} >
            <img src="promocode.png" width="50" height="50" alt="D" />
          </td>
          <td colSpan="2" style={{ paddingLeft: "10px", paddingBottom: '10px' }}>
            <FormattedMessage id="agentHomeInstr1" />
          </td>
        </tr>
        <tr>
          <td align="center" style={{ paddingBottom: '10px' }}>
            <img src="linkicon.png" width="50" height="50" alt="D" />
          </td>
          <td colSpan="2" style={{ paddingLeft: "10px", paddingBottom: '10px' }}>
            <FormattedMessage id="agentHomeInstr2" />
          </td>
        </tr>
        <tr>
          <td align="center" style={{ paddingBottom: '10px' }}>
            <img src="qrscan1.png" width="70" height="70" alt="D" />
          </td>
          <td colSpan="2" style={{ paddingLeft: "10px", paddingBottom: '10px' }}>
            <FormattedMessage id="agentHomeInstr3" />
          </td>
        </tr>
      </table>

      <div className="ag-theme-alpine" style={{ height: 735, width: '100%', position: 'relative' }}>
        {/*<div style={{ position: 'absolute', top: 13, right: 6, zIndex: 1000 }}>
          <button className="btn btn-success btn-success-thin" onClick={downloadValidated} >Export</button>
        </div>*/}
        <br />
        <AgGridReact style={{ height: 735, width: '100%' }}
          onFirstDataRendered={headerHeightSetter}
          rowData={vehicles}
          frameworkComponents={{
          }}
          onGridReady={onGridReady}
          defaultColDef={{
            flex: 1,
            editable: true,
            cellStyle: cellLeftAlign,
            headerClass: "header-text-center",
            wrapText: true
          }}
          default pagination={true} paginationPageSize={15}
          key="id">
          {/* <AgGridColumn headerName="Agent User ID" field="agentUserId" maxWidth="70" sortable={true}
            cellStyle={cellCenterAlign} filter={true} /> */}
          <AgGridColumn headerName={intl.formatMessage({ id: "certId" })} field="generatedId" minWidth="70" sortable={true}
            cellStyle={cellCenterAlign} filter={true} />

          <AgGridColumn headerName={intl.formatMessage({ id: "fname" })} field="seller.firstName" headerClass="text-left"
            filter={true} sortable={true} hide={agentUser && agentUser.agent && agentUser.agent.partner.partnerCommType === 'Marketing'} />
          <AgGridColumn headerName={intl.formatMessage({ id: "lname" })} field="seller.lastName" headerClass="text-left"
            filter={true} sortable={true} hide={agentUser && agentUser.agent && agentUser.agent.partner.partnerCommType === 'Marketing'} />
          <AgGridColumn headerName={intl.formatMessage({ id: "licplate" })} field="licencePlate" cellStyle={cellLeftAlign}
            headerClass="text-left" filter={true} sortable={true} hide={agentUser && agentUser.agent && agentUser.agent.partner.partnerCommType === 'Marketing'} />

          <AgGridColumn field="creationDate" headerName={intl.formatMessage({ id: "submDate" })} headerClass="text-left" valueFormatter={dateFormatter}
            sortable={true} filter={true}  />
          <AgGridColumn headerName={intl.formatMessage({ id: "certStatus" })} field="status" headerClass="text-left" filter={true} sortable={true}
            valueGetter={(params) => { return statusTranslations[params.data.status]; }} />

          <AgGridColumn field="commissionAmount" cellStyle={cellCenterAlign} valueGetter={(params) =>
            params.data.agentCommission != null ? ('' + params.data.agentCommission).concat(` €`) : ''}
            sortable={true} headerName={intl.formatMessage({ id: "commAmount" })}
            hide={agentUser && agentUser.agent && agentUser.agent.partner && (agentUser.agent.partner.payPartnerNotAgents === true)}
          />
          <AgGridColumn headerName={intl.formatMessage({ id: "commStatus" })} field="agentCommStatus"
            minWidth="150" filter={true} sortable={true} cellStyle={cellCenterAlign} 
            valueGetter={(params) => { return refStatusTranslations[params.data.agentCommStatus]; }}
            hide={agentUser && agentUser.agent && agentUser.agent.partner && (agentUser.agent.partner.payPartnerNotAgents === true)}
          />
          {/*<AgGridColumn headerName="" cellRenderer="editRowButtonRenderer"
            cellRendererParams={{ "parentOnClosePopup": () => onClosePopup }} cellStyle={cellCenterAlign}
          />*/}
        </AgGridReact>
      </div>
    </div >
  );

}
