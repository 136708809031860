import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import '../../styles.scss'
import qs from 'qs';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl'

const Progress = ({ location, user, history }) => {
  const pathname = location.pathname;
  const isFirstStep = pathname === '/first';
  const isSecondStep = pathname === '/second';
  const isThirdStep = pathname === '/third';
  const isLoginPage = pathname === '/home';

  const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true, });

  var ut;
  if (!queryStrings.ut) {
    var isPrivate, isFleet, isChargeSt, isWithVehicles, isCompany;
    try {
      ({ isPrivate, isFleet, isChargeSt, isWithVehicles, isCompany } = user.userType);
    } catch {
      history.push("/");
      window.location.reload();
    }
    ut = isPrivate ? 'po' : isFleet ? 'cf' : 'cs';
  } else {
    ut = queryStrings.ut;
  }

  return (
    <React.Fragment>
      {!isLoginPage ? (
        <div className="steps">
          <div className={`${isFirstStep ? 'step active' : 'step'}`}>
            <div>1</div>
            <div><FormattedMessage id="step" /> 1
              {/*{isSecondStep || isThirdStep ? (
                <Link to={`/first?ut=${ut}`}>Step 1</Link>
              ) : (
                'Step 1'
              )}*/}
            </div>
          </div>
          <div className={`${isSecondStep ? 'step active' : 'step'}`}>
            <div>2</div>
            <div><FormattedMessage id="step" /> 2
              {/*{isThirdStep ? <Link to="/second">Step 2</Link> : 'Step 2'}*/}
            </div>
          </div>
          <div className={`${pathname === '/third' ? 'step active' : 'step'}`}>
            <div>3</div>
            <div><FormattedMessage id="step" /> 3</div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </React.Fragment>
  );
};

export default withRouter(Progress);
