import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import qs from "qs";
import axios from 'axios';
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { BASE_API_URL } from '../../utils/constants';

import {
  logout,
  selectEmailNotAlreadyUsedResponse,
  selectEmailNotAlreadyUsedStatus,
  emailNotAlreadyUsed,
} from "../../slices/auth";

import {
  selectAgentUser,
  fetchAgentUserByPromoCodeAsync
} from '../admin-home/adminHomeSlice';

var agentUserByPromoCodeLoaded = false;

const FirstStep = (props) => {
  const { user } = props;
  const intl = useIntl(); //intl.formatMessage({ id: 'certId' })
  const [country, setCountry] = useState("Deutschland");
  const [loading, setLoading] = useState(false);
  const [vatMandatory, setVatMandatory] = useState(true);
  const agentUser = useSelector(selectAgentUser);
  const [adminSettings, setAdminSettings] = useState(null);

  const emailNotAlreadyUsedResponse = useSelector(
    selectEmailNotAlreadyUsedResponse
  );
  const emailNotAlreadyUsedStatus = useSelector(
    selectEmailNotAlreadyUsedStatus
  );

  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: user.password,
      confirmPassword: user.password,
    },
  });

  const password = useRef({});
  password.current = watch("password", "");

  const dispatch = useDispatch();
  dispatch(logout());

  const queryStrings = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  const sellerType = queryStrings.ut;
  const referrer = queryStrings.referrer;
  const partnerPublicCode = queryStrings.ppc;
  const partialPaymentQs = queryStrings.pp;

  var partialPayment = partialPaymentQs === 't'

  var agentPromoCode = queryStrings.apc;
  if (referrer) {
    agentPromoCode = null;
  }

  var userType = {
    isPrivate: sellerType === "po",
    isFleet: sellerType === "cf",
    isChargeSt: sellerType === "cs",
  };

  userType = {
    ...userType,
    isWithVehicles: userType.isPrivate || userType.isFleet,
    isCompany: userType.isChargeSt || userType.isFleet,
  };

  if (!agentUserByPromoCodeLoaded && agentPromoCode) {
    console.log({ agentPromoCode })
    dispatch(fetchAgentUserByPromoCodeAsync({ promoCode: agentPromoCode }));
    agentUserByPromoCodeLoaded = true;
  }

  async function fetchAdminSettings() {
    const { data } = await axios.get(`${BASE_API_URL}/adminSettings/public`);
    setAdminSettings(data);
  }

  useEffect(() => {
    if (!adminSettings) {
      fetchAdminSettings();
    }
  });

  const { isPrivate, isFleet, isChargeSt, isWithVehicles, isCompany } =
    userType;

  const onSubmit = (data) => {
    if (!user.randomReference) {
      var randomReference = Math.random();
      randomReference = ("" + randomReference).slice(2, 10);
      data.randomReference = randomReference;
      data.userType = userType;
      data.referrer = referrer;
      data.country = country;
    }

    data.agentUser = agentUser;
    data.partialPayment = partialPayment;
    data.promoCode = data.promoCode || agentPromoCode;
    data.adminSettings = adminSettings;

    props.updateUser(data);
    console.log({ data });

    dispatch(emailNotAlreadyUsed(data));
  };

  useEffect(() => {
    if (emailNotAlreadyUsedStatus === "loading") {
      setLoading(true);
    }
    if (loading && emailNotAlreadyUsedStatus === "idle") {
      setLoading(false);
      if (emailNotAlreadyUsedResponse.outcome) {
        submitToNextPage();
      } else {
        errors.email = { message: intl.formatMessage({ id: "emailAInUse" }) };
      }
    }
  });

  const noVatChanged = (input) => {
    setVatMandatory(!input.target.checked);
  }

  useEffect(() => {
    props.setAgentUser(agentUser);
    console.log({ agentUser });
  }, [agentUser]);

  function submitToNextPage() {
    props.history.push("/second?ut=" + sellerType);
  }

  var countries = [{ isoCode: "Deutschland", name: "Deutschland" }];
  const wlPartnerId = 23;
  const howMuchBasicPayment = (agentUser && agentUser.agent && agentUser.agent.partner && agentUser.agent.partner.id !== wlPartnerId ? agentUser.agent.partner.pvtCarPaymentAmount : 255);

  if (agentUser && agentUser.agent && agentUser.agent.partner && agentUser.agent.partner.id === wlPartnerId) {
    partialPayment = true;
  }

  return (
    <Form className="input-form" onSubmit={handleSubmit(onSubmit)}>
      <motion.div
        className="col-md-10 offset-md-1"
        initial={{ x: "-100vw" }}
        animate={{ x: 0 }}
        transition={{ stiffness: 150 }}>
        {agentUser && agentUser.agent && agentUser.agent.partner &&
          <div style={window.innerWidth >= 600 ? { position: 'relative', height: '320px' } : {}}>
            <br />
            <div style={window.innerWidth >= 600 ? { position: 'absolute', top: 0, left: -80, right: -80, zIndex: 1000 } : {}}>
              <FormattedMessage id={agentUser.agent.partner.id !== wlPartnerId ? "regWithPartnerInstr1" : "regWithPartnerInstr1Wl"} />
              <br />

              <FormattedMessage id={agentUser.agent.partner.id !== wlPartnerId ? "regWithPartnerInstr2" : "regWithPartnerInstr2Wl"} values={{
                partnerName: (agentUser && agentUser.agent && agentUser.agent.partner) ? agentUser.agent.partner.companyName : '',
                howMuch: (agentUser && agentUser.agent && agentUser.agent.partner) ? agentUser.agent.partner.additionalPaymentAmount : '5',
                howMuchBasicPayment: (agentUser && agentUser.agent && agentUser.agent.partner) ? agentUser.agent.partner.pvtCarPaymentAmount : ''
              }} />

              {(agentUser && agentUser.agent && agentUser.agent.partner && agentUser.agent.partner.additionalPaymentAmount > 0) &&
                <>
                  <FormattedMessage id="regWithPartnerInstr3" values={{
                    partnerName: (agentUser && agentUser.agent && agentUser.agent.partner) ? agentUser.agent.partner.companyName : '',
                    howMuch: (agentUser && agentUser.agent && agentUser.agent.partner) ? agentUser.agent.partner.additionalPaymentAmount : '5',
                    howMuchBasicPayment
                  }} />
                  <br />
                  <br />
                </>
              }
              {agentUser.agent.partner.id === wlPartnerId &&
                <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "regWithPartnerInstr3Wl" }) }} />}

              <FormattedMessage id={agentUser.agent.partner.id !== wlPartnerId ? "regWithPartnerInstr3p1" : "regWithPartnerInstr3Wl1"} />
              <br /><br />
              <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: agentUser.agent.partner.id !== wlPartnerId ? 'regWithPartnerInstr4' : 'regWithPartnerInstr4Wl' }) }} />
              <br />
            </div>
          </div>}

        <Form.Group controlId="firstName">
          <Form.Label>
            <FormattedMessage id="fname" />
          </Form.Label>
          <Form.Control
            type="text"
            name="firstName"
            //placeholder={intl.formatMessage({ id: 'mandatoryField' })}
            autoComplete="off"
            ref={register({
              required: intl.formatMessage({ id: 'mandatoryField' }),
            })}
            className={`${errors.firstName ? "input-error" : ""}`}
          />
          {errors.firstName && (
            <p className="errorMsg">{errors.firstName.message}</p>
          )}
        </Form.Group>

        <Form.Group controlId="lastName">
          <Form.Label>
            <FormattedMessage id="lname" />
          </Form.Label>
          <Form.Control
            type="text"
            name="lastName"
            //placeholder={intl.formatMessage({ id: 'enterYourLastName' })}
            autoComplete="off"
            ref={register({
              required: intl.formatMessage({ id: 'mandatoryField' }),
            })}
            className={`${errors.lastName ? "input-error" : ""}`}
          />
          {errors.lastName && (
            <p className="errorMsg">{errors.lastName.message}</p>
          )}
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            //placeholder={intl.formatMessage({ id: 'emailFormatNotValid' })}
            autoComplete="off"
            ref={register({
              required: intl.formatMessage({ id: 'mandatoryField' }),
              pattern: {
                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                message: intl.formatMessage({ id: 'emailFormatNotValid' }),
              },
            })}
            className={`${errors.email ? "input-error" : ""}`}
          />
          {errors.email && <p className="errorMsg">{errors.email.message}</p>}
        </Form.Group>

        {(!adminSettings || adminSettings.passwordMandatory) &&
          <>
            <Form.Group controlId="password">
              <Form.Label>
                <FormattedMessage id="password" />
              </Form.Label>
              <Form.Control
                type="password"
                name="password"
                //placeholder={intl.formatMessage({ id: 'choosePassword' })}
                autoComplete="off"
                ref={register({
                  required: intl.formatMessage({ id: 'mandatoryField' }),
                  minLength: {
                    value: 6,
                    message: intl.formatMessage({ id: 'pwdMinChars' }, { numChars: 6 }),
                  },
                })}
                className={`${errors.password ? "input-error" : ""}`}
              />
              {errors.password && (
                <p className="errorMsg">{errors.password.message}</p>
              )}
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>
                <FormattedMessage id="confirmPassword" />
              </Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                //placeholder={intl.formatMessage({ id: 'pwdsDoNotMatch' })}
                autoComplete="off"
                ref={register({
                  required: intl.formatMessage({ id: 'mandatoryField' }),
                  validate: (value) =>
                    value === password.current || intl.formatMessage({ id: 'pwdsDoNotMatch' }),

                })}
                className={`${errors.confirmPassword ? "input-error" : ""}`}
              />
              {errors.confirmPassword && (
                <p className="errorMsg">{errors.confirmPassword.message}</p>
              )}
            </Form.Group>
          </>
        }
        {isCompany && (
          <Form.Group controlId="companyName">
            <Form.Label>
              <FormattedMessage id="companyName" />
            </Form.Label>
            <Form.Control
              type="text"
              name="companyName"
              //placeholder="Enter the company name"
              autoComplete="off"
              ref={register({
                required: intl.formatMessage({ id: 'mandatoryField' }),
              })}
              className={`${errors.companyName ? "input-error" : ""}`}
            />
            {errors.companyName && (
              <p className="errorMsg">{errors.companyName.message}</p>
            )}
          </Form.Group>
        )}
        {isCompany && (
          <Form.Group controlId="companyIdentNum">
            <Form.Label>
              <FormattedMessage id="companyId" />
            </Form.Label>
            <Form.Control
              type="text"
              name="companyIdentNum"
              //placeholder="Enter the company ID"
              autoComplete="off"
              ref={register({})}
              className={`${errors.companyIdentNum ? "input-error" : ""}`}
            />
            {errors.companyIdentNum && (
              <p className="errorMsg">{errors.companyIdentNum.message}</p>
            )}
          </Form.Group>
        )}
        {isCompany && (
          <Form.Group controlId="companyVatNumber">
            <Form.Label>
              <FormattedMessage id="vatNum" />
            </Form.Label>
            <Form.Control
              type="text"
              name="companyVatNumber"
              //placeholder="Enter the company VAT Number"
              //required={vatMandatory}
              disabled={!vatMandatory}
              autoComplete="off"
              ref={register({
                required: vatMandatory ? intl.formatMessage({ id: 'mandatoryField' }) : false,
              })}
              className={`${errors.companyVatNumber ? "input-error" : ""}`}
            />
            {errors.companyVatNumber && (
              <p className="errorMsg">{errors.companyVatNumber.message}</p>
            )}

            <Form.Check
              type="checkbox"
              name="novat">
              <Form.Check.Input onChange={noVatChanged} />
              <Form.Check.Label>
                <FormattedMessage id="notEligVat" />
              </Form.Check.Label>
            </Form.Check>

          </Form.Group>
        )}
        {isCompany && (
          <Form.Group controlId="companyAddress">
            <Form.Label>
              <FormattedMessage id="address" />
            </Form.Label>
            <Form.Control
              type="text"
              name="companyAddress"
              //placeholder="Enter the company Address"
              autoComplete="off"
              ref={register({
                required: intl.formatMessage({ id: 'mandatoryField' }),
              })}
              className={`${errors.companyAddress ? "input-error" : ""}`}
            />
            {errors.companyAddress && (
              <p className="errorMsg">{errors.companyAddress.message}</p>
            )}
          </Form.Group>
        )}
        <div className="flexbox-container-horiz-no-border">
          {isCompany && (
            <Form.Group
              controlId="companyCity"
              style={{ paddingRight: "15px" }}>
              <Form.Label>
                <FormattedMessage id="city" />
              </Form.Label>
              <Form.Control
                type="text"
                name="companyCity"
                //placeholder="Enter the company City"
                autoComplete="off"
                ref={register({
                  required: intl.formatMessage({ id: 'mandatoryField' }),
                })}
                className={`${errors.companyCity ? "input-error" : ""}`}
              />
              {errors.companyCity && (
                <p className="errorMsg">{errors.companyCity.message}</p>
              )}
            </Form.Group>
          )}
          {isCompany && (
            <Form.Group controlId="companyZip">
              <Form.Label>
                <FormattedMessage id="postcode" />
              </Form.Label>
              <Form.Control
                type="text"
                name="companyZip"
                //placeholder="Enter the company Postcode"
                autoComplete="off"
                ref={register({
                  pattern: {
                    value: /^[0-9]{5,5}$/,
                    message: 'Company Postcode is 5 digits',
                  },
                  required: intl.formatMessage({ id: 'mandatoryField' }),
                })}
                className={`${errors.companyZip ? "input-error" : ""}`}
              />
              {errors.companyZip && (
                <p className="errorMsg">{errors.companyZip.message}</p>
              )}
            </Form.Group>
          )}
        </div>
        <Form.Group controlId="countryGroup">
          <Form.Label>
            <FormattedMessage id="country" />
          </Form.Label>
          <Form.Control
            className="form-control"
            as="select"
            name="country"
            value={country}
            ref={register({})}
            onChange={(event) => setCountry(event.target.value)}>
            {countries.map(({ isoCode, name }) => (
              <option value={isoCode} key={isoCode}>
                {name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="agree01">
          <Form.Check
            type="checkbox"
            name="agree01"
            feedback="You must agree before submitting."
            feedbackType="invalid">
            <Form.Check.Input required />
            <Form.Check.Label>
              <FormattedMessage id="agreeTo" />
              <a
                href={intl.formatMessage({ id: "linkprivacy" })}
                target="_blank"
                rel="noreferrer">
                <FormattedMessage id="dataPrivacy" />
              </a>
              <FormattedMessage id="agreeTo2" />

              <a
                href={intl.formatMessage({ id: "linktc" })}
                target="_blank"
                rel="noreferrer">
                <FormattedMessage id="tnc" />
              </a>
              <FormattedMessage id="agreeTo3" />
            </Form.Check.Label>
            <Form.Control.Feedback type="invalid" tooltip={false}>
              You must agree before submitting.
            </Form.Control.Feedback>
          </Form.Check>
        </Form.Group>
        {!referrer
          && (
            <Form.Group controlId="promoCodeGroup">
              <Form.Label>
                <FormattedMessage id="promoCode" />
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={agentPromoCode}

                disabled={agentPromoCode || partialPayment}

                name="promoCode"
                autoComplete="off"
                ref={register({})}
                className={`${errors.promoCode ? "input-error" : ""}`}
              />
              {errors.promoCode && (
                <p className="errorMsg">{errors.promoCode.message}</p>
              )}
            </Form.Group>
          )}
        <Button xs={{ borderRadius: "20px" }} variant="primary" type="submit">
          <FormattedMessage id="next" />
        </Button>
      </motion.div>
    </Form>
  );
};

export default FirstStep;
