import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import homeReducer from './features/home/homeSlice';
import adminHomeReducer from './features/admin-home/adminHomeSlice';
import agentsHomeReducer from './features/agents/agentHomeSlice';

const reducer = {
  auth: authReducer,
  message: messageReducer,
  home: homeReducer,
  adminHome: adminHomeReducer,
  agentsHome: agentsHomeReducer
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;
