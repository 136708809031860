import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, selectIsLoggedIn } from '../../slices/auth';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { FormattedMessage } from 'react-intl'
import { IntlProvider, useIntl } from 'react-intl';
import serverSideEnums from '../../common/serverSideEnums';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import {
  fetchReferralsAsync,
  saveReferralAsync,
  selectReferrals,
  selectSaveReferralResult,
  selectSaveReferralStatus
} from './homeSlice';

let needsReloading = true;
var saveButtonClicked = false;

export default function Referrals(props) {
  const vehicles = useSelector(selectReferrals);
  const saveReferralStatus = useSelector(selectSaveReferralStatus);
  const saveReferralResult = useSelector(selectSaveReferralResult);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const intl = useIntl();
  const refStatusTranslations = serverSideEnums(intl).referralStatus;

  if (!user) {
    props.history.push('/login');
  }


  useEffect(() => {
    if (needsReloading) {
      dispatch(fetchReferralsAsync());
      needsReloading = false;
    }
  });

  const cellLeftAlign = { 'text-align': "left", padding: '2px' };
  const cellRightAlign = { 'text-align': "right", padding: '2px' };
  const cellCenterAlign = { 'text-align': "center", padding: '2px' };

  function headerHeightSetter() {
    var padding = 2;
    var height = 50 + padding;
    this.api.setHeaderHeight(height);
    this.api.resetRowHeights();
  }

  function dateFormatter(params) {
    var newDate = new Date();
    newDate.setTime(Date.parse(params.value));
    return newDate.toLocaleDateString();
  }

  const { register, errors, handleSubmit, reset } = useForm({
    defaultValues: {}
  });

  const onSubmit = async (data, event) => {
    event.preventDefault();
    dispatch(saveReferralAsync({ data }));
  };

  useEffect(() => {
    console.log(saveReferralStatus);
    console.log({ saveButtonClicked });
    if (saveReferralStatus === 'idle' && saveButtonClicked) {
      saveButtonClicked = false;
      console.log({ saveReferralResult });
      if (saveReferralResult && saveReferralResult.error) {
        Swal.fire('Ahhh!', "The email was already referred. Please try another one.", 'info');
      } else {
        dispatch(fetchReferralsAsync());
        reset({})
      }
      return;
    }

    if (saveReferralStatus === 'loading') {
      saveButtonClicked = true;
    }
  });

  return (
    <div>
      <header className="">
        <h3><FormattedMessage id="referRFriend" /></h3>
        <FormattedMessage id="referralsSubTitle" />
      </header>
      <Form className="input-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-md-6 offset-md-0" >
          <Form.Group controlId="emailGroup">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              name="email"
              //placeholder="Enter Email"
              autoComplete="off"
              ref={register({
                required: 'Email is required.',
                pattern: {
                  value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                  message: 'Email is not valid.'
                }
              })}
              className={`${errors.email ? 'input-error' : ''}`}
              style={{ textAlign: 'left' }}
            />
            {errors.email && (
              <p className="errorMsg">{errors.email.message}</p>
            )}
          </Form.Group>
          <Button variant="primary" type="submit"><FormattedMessage id="addReferral" /></Button>
        </div>
      </Form >

      <div className="ag-theme-alpine" style={{ height: 350, width: '100%' }}>
        <AgGridReact style={{ height: 350, width: '100%' }}
          onFirstDataRendered={headerHeightSetter}
          rowData={vehicles}
          defaultColDef={{
            flex: 1,
            editable: true,
            cellStyle: cellLeftAlign,
            headerClass: "header-text-center",
            wrapText: true
          }}
          default key="id" rowSelection='multiple'
        >
          <AgGridColumn headerName="N."
            valueGetter={(params) => params.node.rowIndex + 1}
            maxWidth="60" cellStyle={cellCenterAlign} filter={true} />
          <AgGridColumn headerName="Email" headerClass="" field="email" filter={true} />
          <AgGridColumn field="date" headerName="Date" cellStyle={cellCenterAlign} valueFormatter={dateFormatter}
            sortable={true} maxWidth="200" />
          <AgGridColumn field="paymentAmount" cellStyle={cellCenterAlign} headerName={intl.formatMessage({ id: 'paymentAmount' })}
            valueGetter={(params) => { console.log({ params }); return params.data.paymentAmount ? ('' + params.data.paymentAmount).concat(` €`) : ''; }}
            maxWidth="200" />
          <AgGridColumn cellStyle={cellCenterAlign} headerName="Status" field="status" maxWidth="200" filter={true}
            valueGetter={(params) => { return refStatusTranslations[params.data.status]; }} />
        </AgGridReact>
      </div >

    </div>
  );

}
