import React, { useEffect, useState } from 'react';
import UploadFrontAndBack from '../../components/file-upload-2/UploadFrontAndBack';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { selectUser, selectSeller } from '../../slices/auth';
import sellerType from '../sellerType';
import { FormattedMessage } from 'react-intl'
import { IntlProvider, useIntl } from 'react-intl';

import {
  duplicateVehicleAsync,
  selectSaveVehicleResult,
  selectSaveVehicleStatus
} from './homeSlice';
import { DateRangeTwoTone } from '@material-ui/icons';

var saveButtonClicked = false;

const DuplicateVehicleByOwner = (props) => {
  const { co2saverId, sellerId, closePopup, data } = props;
  const dispatch = useDispatch();
  const saveVehicleStatus = useSelector(selectSaveVehicleStatus);
  const seller = useSelector(selectSeller);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const intl = useIntl();

  const doSubmit = () => {
    setSaveButtonDisabled(true);
    dispatch(duplicateVehicleAsync({ co2saverId }));
  };

  useEffect(() => {
    if (saveVehicleStatus === 'idle' && saveButtonClicked) {
      saveButtonClicked = false;
      closePopup();
      return;
    }

    if (saveVehicleStatus === 'loading') {
      saveButtonClicked = true;
    }
  });

  return (
    <div>
      <h2><FormattedMessage id="duplicateVehicle" values={{ year: new Date().getFullYear() }} /></h2>
      <Form.Group controlId="lpGroup">
        <Form.Label><FormattedMessage id="licplate" /></Form.Label>
        <Form.Control
          defaultValue={data.licencePlate}
          disabled={true}
          type="text"
          name="licencePlate"
          autoComplete="off"
        />
      </Form.Group>
      <FormattedMessage id="duplConfirmText" />
      <br />
      <div style={{ textAlign: 'center' }}>
        <Button variant="primary" type="button" onClick={doSubmit} disabled={saveButtonDisabled}>
          <FormattedMessage id="confirm" />
        </Button>
      </div>
    </div >
  );
};

export default DuplicateVehicleByOwner;
