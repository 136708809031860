import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  fetchVehiclesAsync,
  selectVehicles,
  saveAdminCommissionsAsync,
  selectSaveReferralResult,
  selectSaveReferralStatus
} from './adminHomeSlice';
import { selectUser, selectIsLoggedIn } from '../../slices/auth';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import serverSideEnums from '../../common/serverSideEnums';
import { FormattedMessage } from 'react-intl'
import { IntlProvider, useIntl } from 'react-intl';
import Swal from 'sweetalert2';
import { Form, Button, InputGroup } from 'react-bootstrap';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import EditRowButtonRenderer from './editButtonRenderer';
import DocsIconsListRenderer from './iconsListRenderer';

let needsReloading = true;
var saveButtonClicked = false;

export default function AdminQuotasPartners(props) {
  const vehicles = useSelector(selectVehicles);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const intl = useIntl();
  const statusTranslations = serverSideEnums(intl).status;
  const statusRefTranslations = serverSideEnums(intl).referralStatus;

  const saveReferralStatus = useSelector(selectSaveReferralStatus);
  const saveReferralResult = useSelector(selectSaveReferralResult);
  const [rowIdsSelected, setRowIdsSelected] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('ToBePaid');
  const [gridFilter, setGridFilter] = useState(null);

  useEffect(() => {
    if (needsReloading) {
      dispatch(fetchVehiclesAsync({ onlybyPartners: true, validatedOnly:true }));
      needsReloading = false;
    }
  });
  
  if (!user) {
    props.history.push('/login');
  }

  const cellLeftAlign = { 'text-align': "left", padding: '2px' };
  const cellRightAlign = { 'text-align': "right", padding: '2px' };
  const cellCenterAlign = { 'text-align': "center", padding: '2px' };

  function headerHeightSetter() {
    var padding = 2;
    var height = 50 + padding;
    this.api.setHeaderHeight(height);
    this.api.resetRowHeights();
  }

  function dateFormatter(params) {
    var newDate = new Date();
    newDate.setTime(Date.parse(params.value));
    return newDate.toLocaleDateString();
  }

  const { register, errors, handleSubmit, reset } = useForm({
    defaultValues: {}
  });

  function downloadValidated() {
    var link = document.createElement("a");
    link.href = "https://eco2wo.com/api/co2SaversPaymentInfo";
    link.target = "_blank"

    document.body.appendChild(link);

    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
  }

  const getSelectedRowData = (gridApi) => {
    let selectedNodes = gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data);
    console.log(selectedData);
    return selectedData;
  };

  const onGridSelectionChanged = ({ api, columnApi, type }) => {
    const selectedRowsDataArray = getSelectedRowData(api);
    const rowIds = [];
    selectedRowsDataArray.forEach(rowData => {
      rowIds.push(rowData.id);
    });
    console.log(rowIds);
    setRowIdsSelected(rowIds);
  }

  const onSubmit = async (data, event) => {
    event.preventDefault();
    dispatch(saveAdminCommissionsAsync({ data: rowIdsSelected, newStatus: selectedStatus }));
  };

  useEffect(() => {
    console.log(saveReferralStatus);
    console.log({ saveButtonClicked });
    if (saveReferralStatus === 'idle' && saveButtonClicked) {
      saveButtonClicked = false;
      console.log({ saveReferralResult });
      if (saveReferralResult && saveReferralResult.error) {
        Swal.fire('Ahhh!', "There was an error. Please try again later.", 'info');
      } else {
        dispatch(fetchVehiclesAsync({ onlybyPartners: true, validatedOnly:true }));
        reset({})
      }
      return;
    }

    if (saveReferralStatus === 'loading') {
      saveButtonClicked = true;
    }
  });

  var statuses = [
    { isoCode: 'ToBePaid', name: 'To be Paid' },
    { isoCode: 'Paid', name: 'Paid' },
  ];

  var gridApi;
  var gridColumnApi;

  const onGridReady = params => {
    gridApi = params.api;
    gridColumnApi = params.columnApi;
    params.columnApi.getColumn('generatedId').setSort("asc")
  };

  function downloadValidated() {
    var link = document.createElement("a");
    link.href = "https://eco2wo.com/api/commissionsPaymentInfo";
    link.target = "_blank"

    document.body.appendChild(link);

    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
  }

  function onFilterChanged(params) {
    let filterModel = params.api.getFilterModel();
    setGridFilter(filterModel);
    console.log({filterModel})
  }

  function gridSetFilters(params) {
    let filterModel = gridFilter;
    console.log(this)

    if (filterModel) {
      params.api.setFilterModel(filterModel);
    }
  }

  return (
    <div className="" style={{ height: 1100, width: '100%', position: 'relative' }}>
      <header className="">
        <h3>Commissions</h3>
        You can use this page to review and update the Commissions information.
      </header>
      <Form className="input-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-md-6 offset-md-0" >
          Please select the commissons in the grid for which you want to change the status.
          Then click "Update Status".
          <Form.Group controlId="country">
            <Form.Label>New Status</Form.Label>
            <Form.Control
              as="select"
              name="status"
              onChange={(event) => setSelectedStatus(event.target.value)}
            >
              {statuses.map(({ isoCode, name }) => (
                <option value={isoCode} key={isoCode}>
                  {name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Button variant="primary" type="submit">Update Status</Button>
        </div>
      </Form >
      <div className="ag-theme-alpine" style={{ height: 735, width: '100%', position: 'relative' }}>
        <div style={{ position: 'absolute', top: 24, right: 6, zIndex: 1000 }}>
          <button className="btn btn-success btn-success-thin" onClick={downloadValidated} >Export</button>
        </div>
        <br />

        <AgGridReact style={{ height: 735, width: '100%' }}
          onFirstDataRendered={headerHeightSetter}
          onRowDataChanged={gridSetFilters}
          onRowDataUpdated={gridSetFilters}
          rowData={vehicles}
          frameworkComponents={{
            editRowButtonRenderer: EditRowButtonRenderer,
            docsIconsListRenderer: DocsIconsListRenderer
          }}
          onGridReady={onGridReady}
          defaultColDef={{
            flex: 1,
            editable: true,
            cellStyle: cellLeftAlign,
            headerClass: "header-text-center",
            wrapText: true
          }}
          default pagination={true} paginationPageSize={15}
          key="id"
          onSelectionChanged={onGridSelectionChanged}
          rowSelection='multiple'
          onFilterChanged={onFilterChanged.bind(this)} >
          <AgGridColumn field="partnerCompanyName" maxWidth="120" cellStyle={cellLeftAlign} headerName="Partner"
            headerClass="text-left" filter={true} sortable={true} />
          <AgGridColumn field="agentOfficeId" maxWidth="150" cellStyle={cellLeftAlign} headerName="Agency"
            headerClass="text-left" filter={true} sortable={true} />
          <AgGridColumn field="agentId" maxWidth="150" cellStyle={cellLeftAlign} headerName="Agent ID"
            headerClass="text-left" filter={true} sortable={true} />
          <AgGridColumn headerName="Certificate ID" headerClass="text-left" field="generatedId" minWidth="70" maxWidth="150" sortable={true}
            cellStyle={cellLeftAlign} filter={true} />
          <AgGridColumn headerName="First name" headerClass="text-left" field="seller.firstName" filter={true} sortable={true} />
          <AgGridColumn headerName="Last name" headerClass="text-left" field="seller.lastName" filter={true} sortable={true} />
          <AgGridColumn field="licencePlate" maxWidth="140" headerClass="text-left" cellStyle={cellLeftAlign}
            headerClass="text-left" filter={true} sortable={true} />
          <AgGridColumn field="creationDate" headerName="Submission date" headerClass="text-left" valueFormatter={dateFormatter}
            sortable={true} filter={true} />
            <AgGridColumn cellStyle={cellCenterAlign} headerName="Commission" maxWidth="120" valueGetter={(params) =>
              params.data.agentCommission ? ('' + params.data.agentCommission).concat(` €`) : ''} />
          <AgGridColumn headerName="Comm. Status" field="agentCommStatus" headerClass="text-left" maxWidth="150" filter={true} sortable={true}
            valueGetter={(params) => { return statusRefTranslations[params.data.agentCommStatus]; }} />

          <AgGridColumn headerName="" checkboxSelection={true} headerCheckboxSelection={false}
            minWidth="60" maxWidth="60" cellStyle={cellRightAlign} />
        </AgGridReact>
      </div>
    </div >
  );

}
