import axios from "axios";

export const newAxiosWithJwt = (user) => {
  const jwt = user.accessToken;
  return axios.create({
    baseURL: "https://eco2wo.com/api",
    headers: {
      "Content-type": "application/json",
      "Authorization": "Bearer " + jwt
    }
  });
}

export default axios.create({
  baseURL: "https://eco2wo.com/api",
  headers: {
    "Content-type": "application/json"
  }
});