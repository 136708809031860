import React from "react";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

const Footer = (props) => {
  const intl = useIntl(); //intl.formatMessage({ id: 'certId' })
  return (
    <footer className="footer">
      <img src={props.logoImage.src} alt="logo" height="35" />
      <p className="footer-text">
        <FormattedMessage id="footerText" />
      </p>
      <b>
        <ul className="footer-links">
          <li>
            <a href={intl.formatMessage({ id: "linkprivacy" })} className="nav-link">
              <FormattedMessage id="dataPrivacy" />
            </a>
          </li>
          <li>
            <a href={intl.formatMessage({ id: "footerLinkImprint" })} className="nav-link">
              <FormattedMessage id="imprint" />
            </a>
          </li>
          <li>
            <a href={intl.formatMessage({ id: "linktc" })} className="nav-link">
              <FormattedMessage id="tnc" />
            </a>
          </li>
          <li>
            <a href={intl.formatMessage({ id: "footerLinkRightToWithdraw" })} className="nav-link">
              <FormattedMessage id="rtWith" />
            </a>
          </li>
        </ul>
      </b>
      <div className="copyright">
        <FormattedMessage id="footerCopyright" />
      </div>
    </footer>
  );
};

export default Footer;
