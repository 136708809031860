import React, {
  Component
} from "react";

import fileService from '../../services/upload-files.service';
import UploadFiles from "./file-upload.component";
import { FormattedMessage } from 'react-intl'

//intl.formatMessage({ id: 'certId' })
import { injectIntl } from 'react-intl'
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";

class UploadFrontAndBack extends Component {
  constructor(props) {
    super(props);
    this.filenamePrefix = props.filenamePrefix;
    this.intl = props.intl;
    this.isEdit = props.isEdit;
    this.allAttachmentsOptional = props.allAttachmentsOptional;
    this.horizStyle = !props.vertStyle;
    this.uploadThirdDoc = props.uploadThirdDoc;
    this.hidePreviewPane = props.hidePreviewPane;
    this.state = {
      message: "",
      filenamePrefix: props.filenamePrefix,
      fileMissingErrors: this.isEdit ? null : '* ' + props.intl.formatMessage({ id: 'bothFilesMand' }),
      files: null
    };
    this.setAttachsOk = props.setAttachsOk;
    this.updateFileMandatoryControl = this.updateFileMandatoryControl.bind(this);
    this.areAllUploadEvidencesSet = this.areAllUploadEvidencesSet.bind(this);
    if (!this.isEdit) {
      this.setAttachsOk(false);
    }
  }

  updateFileMandatoryControl = (fullPrefix, i) => {
    if (this.allAttachmentsOptional) {
      return true;
    }

    const temp = { ...this.state.files };
    if (!temp) {
      temp = {};
    }
    temp[fullPrefix] = 'ok';
    this.setState({ ...this.state, files: temp });
    console.log({ temp });
    this.areAllUploadEvidencesSet();
  }

  areAllUploadEvidencesSet() {

    var temp = { ...this.state.fileMissingErrors };
    var outcome = true;
    if (!this.state.files) {
      temp = '* ' + this.intl.formatMessage({ id: 'bothFilesMand' });
      outcome = false;
    } else if (!this.state.files['front'] ) { //|| !this.state.files['back']
      temp = '* ' + this.intl.formatMessage({ id: 'bothFilesMand' });
      outcome = false;
    } else {
      temp = null;
    }

    this.setState({ ...this.state, fileMissingErrors: temp });

    this.setAttachsOk(outcome);
    console.log({ temp });
    console.log({ outcome });

    return outcome;
  }

  render() {
    const {
      message
    } = this.state;

    return (
      <div>
        <div className={this.horizStyle ? "flexbox-container-horiz" : "flexbox-container-vert"} style={{ border: 0 }}>
          <div className="flexbox-container-vert" style={this.horizStyle ? { width: this.uploadThirdDoc ? '50%' : '100%' } : {}} >
            <UploadFiles updateFilesCb={fileService.uploadMultiple}
              title={this.intl.formatMessage({ id: (this.horizStyle ? 'frontscan' : 'shortFrontscan') })} callback={this.updateFileMandatoryControl}
              filenamePrefix={`${this.filenamePrefix}-front`} label="" index="0000000" frontBack="front" hidePreviewPane={this.hidePreviewPane} />
          </div>
          {/* {!this.horizStyle && <div style={{ paddingTop: 20 }} />}
          <div className="flexbox-container-vert" style={this.horizStyle ? { width: '50%' } : {}} >
            <UploadFiles updateFilesCb={fileService.uploadMultiple}
              title={this.intl.formatMessage({ id: (this.horizStyle ? 'backscan' : 'shortBackscan') })} callback={this.updateFileMandatoryControl}
              filenamePrefix={`${this.filenamePrefix}-back`} label="" index="0000000" frontBack="back" hidePreviewPane={this.hidePreviewPane} />
          </div> */}
          {this.uploadThirdDoc &&
            <>
              {!this.horizStyle && <div style={{ paddingTop: 20 }} />}

              <div className="flexbox-container-vert" style={this.horizStyle ? { width: '50%' } : {}} >
                <UploadFiles updateFilesCb={fileService.uploadMultiple}
                  title={this.intl.formatMessage({ id: (this.horizStyle ? 'shortThirdDoc' : 'shortThirdDoc') })} callback={this.updateFileMandatoryControl}
                  filenamePrefix={`${this.filenamePrefix}-notSpecified`} label="" index="0000000" frontBack="notSpecified" hidePreviewPane={this.hidePreviewPane} />
              </div>
            </>
          }

        </div>
        <p className='errorMsg'>{this.state.fileMissingErrors}</p>

      </div >
    );
  }
}

export default injectIntl(UploadFrontAndBack);
