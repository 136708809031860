export default (sellerType) => {
  var temp = {
    isPrivate: sellerType === 'private',
    isFleet: sellerType === 'commercial',
    isChargeSt: sellerType === 'charging_st',
  };

  return {
    ...temp,
    isWithVehicles: temp.isPrivate || temp.isFleet,
    isCompany: temp.isChargeSt || temp.isFleet,
  }
}