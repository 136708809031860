import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchVehicles, saveVehicle, saveSellerAccount, fetchChargingPoints,
  saveChPoint, fetchReferrals, saveReferral, duplicateVehicle
} from './homeAPI';

import http, { newAxiosWithJwt } from "../../http-common";

const initialState = {
  vehicles: [],
  referrals: [],
  saveVehicleResult: null,
  saveVehicleStatus: null,
  saveSellerAccountResult: null,
  saveSellerAccountStatus: null,
  saveReferralResult: null,
  saveReferralStatus: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchVehiclesAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchVehiclesAsync = createAsyncThunk(
  'home/fetchVehicles',
  async (dummy, { getState }) => {
    const state = getState();
    const isChStationsOwner = state.auth.user.user.sellerType === 'charging_st';
    var response;
    if (isChStationsOwner) {
      response = await fetchChargingPoints(state.auth.user);
    } else {
      response = await fetchVehicles(state.auth.user);
    }

    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fetchReferralsAsync = createAsyncThunk(
  'home/fetchReferral',
  async (dummy, { getState }) => {
    const state = getState();
    const response = await fetchReferrals(state.auth.user);
    return response.data;
  }
);

export const saveVehicleDataAsync = createAsyncThunk(
  'home/saveVehicle',
  async ({ co2saverId, data }, { getState }) => {
    const state = getState();
    const isChStationsOwner = state.auth.user.user.sellerType === 'charging_st';
    var response;
    if (isChStationsOwner) {
      response = await saveChPoint(co2saverId, data, state.auth.user);
    } else {
      response = await saveVehicle(co2saverId, data, state.auth.user);
    }
    return response.data;
  }
);

export const duplicateVehicleAsync = createAsyncThunk(
  'home/duplicateVehicle',
  async ({ co2saverId }, { getState }) => {
    const state = getState();
    var response;
    response = await duplicateVehicle(co2saverId, state.auth.user);
    return response.data;
  }
);

export const saveReferralAsync = createAsyncThunk(
  'home/saveReferral',
  async ({ data }, { getState }) => {
    const state = getState();
    const response = await saveReferral(data, state.auth.user);
    return response.data;
  }
);

export const saveSellerAccountAsync = createAsyncThunk(
  'home/saveSellerAccount',
  async ({ data }, { getState }) => {
    const state = getState();
    const response = await saveSellerAccount(data, state.auth.user);
    return response.data;
  }
);

export const savePasswordAsync = createAsyncThunk(
  'home/savePassword',
  async ({ pwdResetCode, password, newPass, useCode }, { getState }) => {
    const state = getState();
    var response;
    if (useCode) {
      response = await http.post("/authenticate/setNewPasswordAfterReset", {
        pwdResetCode: useCode,
        password
      });
    } else {
      response = await newAxiosWithJwt(state.auth.user).post("/sellers/setNewPassword", { newPass });
    }
    return response.data;
  }
);

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    increment: (state) => {
      state.value += 1;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchVehiclesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVehiclesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log(action.payload.numberOfElements);
        state.vehicles = action.payload.content;
      });
    builder
      .addCase(saveVehicleDataAsync.pending, (state) => {
        state.saveVehicleStatus = 'loading';
      })
      .addCase(saveVehicleDataAsync.fulfilled, (state, action) => {
        state.saveVehicleStatus = 'idle';
        console.log(action.payload);
        state.saveVehicleResult = action.payload;
      });
    builder
      .addCase(duplicateVehicleAsync.pending, (state) => {
        state.saveVehicleStatus = 'loading';
      })
      .addCase(duplicateVehicleAsync.fulfilled, (state, action) => {
        state.saveVehicleStatus = 'idle';
        console.log(action.payload);
        state.saveVehicleResult = action.payload;
      });
    builder
      .addCase(saveSellerAccountAsync.pending, (state) => {
        state.saveSellerAccountStatus = 'loading';
      })
      .addCase(saveSellerAccountAsync.fulfilled, (state, action) => {
        state.saveSellerAccountStatus = 'idle';
        state.saveSellerAccountResult = action.payload;
      });
    builder
      .addCase(fetchReferralsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchReferralsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.referrals = action.payload;
      });
    builder
      .addCase(saveReferralAsync.pending, (state) => {
        state.saveReferralStatus = 'loading';
      })
      .addCase(saveReferralAsync.rejected, (state) => {
        state.saveReferralStatus = 'idle';
        state.saveReferralResult = { error: "error" };
      })
      .addCase(saveReferralAsync.fulfilled, (state, action) => {
        state.saveReferralStatus = 'idle';
        state.saveReferralResult = action.payload;
      });
    builder
      .addCase(savePasswordAsync.pending, (state) => {
        state.saveSellerAccountStatus = 'loading';
      })
      .addCase(savePasswordAsync.fulfilled, (state, action) => {
        state.saveSellerAccountStatus = 'idle';
      });
  },
});

export const { increment } = homeSlice.actions;

export const selectVehicles = (state) => state.home.vehicles;

export const selectReferrals = (state) => state.home.referrals;

export const selectSaveVehicleResult = (state) => state.home.saveVehicleResult;

export const selectSaveVehicleStatus = (state) => state.home.saveVehicleStatus;

export const selectSaveSellerAccountResult = (state) => state.home.saveSellerAccountResult;

export const selectSaveSellerAccountStatus = (state) => state.home.saveSellerAccountStatus;

export const selectSaveReferralResult = (state) => state.home.saveReferralResult;

export const selectSaveReferralStatus = (state) => state.home.saveReferralStatus;

export default homeSlice.reducer;
