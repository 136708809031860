import { createSlice, createAsyncThunk, current, createReducer, createAction } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));

export const register = createAsyncThunk(
  "auth/register",
  async ({ username, email, password }, thunkAPI) => {
    try {
      const response = await AuthService.register(username, email, password);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(username, password);
      return { user: data };
    } catch (error) {
      var message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (message && message.endsWith('401')) {
          message = 'userPassIncorrect';
        } else {
          message = 'errorTryLater';
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const emailNotAlreadyUsed = createAsyncThunk(
  "auth/emailNotAlreadyUsed",
  async ({ email }, thunkAPI) => {
    try {
      const data = await AuthService.emailNotAlreadyUsed(email);
      return data;
    } catch (error) {
      //const message =
      //  (error.response &&
      //    error.response.data &&
      //    error.response.data.message) ||
      //  error.message ||
      //  error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resetMyPassword = createAsyncThunk(
  "auth/resetMyPassword",
  async ({ username }, thunkAPI) => {
    try {
      const data = await AuthService.resetMyPassword(username);
      return { outcome: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

const initialState = user
  ? {
    isLoggedIn: true,
    user,
    passwordReset: false,
    lang: localStorage.getItem('lang') || 'en',
    emailNotAlreadyUsedStatus: null,
    emailNotAlreadyUsedResponse: null
  }
  : {
    isLoggedIn: false,
    user: null,
    passwordReset: false,
    lang: localStorage.getItem('lang') || 'en',
    emailNotAlreadyUsedStatus: null,
    emailNotAlreadyUsedResponse: null
  };


const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSeller: (state, action) => {
      Object.assign(state.user.user, action.payload);
      const currentUserLocalStorage = JSON.parse(localStorage.getItem('user'));
      currentUserLocalStorage.user = action.payload;
      localStorage.setItem("user", JSON.stringify(currentUserLocalStorage));
    }
  },
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [resetMyPassword.fulfilled]: (state, action) => {
      state.passwordReset = true;
      state.passwordResetOutcome = action.payload.outcome;
    },
    [emailNotAlreadyUsed.fulfilled]: (state, action) => {
      state.emailNotAlreadyUsedResponse = action.payload;
      console.log({ pl: action.payload })
      state.emailNotAlreadyUsedStatus = 'idle';
    },
    [emailNotAlreadyUsed.rejected]: (state, action) => {
      state.emailNotAlreadyUsedResponse = null;
      state.emailNotAlreadyUsedStatus = 'idle';
    },
    [emailNotAlreadyUsed.pending]: (state, action) => {
      state.emailNotAlreadyUsedStatus = 'loading';
    },
  },
});

export const { setSeller } = authSlice.actions;

export const selectUser = (state) => state.auth.user;

export const selectLang = (state) => state.auth.lang;

export const selectSeller = (state) =>
  state.auth ? state.auth.user ? state.auth.user.user : null : null;

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectPasswordResetOutcome = (state) => state.auth.passwordResetOutcome;
export const selectPasswordReset = (state) => state.auth.passwordReset;
export const selectEmailNotAlreadyUsedResponse = (state) => state.auth.emailNotAlreadyUsedResponse;
export const selectEmailNotAlreadyUsedStatus = (state) => state.auth.emailNotAlreadyUsedStatus;

const { reducer } = authSlice;
export default reducer;
