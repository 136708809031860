import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { createTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
//import 'typeface-roboto'
import Typography from '@material-ui/core/Typography'
import qs from 'qs';
import FormPasswordReset from './FormPasswordReset'
//import './styles.css'

const theme = createTheme({
  palette: {
    type: 'dark',
  },
})

const SetNewPassword = (props) => {
  const useCode = qs.parse(props.location.search, { ignoreQueryPrefix: true }).useCode;
  return (
    <FormPasswordReset useCode={useCode} history={props.history} />
  )
};

export default SetNewPassword;
