import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import AdminHome from "../features/admin-home/AdminHome"


const BoardAdmin = (props) => {
  const [content, setContent] = useState("");

  //useEffect(() => {
  //  UserService.getAdminBoard().then(
  //    (response) => {
  //      setContent(response.data);
  //    },
  //    (error) => {
  //      const _content =
  //        (error.response &&
  //          error.response.data &&
  //          error.response.data.message) ||
  //        error.message ||
  //        error.toString();

  //      setContent(_content);

  //      if (error.response && error.response.status === 401) {
  //        EventBus.dispatch("logout");
  //      }
  //    }
  //  );
  //}, []);


  return (
    <div>
      {/*<header className="jumbotron">
        <h3>{content}</h3>
      </header>*/}
      <AdminHome {...props} />
    </div>
  );
};

export default BoardAdmin;
