import React from 'react';

function downloadFile(e) {
    var link = document.createElement("a");
    link.href = "https://eco2wo.com/api"+ e;
    link.target="_blank"
    document.body.appendChild(link);

    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
}

const DocIconRenderer = props => {

  return (
    <div className="flexbox-container-horiz-no-border" style={{ paddingTop: '6px', paddingLeft: '3px' }}>
      <button onClick={() => downloadFile(props.value)} className="button-borderless">
        <img src={`${props.rendererImage}`} width="15" height="20" alt="D"/>
      </button>
    </div>
)};

export default DocIconRenderer;