import axios, { newAxiosWithJwt } from "../http-common";

const register = (username, email, password) => {
  return axios.post("/signup", {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios
    .post("/authenticate", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const emailNotAlreadyUsed = (email) => {
  return axios
    .get("/register/emailIsNotTaken/" + email)
    .then((response) => {
      return response.data;
    });
}

const resetMyPassword = (username) => {
  return axios
    .get("/authenticate/forgotPassword/" + username)
    .then((response) => {
      return response.data;
    });
}

const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  register,
  login,
  logout,
  resetMyPassword,
  emailNotAlreadyUsed
};

export default authService;
