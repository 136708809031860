import http, { newAxiosWithJwt } from "../../http-common";

export async function adminFetchVehicles(user, onlybyPartners, validatedOnly) {
  return newAxiosWithJwt(user).get("/admin/co2savers" 
    + (onlybyPartners === true ? '?partnersOnly=true' : '?partnersOnly=false')
    + (validatedOnly === true ? '&validatedOnly=true' : '&validatedOnly=false')
  );
}

export async function adminFetchCO2SaversValidatedOrPaidUnbought(user) {
  return newAxiosWithJwt(user).get("/admin/co2savers/validatedOrPaidUnbought");
}

export async function adminFetchPartners(user) {
  return newAxiosWithJwt(user).get("/admin/partners");
}

export async function adminFetchAgents(user) {
  return newAxiosWithJwt(user).get("/admin/agents");
}

export async function fetchPartnerByPpc(ppc) {
  return http.get("/admin/partners/public/byPublicCode/" + ppc);
}

export async function disableAgent(id, dis, user) {
  return newAxiosWithJwt(user).post("/admin/agents/" + id + '/disable/' + dis);
}

export async function payPartner(partnerId, amount, user) {
  return newAxiosWithJwt(user).post("/admin/partners/pay/" + partnerId + '/' + amount);
}

export async function fetchAgentUserByPromoCode(promoCode) {
  return http.get("/agent/byPromoCode/" + promoCode);
}

export async function adminSaveVehicle(vehicleId, data, user) {
  return newAxiosWithJwt(user).post("/admin/co2savers/" + vehicleId, data);
}

export async function adminSavePartner(id, data, user) {
  return newAxiosWithJwt(user).post("/admin/partners/" + id, data);
}

export async function adminDeleteVehicle(vehicleId, user) {
  return newAxiosWithJwt(user).post("/admin/co2savers/delete/" + vehicleId);
}

export async function adminFetchAdminSettings(user) {
  return newAxiosWithJwt(user).get("/adminSettings");
}

export async function adminSaveAdminSettings(data, user) {
  return newAxiosWithJwt(user).post("/adminSettings", data);
}

export async function adminFetchBuyersInvoices(user) {
  return newAxiosWithJwt(user).get("/buyersInvoices");
}

export async function adminSaveBuyerInvoice(data, user) {
  return newAxiosWithJwt(user).post("/buyersInvoices", data);
}

export async function saveAdminReferrals(data, newStatus, user) {
  return newAxiosWithJwt(user).post("/admin/referrals/" + newStatus, data);
}

export async function saveCo2SaversNewStatus(data, newStatus, user) {
  return newAxiosWithJwt(user).post("/admin/co2savers/newStatus/" + newStatus, data);
}

export async function saveAdminCommissions(data, newStatus, user) {
  return newAxiosWithJwt(user).post("/admin/co2savers/commissions/" + newStatus, data);
}

export async function fetchAdminReferrals(user) {
  return newAxiosWithJwt(user).get("/admin/referrals");
}

export async function fetchAdminStats(user) {
  return newAxiosWithJwt(user).get("/adminSettings/stats");
}

export async function createCreditNotes(user, selType) {
  return newAxiosWithJwt(user).get("admin/co2savers/cfcnzip?sellerType=" + selType);
}

export async function adminFetchSellerByEmail(user, email) {
  return newAxiosWithJwt(user).get("admin/co2savers/seller/"+email);
}
