import React, { Component, useEffect, useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import {
  payPartnerAsync,
  selectPayPartnerStatus,
  selectPayAgentResult
} from './adminHomeSlice';

var saveButtonClicked = false;

const PayPartner = (props) => {
  const { closePopup, data } = props;
  console.log({ data });
  const dispatch = useDispatch();

  const payPartnerStatus = useSelector(selectPayPartnerStatus);
  const { register, handleSubmit, errors } = useForm({
    defaultValues: data
  });

  const onSubmit = (updatedData) => {
    if (!window.confirm("Are you sure?")) {
      return false;
    }

    dispatch(payPartnerAsync({ partnerId: data.id, amount: updatedData.amount }))
  };

  useEffect(() => {
    if (payPartnerStatus === 'idle' && saveButtonClicked) {
      console.log('oh yeah')
      saveButtonClicked = false;
      closePopup();
      return;
    }

    if (payPartnerStatus === 'loading') {
      saveButtonClicked = true;
    }
  });

  function round2dec(num) {
    return Math.round(num * 100) / 100
  }

  return (
    <div>
      <h2>Pay Partner:&nbsp;{data.companyName}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>
      <hr />
      <Form className="input-form" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="totalCommGroup">
          <Form.Label>Total Earned Commission</Form.Label>
          <InputGroup className="mb-3">

            <Form.Control
              type="text"
              name="totalComm"
              value={round2dec(data.totalCommission)}
              placeholder=""
              autoComplete="off"
              disabled={true}
              className={`${errors.vin ? 'input-error' : ''}`}
            />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="prevPaidCommGroup">
          <Form.Label>Previously Paid Commission</Form.Label>
          <InputGroup className="mb-3">

            <Form.Control
              type="text"
              name="prevPayComm"
              value={round2dec(data.paidCommission)}
              placeholder=""
              autoComplete="off"
              disabled={true}
              className={`${errors.vin ? 'input-error' : ''}`}
            />
            <InputGroup.Text>€</InputGroup.Text>

          </InputGroup>
        </Form.Group>
        <Form.Group controlId="PaymentAmountGroup">
          <Form.Label>Commission Paid NOW</Form.Label>
          <InputGroup className="mb-3">

            <Form.Control
              type="text"
              name="amount"
              placeholder=""
              autoComplete="off"
              ref={register({
                required: false,
                pattern: {
                  value: /^[0-9\\.\\-]+$/,
                  message: 'Amount can contain numbers only.'
                }
              })}
              className={`${errors.amount ? 'input-error' : ''}`}
            />
            {errors.amount && (
              <p className="errorMsg">{errors.amount.message}</p>
            )}
            <InputGroup.Text>€</InputGroup.Text>

          </InputGroup>
        </Form.Group>
        <div className="flexbox-container-horiz-no-border">
          <div className="flexbox-container-vert-no-border" style={{ width: '50%' }}>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PayPartner;
