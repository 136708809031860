import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, selectIsLoggedIn } from '../../slices/auth';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { encode, decode } from 'base64-arraybuffer';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import DocIconRenderer from './docIconRenderer';

import {
  selectPartners,
  selectSavePartnerResult,
  selectSavePartnerStatus,
  savePartnerAsync,
  fetchPartnersAsync,
  payPartnerAsync
} from './adminHomeSlice';
import { height } from 'dom-helpers';
import PayPartner from './PayPartner';
import { FormattedMessage } from 'react-intl';

if (FileReader.prototype.readAsArrayBuffer && FileReader.prototype.readAsBinaryString) {
  FileReader.prototype.readAsArrayBuffer = function readAsArrayBuffer() {
    this.readAsBinaryString.apply(this, arguments);
    this.__defineGetter__('resultString', this.__lookupGetter__('result'));
    this.__defineGetter__('result', function () {
      var string = this.resultString;
      var result = new Uint8Array(string.length);
      for (var i = 0; i < string.length; i++) {
        result[i] = string.charCodeAt(i);
      }
      return result.buffer;
    });
  };
}

let needsReloading = true;
var saveButtonClicked = false;

export default function AdminPartners(props) {
  const partners = useSelector(selectPartners);
  const savePartnerStatus = useSelector(selectSavePartnerStatus);
  const savePartnerResult = useSelector(selectSavePartnerResult);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [country, setCountry] = useState('Deutschland');
  const [logoFile, setLogoFile] = useState(null);
  const [tncFile, setTncFile] = useState(null);
  const [editedPartner, setEditedPartner] = useState({});
  const [payPartnerNotAgents, setPayPartnerNotAgents] = useState('false');
  const [partnerCommType, setPartnerCommType] = useState('Sales');


  useEffect(() => {
    if (needsReloading) {
      dispatch(fetchPartnersAsync());
      needsReloading = false;
    }
  });

  if (!user) {
    props.history.push('/login');
  }

  const cellLeftAlign = { 'text-align': "left", padding: '2px' };
  const cellRightAlign = { 'text-align': "right", padding: '2px' };
  const cellCenterAlign = { 'text-align': "center", padding: '2px' };

  function headerHeightSetter() {
    var padding = 2;
    var height = 50 + padding;
    this.api.setHeaderHeight(height);
    this.api.resetRowHeights();
  }

  function dateFormatter(params) {
    var newDate = new Date();
    newDate.setTime(Date.parse(params.value));
    return newDate.toLocaleDateString();
  }

  //var gridApi;
  //var gridColumnApi;

  //const onGridReady = params => {
  //  gridApi = params.api;
  //  gridColumnApi = params.columnApi;
  //};

  const { register, errors, handleSubmit, reset } = useForm({
    defaultValues: {}
  });

  const onSubmit = async (data, event) => {
    event.preventDefault();
    var partner = { ...data };
    partner.country = country;
    partner.logoFile = logoFile;
    partner.tncFile = tncFile;
    partner.payPartnerNotAgents = payPartnerNotAgents;
    partner.partnerCommType = partnerCommType;
    dispatch(savePartnerAsync({ id: editedPartner.id || '0', data: partner }));
  };

  function round2dec(num) {
    return Math.round(num * 100) / 100
  }

  var countries = [
    { isoCode: 'Deutschland', name: 'Deutschland' },
  ];

  var optionsPPNA = [
    { isoCode: 'false', name: 'Pay Agents on IBANs' },
    { isoCode: 'true', name: 'Pay Partner (no IBANs for Agents)' }
  ];

  var optionsSOrM = [
    { isoCode: 'Sales', name: 'Sales' },
    { isoCode: 'Marketing', name: 'Marketing' }
  ];

  function onClosePopup() {
    dispatch(fetchPartnersAsync());
  }

  useEffect(() => {
    console.log(savePartnerStatus);
    if (savePartnerStatus === 'idle' && saveButtonClicked) {
      saveButtonClicked = false;
      if (savePartnerResult && !savePartnerResult.error) {
        Swal.fire('Awesome!', "Partner Saved!", 'success');

        dispatch(fetchPartnersAsync());
        reset({});
        setEditedPartner({});
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Partner could not be saved. Please check if the Company Name or Email are duplicates.'
        });

        return;
      }
    }
    if (savePartnerStatus === 'loading') {
      saveButtonClicked = true;
    }

  });

  var gridApi;
  var gridColumnApi;

  const onGridReady = params => {
    gridApi = params.api;
    gridColumnApi = params.columnApi;
    params.columnApi.getColumn('id').setSort("asc")
  };

  const [open, setOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
    dispatch(fetchPartnersAsync());
  }

  return (
    <div>
      <header className="">
        <h3>Partners Management</h3>
        You can use this page to add and edit Partners.
      </header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="flexbox-container-horiz">
          <div style={{ width: '33.33%', paddingLeft: '10px', paddingRight: '15px' }} >
            <Form.Group controlId="companyNameGroup">
              <Form.Label>Company name</Form.Label>
              <Form.Control
                type="text"
                name="companyName"
                autoComplete="off"
                ref={register({
                  required: 'Company name is required.',
                })}
                className={`${errors.companyName ? 'input-error' : ''}`}
                style={{ textAlign: 'left' }}
              />
              {errors.companyName && (
                <p className="errorMsg">{errors.companyName.message}</p>
              )}
            </Form.Group>

            <div>Clients payment amounts</div>
            <div className="flexbox-container-horiz-no-border" style={{ padding: '0px' }}>
              <Form.Group controlId="pvtCarPaymentAmountGroup" style={{ width: '24%' }}>
                <Form.Label>Private <br />Cars</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    name="pvtCarPaymentAmount"
                    defaultValue="0.0"
                    autoComplete="off"
                    ref={register({
                      required: 'Partner pvtCarPaymentAmount is required.',
                    })}
                    className={`${errors.pvtCarPaymentAmount ? 'input-error' : ''}`}
                    style={{ textAlign: 'left' }}
                  />
                  {errors.pvtCarPaymentAmount && (
                    <p className="errorMsg">{errors.pvtCarPaymentAmount.message}</p>
                  )}
                  <InputGroup.Text>€</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              &nbsp; &nbsp;
              <Form.Group controlId="fleetCarPaymentAmountGroup" style={{ width: '24%' }}>
                <Form.Label>Commercial Cars</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    name="fleetCarPaymentAmount"
                    defaultValue="0.0"
                    autoComplete="off"
                    ref={register({
                      required: 'Agent fleetCarPaymentAmount is required.',
                    })}
                    className={`${errors.fleetCarPaymentAmount ? 'input-error' : ''}`}
                    style={{ textAlign: 'left' }}
                  />
                  {errors.fleetCarPaymentAmount && (
                    <p className="errorMsg">{errors.fleetCarPaymentAmount.message}</p>
                  )}
                  <InputGroup.Text>€</InputGroup.Text>

                </InputGroup>
              </Form.Group>
              &nbsp; &nbsp;
              <Form.Group controlId="fleetTruckPaymentAmountGroup" style={{ width: '24%' }}>
                <Form.Label>Commercial Trucks</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    name="fleetTruckPaymentAmount"
                    defaultValue="0.0"
                    autoComplete="off"
                    ref={register({
                      required: 'Agent fleetTruckPaymentAmount is required.',
                    })}
                    className={`${errors.fleetTruckPaymentAmount ? 'input-error' : ''}`}
                    style={{ textAlign: 'left' }}
                  />
                  {errors.fleetTruckPaymentAmount && (
                    <p className="errorMsg">{errors.fleetTruckPaymentAmount.message}</p>
                  )}
                  <InputGroup.Text>€</InputGroup.Text>

                </InputGroup>
              </Form.Group>
              &nbsp; &nbsp;
              <Form.Group controlId="fleetBusPaymentAmountGroup" style={{ width: '24%' }}>
                <Form.Label>Commercial Buses</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    name="fleetBusPaymentAmount"
                    defaultValue="0.0"
                    autoComplete="off"
                    ref={register({
                      required: 'Agent fleetBusPaymentAmount is required.',
                    })}
                    className={`${errors.fleetBusPaymentAmount ? 'input-error' : ''}`}
                    style={{ textAlign: 'left' }}
                  />
                  {errors.fleetBusPaymentAmount && (
                    <p className="errorMsg">{errors.fleetBusPaymentAmount.message}</p>
                  )}
                  <InputGroup.Text>€</InputGroup.Text>

                </InputGroup>
              </Form.Group>
            </div>

            <Form.Group controlId="addPayCustGroup" style={{ width: '100%' }}>
              <Form.Label>Promo Code (Bonus/Plus) Payment For Customers</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  defaultValue="0.0"
                  name="additionalPaymentAmount"
                  autoComplete="off"
                  ref={register({
                    required: 'Additional Payment For Customers is required.',
                  })}
                  className={`${errors.additionalPaymentAmount ? 'input-error' : ''}`}
                  style={{ textAlign: 'left' }}
                />
                {errors.additionalPaymentAmount && (
                  <p className="errorMsg">{errors.additionalPaymentAmount.message}</p>
                )}
                <InputGroup.Text>€</InputGroup.Text>

              </InputGroup>
            </Form.Group>
            <Form.Group controlId="percentPartnerGroup">
              <Form.Label>Partner Commission Percent</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  name="commissionPercentForPartner"
                  defaultValue="0.0"
                  autoComplete="off"
                  ref={register({
                    required: 'Partner Commission Percent is required.',
                  })}
                  className={`${errors.commissionPercentForPartner ? 'input-error' : ''}`}
                  style={{ textAlign: 'left' }}
                />
                {errors.commissionPercentForPartner && (
                  <p className="errorMsg">{errors.commissionPercentForPartner.message}</p>
                )}
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <div>Agent Commission Percent</div>
            <div className="flexbox-container-horiz-no-border" style={{ padding: '0px' }}>
              <Form.Group controlId="percentPartnerGroup" style={{ width: '48%' }}>
                <Form.Label>Private Vehicles</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    name="commissionPercentForAgent"
                    defaultValue="0.0"
                    autoComplete="off"
                    ref={register({
                      required: 'Partner Commission Percent is required.',
                    })}
                    className={`${errors.commissionPercentForPartner ? 'input-error' : ''}`}
                    style={{ textAlign: 'left' }}
                  />
                  {errors.commissionPercentForPartner && (
                    <p className="errorMsg">{errors.commissionPercentForPartner.message}</p>
                  )}
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              &nbsp; &nbsp;&nbsp;&nbsp;
              <Form.Group controlId="percentAgentGroup" style={{ width: '48%' }}>
                <Form.Label>Commercial Fleets</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    name="commissionPercentForAgentCF"
                    defaultValue="0.0"
                    autoComplete="off"
                    ref={register({
                      required: 'Agent Commission Percent is required.',
                    })}
                    className={`${errors.commissionPercentForAgent ? 'input-error' : ''}`}
                    style={{ textAlign: 'left' }}
                  />
                  {errors.commissionPercentForAgent && (
                    <p className="errorMsg">{errors.commissionPercentForAgent.message}</p>
                  )}
                  <InputGroup.Text>%</InputGroup.Text>

                </InputGroup>
              </Form.Group>
            </div>

          </div>

          <div style={{ width: '33.33%', paddingLeft: '15px', paddingRight: '15px' }}  >

            <Form.Group controlId="emailGroup" style={{ width: '100%' }}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                autoComplete="off"
                ref={register({
                  required: 'Email is required.',
                })}
                className={`${errors.email ? 'input-error' : ''}`}
                style={{ textAlign: 'left' }}
              />
              {errors.email && (
                <p className="errorMsg">{errors.email.message}</p>
              )}
            </Form.Group>

            <div className="flexbox-container-horiz-no-border" style={{ padding: '0px' }}>

              <Form.Group controlId="contactNameGroup" style={{ width: '48%' }}>
                <Form.Label>Contact Name</Form.Label>
                <Form.Control
                  type="text"
                  name="contactName"
                  autoComplete="off"
                  ref={register({
                    required: 'Contact Name is required.',
                  })}
                  className={`${errors.contactName ? 'input-error' : ''}`}
                  style={{ textAlign: 'left' }}
                />
                {errors.contactName && (
                  <p className="errorMsg">{errors.contactName.message}</p>
                )}
              </Form.Group>
              &nbsp; &nbsp;&nbsp;&nbsp;

              <Form.Group controlId="contactJobTitleGroup" style={{ width: '48%' }}>
                <Form.Label>Contact Job Title</Form.Label>
                <Form.Control
                  type="text"
                  name="contactJobTitle"
                  autoComplete="off"
                  ref={register({
                    required: 'Contact Job Title is required.',
                  })}
                  className={`${errors.contactJobTitle ? 'input-error' : ''}`}
                  style={{ textAlign: 'left' }}
                />
                {errors.contactJobTitle && (
                  <p className="errorMsg">{errors.contactJobTitle.message}</p>
                )}
              </Form.Group>

            </div>

            <Form.Group controlId="addressGroup" style={{ width: '100%' }}   >
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                autoComplete="off"
                ref={register({
                  required: 'Address is required.',
                })}
                className={`${errors.address ? 'input-error' : ''}`}
                style={{ textAlign: 'left' }}
              />
              {errors.address && (
                <p className="errorMsg">{errors.address.message}</p>
              )}
            </Form.Group>
            <div className="flexbox-container-horiz-no-border" style={{ padding: '0px' }}>
              <Form.Group controlId="addressGroup" style={{ paddingRight: '15px' }}>
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  autoComplete="off"
                  ref={register({
                    required: 'City is required.',
                  })}
                  className={`${errors.city ? 'input-error' : ''}`}
                  style={{ textAlign: 'left' }}
                />
                {errors.city && (
                  <p className="errorMsg">{errors.city.message}</p>
                )}
              </Form.Group>
              <Form.Group controlId="postcodeGroup">
                <Form.Label>Postcode</Form.Label>
                <Form.Control
                  type="text"
                  name="postcode"
                  autoComplete="off"
                  ref={register({
                    required: 'Postcode is required.',
                    pattern: {
                      value: /^[0-9]{5,5}$/,
                      message: 'Postcode is 5 digits.',
                    },
                  })}
                  className={`${errors.postcode ? 'input-error' : ''}`}
                  style={{ textAlign: 'left' }}
                />
                {errors.postcode && (
                  <p className="errorMsg">{errors.postcode.message}</p>
                )}
              </Form.Group>
            </div>
            <Form.Group controlId="countryGroup">
              <Form.Label>Country</Form.Label>
              <Form.Control className="form-control"
                as="select"
                name="country"
                value={country}
                ref={register({})}
                onChange={(event) => setCountry(event.target.value)} >
                {countries.map(({ isoCode, name }) => (
                  <option value={isoCode} key={isoCode}>
                    {name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="ppnaGr">
              <Form.Label>Type of Referral</Form.Label>
              <Form.Control className="form-control"
                as="select"
                name="partnerCommTypeControl"
                value={partnerCommType}
                ref={register({})}
                onChange={(event) => setPartnerCommType(event.target.value)} >
                {optionsSOrM.map(({ isoCode, name }) => (
                  <option value={isoCode} key={isoCode}>
                    {name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>

          <div style={{ width: '33.33%', paddingLeft: '15px', paddingRight: '10px' }}  >

            <Form.Group controlId="ppnaGr">
              <Form.Label>Agents payment option</Form.Label>
              <Form.Control className="form-control"
                as="select"
                name="payPartnerNotAgents"
                value={payPartnerNotAgents}
                ref={register({})}
                onChange={(event) => setPayPartnerNotAgents(event.target.value)} >
                {optionsPPNA.map(({ isoCode, name }) => (
                  <option value={isoCode} key={isoCode}>
                    {name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId='ibanHolderName'>
              <Form.Label>
                {/* <FormattedMessage id='recipientName' /> */}
                IBAN Recepient Name
              </Form.Label>
              <Form.Control
                type='text'
                name='ibanHolderName'
                //placeholder="Enter your IBAN's Holder Name"
                autoComplete='off'
                ref={register({
                })}
                className={`${errors.ibanHolderName ? 'input-error' : ''}`}
              />
              {errors.ibanHolderName && (
                <p className='errorMsg'>{errors.ibanHolderName.message}</p>
              )}
            </Form.Group>
            <Form.Group controlId='iban'>
              <Form.Label>IBAN</Form.Label>
              <Form.Control
                type='text'
                name='iban'
                //placeholder='Enter your IBAN'
                autoComplete='off'
                ref={register({
                  pattern: {
                    value: /^[a-zA-Z0-9]+$/,
                    message: 'IBAN should contain only characters and numbers.',
                  },
                })}
                className={`${errors.iban ? 'input-error' : ''}`}
              />
              {errors.firstName && (
                <p className='errorMsg'>{errors.iban.message}</p>
              )}
            </Form.Group>
            <Form.Group controlId='bankName'>
              <Form.Label>
                <FormattedMessage id='bankName' />
              </Form.Label>
              <Form.Control
                type='text'
                name='bankName'
                //placeholder="Enter your Bank's name"
                autoComplete='off'
                ref={register({
                })}
                className={`${errors.bank_name ? 'input-error' : ''}`}
              />
              {errors.lastName && (
                <p className='errorMsg'>{errors.bank_name.message}</p>
              )}
            </Form.Group>

            <div className="flexbox-container-horiz-no-border" style={{ padding: '0px' }}>

              <Form.Group controlId="logoGroup" style={{ width: '50%' }}>
                <Form.Label>Logo</Form.Label>
                <input type="file" onChange={e => {
                  if (e.target.files && e.target.files[0]) {
                    var reader = new FileReader();
                    reader.onload = function () {
                      var arrayBuffer = reader.result;
                      console.log({ logoFile: encode(arrayBuffer) });
                      setLogoFile({ name: e.target.files[0].name, dataB64: encode(arrayBuffer) });
                    }
                    reader.readAsArrayBuffer(e.target.files[0]);
                  } else {
                    setLogoFile(null);
                  }
                }} />
              </Form.Group>
            </div>
            <div className="flexbox-container-horiz-no-border" style={{ padding: '0px' }}>

              <Form.Group controlId="tncGroup" style={{ width: '50%' }}>
                <Form.Label>Sales (Agents) T&C file</Form.Label>
                <input type="file" onChange={e => {
                  if (e.target.files && e.target.files[0]) {
                    var reader = new FileReader();
                    reader.onload = function () {
                      var arrayBuffer = reader.result;
                      setTncFile({ name: e.target.files[0].name, dataB64: encode(arrayBuffer) });
                    }
                    reader.readAsArrayBuffer(e.target.files[0]);
                  } else {
                    setTncFile(null);
                  }
                }} />
              </Form.Group>
            </div>
            <div style={{
              display: 'flex', flexDirection: 'row', height: '80px',
              paddingTop: '20px', paddingBottom: '10px'
            }}>
              <div style={{
                width: '50%', display: 'flex', flexDirection: 'column',
                paddingLeft: '30px', paddingRight: '30px'
              }}>
                <Button variant="primary" type="button"
                  onClick={() => { setEditedPartner({}); reset({}); }}
                >Cancel</Button>
              </div>
              <div style={{
                width: '50%', display: 'flex', flexDirection: 'column',
                paddingLeft: '30px', paddingRight: '30px'
              }}>
                <Button variant="primary" type="submit">Save</Button>
              </div>
            </div>

          </div>
          {/* //commissionPercentForAgentCF */}


        </div>
      </Form >

      <Modal open={open} onClose={onCloseModal} center>
        <PayPartner data={selectedRowData} closePopup={onCloseModal} />
      </Modal>

      <br />

      <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
        <AgGridReact style={{ height: 800, width: '100%' }}
          onGridReady={onGridReady}
          onFirstDataRendered={headerHeightSetter}
          rowData={partners}
          frameworkComponents={{
            editRowButtonRenderer: (props) => {
              return (
                <div>
                  <button className="btn btn-success btn-success-thin"
                    onClick={() => {
                      setEditedPartner(props.data);
                      setPayPartnerNotAgents('' + props.data.payPartnerNotAgents);
                      setPartnerCommType(props.data.partnerCommType);
                      reset(props.data);
                    }}>Edit...</button>
                </div>
              )
            },
            payRowButtonRenderer: (props) => {
              return (
                <div>
                  <button className="btn btn-success btn-success-thin"
                    onClick={() => {
                      setSelectedRowData(props.data);
                      onOpenModal();
                    }}>Pay...</button>
                </div>
              )
            },
            docIconRendered: DocIconRenderer,
            linkRenderer: props => (<a target="_blank" href={window.location.origin + '/regAgent?partner=' + props.data.companyName + '&ppc=' + props.value}>LINK</a>),
            linkRenderer2: props => (<a target="_blank" href={window.location.origin + '/land?partner=' + props.data.companyName + '&ppc=' + props.value}>LINK</a>)
          }}
          defaultColDef={{
            flex: 1,
            editable: true,
            cellStyle: cellLeftAlign,
            headerClass: "text-center",
            wrapText: true
          }}
          default key="id"
        >
          <AgGridColumn headerName="ID" field="id" maxWidth="35" filter={true} />
          <AgGridColumn headerName="Company Name" field="companyName" filter={true} />
          {/* <AgGridColumn headerName="Email" field="email" filter={true} minWidth="180" /> */}
          <AgGridColumn headerName="Contact name" field="contactName" filter={true} />
          {/* <AgGridColumn headerName="Contact Job Title" field="contactJobTitle" filter={true} /> */}

          {/* <AgGridColumn headerName="Address" field="address" filter={true} />
          <AgGridColumn headerName="City" field="city" filter={true} />
          <AgGridColumn headerName="Post Code" field="postcode" filter={true} maxWidth="80" /> */}
          {/* <AgGridColumn headerName="Country" field="country" filter={true} minWidth="95" /> */}

          <AgGridColumn field="publicCode" headerName="Agents Reg. Link"
            cellRenderer="linkRenderer" maxWidth="70" cellStyle={cellCenterAlign}
          />
          <AgGridColumn field="publicCode" headerName="Landing Page Link"
            cellRenderer="linkRenderer2" maxWidth="70" cellStyle={cellCenterAlign}
          />
          <AgGridColumn headerName="Logo"
            cellRendererParams={{ "rendererImage": "front-icon-doc.png" }}
            cellRenderer="docIconRendered"
            valueGetter={(params) => `/admin/partners/logoFile/${params.data.id}`}
            field="logo" maxWidth="45" />
          <AgGridColumn headerName="Sales T&C"
            cellRendererParams={{ "rendererImage": "front-icon-doc.png" }}
            cellRenderer="docIconRendered"
            valueGetter={(params) => `/admin/partners/tncFile/${params.data.id}`}
            maxWidth="45" />

          <AgGridColumn headerName="Partner Commiss." valueGetter={(params) =>
            params.data.commissionPercentForPartner ? ('' + params.data.commissionPercentForPartner).concat(` %`) : ''}
            filter={true} maxWidth="95" cellStyle={cellCenterAlign} />
          <AgGridColumn headerName="Agent Commiss. Private" valueGetter={(params) =>
            params.data.commissionPercentForAgent ? ('' + params.data.commissionPercentForAgent).concat(` %`) : ''}
            filter={true} maxWidth="95" cellStyle={cellCenterAlign} />
          <AgGridColumn headerName="Agent Commiss. Fleets" valueGetter={(params) =>
            params.data.commissionPercentForAgentCF ? ('' + params.data.commissionPercentForAgentCF).concat(` %`) : ''}
            filter={true} maxWidth="95" cellStyle={cellCenterAlign} />
          <AgGridColumn headerName="Pvt. Cars Base Payment" valueGetter={(params) =>
            params.data.pvtCarPaymentAmount ? ('' + params.data.pvtCarPaymentAmount).concat(` €`) : ''}
            filter={true} maxWidth="95" cellStyle={cellCenterAlign} />

          <AgGridColumn headerName="Fleet Cars Base Payment" valueGetter={(params) =>
            params.data.fleetCarPaymentAmount ? ('' + params.data.fleetCarPaymentAmount).concat(` €`) : ''}
            filter={true} maxWidth="95" cellStyle={cellCenterAlign} />
          <AgGridColumn headerName="Promo Add. Payment" valueGetter={(params) =>
            params.data.additionalPaymentAmount ? ('' + params.data.additionalPaymentAmount).concat(` €`) : ''}
            filter={true} maxWidth="95" cellStyle={cellCenterAlign} />


          <AgGridColumn headerName="Total P+A Commission" valueGetter={(params) =>
            (params.data.totalPPlusACommission != null) ? ('' + round2dec(params.data.totalPPlusACommission)).concat(` €`) : ''}
            filter={true} maxWidth="95" cellStyle={cellCenterAlign} />

          <AgGridColumn headerName="Total Partner Commission" valueGetter={(params) =>
            params.data.totalCommission != null ? ('' + round2dec(params.data.totalCommission)).concat(` €`) : ''}
            filter={true} maxWidth="95" cellStyle={cellCenterAlign} />
          <AgGridColumn headerName="Already Paid Commission" valueGetter={(params) =>
            params.data.paidCommission != null ? ('' + round2dec(params.data.paidCommission)).concat(` €`) : ''}
            filter={true} maxWidth="95" cellStyle={cellCenterAlign} />
          <AgGridColumn headerName="To Be Paid Commission" valueGetter={(params) =>
            params.data.toBePaidCommission != null ? ('' + round2dec(params.data.toBePaidCommission)).concat(` €`) : ''}
            filter={true} maxWidth="95" cellStyle={cellCenterAlign} />


          <AgGridColumn headerName=""
            cellRenderer="editRowButtonRenderer"
            cellRendererParams={{ "parentOnClosePopup": () => onClosePopup }}
            cellStyle={cellCenterAlign}
            minWidth="80"
          />

          <AgGridColumn headerName=""
            cellRenderer="payRowButtonRenderer"
            cellStyle={cellCenterAlign}
            minWidth="80"
          />
        </AgGridReact>
      </div >

    </div>
  );

}
