import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { selectSeller } from "../../slices/auth";
import { FormattedMessage } from "react-intl";

import ecoImage from "../../assets/leaves-gec19df3f6_1280.jpg";
import { IntlProvider, useIntl } from "react-intl";

import Spinner from "./Spinner";
import {
  savePasswordAsync,
  selectSaveSellerAccountResult,
  selectSaveSellerAccountStatus,
} from "../home/homeSlice";

var saveButtonClicked = false;

const FormPasswordReset = (props) => {
  var useCode = props.useCode;
  console.log({ useCode });
  const saveSellerAccountStatus = useSelector(selectSaveSellerAccountStatus);
  const dispatch = useDispatch();
  const seller = useSelector(selectSeller);
  const intl = useIntl(); //intl.formatMessage({ id: 'certId' })

  const onSubmit = async (data, event) => {
    event.preventDefault();
    dispatch(
      savePasswordAsync({
        pwdResetCode: data.currentPass,
        password: data.newPass,
        useCode,
        newPass: data.newPass,
      })
    );
  };

  const { register, errors, handleSubmit } = useForm({
    defaultValues: useCode
      ? {
          currentPass: useCode,
        }
      : {},
  });

  useEffect(() => {
    console.log(saveSellerAccountStatus);
    if (saveSellerAccountStatus === "idle" && saveButtonClicked) {
      saveButtonClicked = false;
      Swal.fire("Awesome!", "You've updated your password!", "success").then(
        (result) => {
          if (result.isConfirmed || result.isDismissed) {
            props.history.push("/login");
          }
        }
      );
      return;
    }

    if (saveSellerAccountStatus === "loading") {
      saveButtonClicked = true;
    }
  });

  return (
    <div>
      <header className=""></header>
      <div className="row content-container two-column-form">
        <div className="left col-md-6 col-sm-12">
          <h3>
            {useCode ? (
              <FormattedMessage id="changePassword" />
            ) : (
              <FormattedMessage id="changePassword" />
            )}
          </h3>
          <FormattedMessage id="subtitlePlsChangePass" />
          <Form className="input-form" onSubmit={handleSubmit(onSubmit)}>
            {seller && (
              <Form.Group controlId="ibanHolderName">
                <Form.Label>{`Email:  ${seller.email}`}</Form.Label>
              </Form.Group>
            )}
            <Form.Group controlId="currentPass">
              <Form.Label>
                {intl.formatMessage({
                  id: useCode ? "codeFromEmail" : "curPass",
                })}
              </Form.Label>
              <Form.Control
                type={useCode ? "text" : "password"}
                name="currentPass"
                placeholder=""
                autoComplete="off"
                ref={register(useCode ? { required: "required." } : {})}
                className={`${errors.currentPass ? "input-error" : ""}`}
              />
              {errors.currentPass && (
                <p className="errorMsg">{errors.currentPass.message}</p>
              )}
            </Form.Group>

            <Form.Group controlId="currentPass">
              <Form.Label>
                <FormattedMessage id="newPassword" />
              </Form.Label>
              <Form.Control
                type="password"
                name="newPass"
                placeholder=""
                autoComplete="off"
                ref={register({
                  required: "New Password is required.",
                })}
                className={`${errors.newPass ? "input-error" : ""}`}
              />
              {errors.newPass && (
                <p className="errorMsg">{errors.newPass.message}</p>
              )}
            </Form.Group>

            <Form.Group controlId="currentPass">
              <Form.Label>
                <FormattedMessage id="confiPass" />
              </Form.Label>
              <Form.Control
                type="password"
                name="confirmPass"
                placeholder=""
                autoComplete="off"
                ref={register({
                  required: "Confirm Password is required.",
                })}
                className={`${errors.confirmPass ? "input-error" : ""}`}
              />
              {errors.confirmPass && (
                <p className="errorMsg">{errors.confirmPass.message}</p>
              )}
            </Form.Group>
            <Button variant="primary" type="submit">
              <FormattedMessage id="save" />
            </Button>
          </Form>
        </div>
        <div className="col-md-6 col-sm-12 p-0">
          <div className="image-container">
            <img src={ecoImage} alt="eco green car co2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormPasswordReset;
