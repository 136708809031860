const serverSideEnums = (intl) => {

  const status = {
    InProcess: intl.formatMessage({ id: 'statusInProcess' }),
    Reviewing: intl.formatMessage({ id: 'statusReviewing' }),
    Rejected: intl.formatMessage({ id: 'statusRejected' }),
    Validated: intl.formatMessage({ id: 'statusValidated' }),
    Paid: intl.formatMessage({ id: 'statusPaid' }),
    PaymentInitiated: intl.formatMessage({ id: 'statusPaymentInitiated' }),
    PaymentFailed: intl.formatMessage({ id: 'statusPaymentFailed' })
  };

  const referralStatus = {
    Emailed: intl.formatMessage({ id: 'referralStatusEmailed' }),
    SignedUp: intl.formatMessage({ id: 'referralStatusSignedUp' }),
    CO2SaverValidated: intl.formatMessage({ id: 'referralStatusCO2SaverValidated' }),
    ToBePaid: intl.formatMessage({ id: 'referralStatusToBePaid' }),
    Paid: intl.formatMessage({ id: 'referralStatusPaid' })
  };

  return { status, referralStatus };
};

export default serverSideEnums;
