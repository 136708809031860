import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchVehiclesAsync,
  selectVehicles,
} from './adminHomeSlice';
import { selectUser, selectIsLoggedIn } from '../../slices/auth';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import serverSideEnums from '../../common/serverSideEnums';
import { FormattedMessage } from 'react-intl'
import { IntlProvider, useIntl } from 'react-intl';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Modal } from 'react-responsive-modal';

import EditRowButtonRenderer from './editButtonRenderer';
import DocsIconsListRenderer from './iconsListRenderer';
import ExportPopup from './ExportPopup';

import {
  saveCo2SaversNewStatusAsync,
  selectSaveMultVehiclesResult,
  selectSaveMultVehiclesStatus
} from './adminHomeSlice';

let needsReloading = true;
var saveButtonClicked = false;

export default function AdminHome(props) {
  const vehicles = useSelector(selectVehicles);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const intl = useIntl();
  const statusTranslations = serverSideEnums(intl).status;
  const [gridFilter, setGridFilter] = useState(null);
  const isSuperAdmin = user && user.roles.includes("ROLE_SuperAdmin");
  const adminEditAllData = props.adminEditAllData;
  const [rowIdsSelected, setRowIdsSelected] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('Reviewing');
  const saveMultVehiclesStatus = useSelector(selectSaveMultVehiclesStatus);


  useEffect(() => {
    if (needsReloading) {
      dispatch(fetchVehiclesAsync({ onlybyPartners: false }));
      needsReloading = false;
    }
  });

  useEffect(() => {
    if (saveMultVehiclesStatus === 'idle' && saveButtonClicked) {
      saveButtonClicked = false;
      console.log('BBBBBB')
      //needsReloading = true;
     dispatch(fetchVehiclesAsync({ onlybyPartners: false }));
      return;
    }

    if (saveMultVehiclesStatus === 'loading') {
      saveButtonClicked = true;
      console.log('AAAAAAA')

    }
  });

  if (!user) {
    props.history.push('/login');
  }

  const cellLeftAlign = { 'text-align': "left", padding: '2px' };
  const cellRightAlign = { 'text-align': "right", padding: '2px' };
  const cellCenterAlign = { 'text-align': "center", padding: '2px' };

  function headerHeightSetter() {
    var padding = 2;
    var height = 50 + padding;
    this.api.setHeaderHeight(height);
    this.api.resetRowHeights();
  }

  function onClosePopup() {
    dispatch(fetchVehiclesAsync({ onlybyPartners: false }));
  }

  function dateFormatter(params) {
    var newDate = new Date();
    newDate.setTime(Date.parse(params.value));
    return newDate.toLocaleDateString();
  }

  const { register, errors, handleSubmit, reset } = useForm({
    defaultValues: {}
  });

  const getSelectedRowData = (gridApi) => {
    let selectedNodes = gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data);
    console.log(selectedData);
    return selectedData;
  };

  const onGridSelectionChanged = ({ api, columnApi, type }) => {
    const selectedRowsDataArray = getSelectedRowData(api);
    const rowIds = [];
    selectedRowsDataArray.forEach(rowData => {
      rowIds.push(rowData.id);
    });
    console.log(rowIds);
    setRowIdsSelected(rowIds);
  }

  const onSubmit = async (data, event) => {
    event.preventDefault();
    dispatch(saveCo2SaversNewStatusAsync({ data: rowIdsSelected, newStatus: selectedStatus }));
  };

  var gridApi;
  var gridColumnApi;

  function onFilterChanged(params) {
    let filterModel = params.api.getFilterModel();
    setGridFilter(filterModel);
    console.log({ filterModel })
  }

  function gridSetFilters(params) {
    let filterModel = gridFilter;
    console.log(this)

    if (filterModel) {
      params.api.setFilterModel(filterModel);
    }
  }

  const onGridReady = params => {
    gridApi = params.api;
    gridColumnApi = params.columnApi;
    params.columnApi.getColumn('generatedId').setSort("asc");
    // gridApi.refreshHeader();
  };

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
  }

  var waysOfPayment = {
    IBAN: 'IBAN',
    NGO02: 'MyClimate',
    NGO01: 'Crea+',
  };

  var statuses = [
    { isoCode: 'Reviewing', name: 'Reviewing' },
    { isoCode: 'Validated', name: 'Validated' },
    { isoCode: 'Paid', name: 'Paid' },
  ];

  return (
    <div className="" style={{ height: 835, width: '100%', position: 'relative' }}>
      <header className="flexbox-container-horiz-no-border">
        <div>
          <h3><FormattedMessage id="certificates" /></h3>
          <FormattedMessage id="adminHomeSubtitle" />
        </div>
      </header>
      <Modal open={open} onClose={onCloseModal} center>
        <ExportPopup />
      </Modal>
      <div className="ag-theme-alpine" style={{ height: 735, width: '100%', position: 'relative' }}>
        <div style={{ position: 'absolute', top: -25, right: 10, zIndex: 1000, display: isSuperAdmin ? '' : 'none' }}>
          <button className="btn btn-success btn-success-thin" onClick={onOpenModal} >Export</button>
        </div>
        <div style={{ position: 'absolute', top: 25, right: 10, zIndex: 1000, display: (!isSuperAdmin && adminEditAllData) ? '' : 'none' }}>
          <EditRowButtonRenderer adminEditAllData={adminEditAllData} parentOnClosePopup={() => onClosePopup} />
        </div>
        <div style={{ position: 'absolute', top: 25, right: 10, zIndex: 1000, display: isSuperAdmin ? '' : 'none' }}>
          <EditRowButtonRenderer adminEditAllData={adminEditAllData} parentOnClosePopup={() => onClosePopup} />
        </div>
        <div style={{ position: 'absolute', top: -50, right: 200, zIndex: 1000, display: isSuperAdmin ? '' : 'none' }}>
          <Form className="input-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="flexbox-container-horiz-no-border"
              style={{ width: '400px', display: isSuperAdmin ? '' : 'none' }} >
              <Form.Group controlId="country">
                <Form.Control
                  as="select"
                  name="status"
                  onChange={(event) => setSelectedStatus(event.target.value)}
                >
                  {statuses.map(({ isoCode, name }) => (
                    <option value={isoCode} key={isoCode}>
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>&nbsp;&nbsp;
              <Button disabled={false} variant="primary" type="submit" style={{ height: '40px' }}>Update</Button>
            </div>
          </Form >
        </div>
        <br />
        <AgGridReact style={{ height: 735, width: '100%' }}
          onFirstDataRendered={headerHeightSetter}
          onRowDataChanged={gridSetFilters}
          onRowDataUpdated={gridSetFilters}
          rowData={vehicles}
          frameworkComponents={{
            editRowButtonRenderer: (props) => (
              <EditRowButtonRenderer
                adminEditAllData={adminEditAllData}
                {...props}
              />
            ),
            docsIconsListRenderer: DocsIconsListRenderer
          }}
          onGridReady={onGridReady}
          defaultColDef={{
            flex: 1,
            editable: true,
            cellStyle: cellLeftAlign,
            headerClass: "header-text-center",
            wrapText: true
          }}
          default pagination={true} paginationPageSize={15}
          onFilterChanged={onFilterChanged.bind(this)} rowSelection='multiple'
          suppressCellSelection={true}
          onSelectionChanged={onGridSelectionChanged}
          key="id">
          <AgGridColumn headerName={intl.formatMessage({ id: 'quotaID' })} field="generatedId" minWidth="80" sortable={true}
            cellStyle={cellCenterAlign} filter={true} />
          <AgGridColumn headerName={intl.formatMessage({ id: 'fname' })} field="seller.firstName" filter={true} sortable={true} minWidth="100" />
          <AgGridColumn headerName={intl.formatMessage({ id: 'lname' })} field="seller.lastName" filter={true} sortable={true} minWidth="100" />
          <AgGridColumn field="seller.companyName" minWidth="100" cellStyle={cellLeftAlign} headerName={intl.formatMessage({ id: 'company' })}
            headerClass="text-left" filter={true} sortable={true} />
          <AgGridColumn headerName="Email" field="seller.email" filter={true} sortable={true} minWidth="220" />

          <AgGridColumn headerName={intl.formatMessage({ id: 'licplate' })} maxWidth="100" cellStyle={cellLeftAlign}
            headerClass="text-left" filter={true} sortable={true} field="licencePlate" />
          <AgGridColumn field="vin" maxWidth="100" cellStyle={cellLeftAlign} headerName="VIN"
            headerClass="text-left" filter={true} sortable={true} />
          {/* <AgGridColumn field="name" maxWidth="100" cellStyle={cellLeftAlign} headerName="Ch.Point Name"
            headerClass="text-left" filter={true} sortable={true} /> */}

          <AgGridColumn
            field="year" sortable={true} filter={true} 
            headerName={intl.formatMessage({ id: "year" })}
          />

          <AgGridColumn field="partnerCompanyName" maxWidth="100" cellStyle={cellLeftAlign} headerName="Partner"
            headerClass="text-left" filter={true} sortable={true} />
          {/*<AgGridColumn headerName="Brand" valueGetter={(params) =>
          ((params.data.model || {})['brand'] || '').concat(' ', (params.data.model || {})['model'])} >
        </AgGridColumn>*/}
          {/*<AgGridColumn field="seller.email" minWidth="180" filter={true} sortable={true} />*/}
          <AgGridColumn field="creationDate" headerName={intl.formatMessage({ id: "submDate" })} valueFormatter={dateFormatter}
            sortable={true} filter={true} minWidth="90" />
          <AgGridColumn headerName="Docs" cellRendererParams={{ "rendererImage": "icon-doc.png" }}
            cellRenderer="docsIconsListRenderer" field="fileEvidences" minWidth="60" />
          <AgGridColumn headerName="Status" field="status" maxWidth="100" filter={true} sortable={true}
            valueGetter={(params) => { return statusTranslations[params.data.status]; }} />
          <AgGridColumn field="paymentAmount" cellStyle={cellRightAlign} valueGetter={(params) =>
            params.data.paymentAmount ? ('' + params.data.paymentAmount).concat(` €`) : ''}
            maxWidth="70" sortable={true} headerName={intl.formatMessage({ id: "paymentAmount" })} />
          <AgGridColumn headerName={intl.formatMessage({ id: "wop" })} field="seller.wayOfPayment" maxWidth="100" filter={true} sortable={true}
            valueGetter={(params) => { return waysOfPayment[params.data.seller.wayOfPayment]; }} />

          {/* <AgGridColumn field="amountMWGenerated" cellStyle={cellRightAlign} valueGetter={(params) =>
            params.data.amountMWGenerated ? ('' + params.data.amountMWGenerated).concat(` MWh`) : ''}
            minWidth="50" maxWidth="50" headerName="MWh Gen" headerClass="header-text-center" /> */}

          <AgGridColumn field="tCo2Saved" cellStyle={cellCenterAlign} valueGetter={(params) =>
            params.data.tco2Saved ? ('' + params.data.tco2Saved).concat(` t`) : ''}
            maxWidth="70" headerName="tCO2" headerClass="header-text-center" />
          {/* {isSuperAdmin &&
            <AgGridColumn headerName="Bought by" field="buyerInvoiceCompanyName"
              sortable={true} minWidth="60" maxWidth="100" filter={true} />
          } */}
          {isSuperAdmin && <AgGridColumn headerName="" checkboxSelection={true}
            minWidth="60" maxWidth="60" cellStyle={cellRightAlign} />
          }
          {(isSuperAdmin || adminEditAllData) &&
            <AgGridColumn headerName="" cellRenderer="editRowButtonRenderer" minWidth="80"
              cellRendererParams={{ "parentOnClosePopup": () => onClosePopup }} cellStyle={cellCenterAlign}
            />
          }
        </AgGridReact>
      </div>
    </div >
  );

}
