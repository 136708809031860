import React from 'react';

const ExportPopup = (props) => {
  
  function downloadValidated() {
    download([3])
  }

  function downloadReviewing() {
    download([1])
  }
  
  function download(statuses) {
    var link = document.createElement("a");
    link.href = "https://eco2wo.com/api/co2SaversPaymentInfo?statuses="+statuses.join(",");
    link.target = "_blank"

    document.body.appendChild(link);

    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
  }

  return (
    <div>
      <h2>Export Information&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>
      <hr />
      To send information to UBA, please export the <b>Reviewing</b> Vehicles&nbsp;&nbsp;&nbsp;
      <button className="btn btn-success btn-success-thin" onClick={downloadReviewing} >Reviewing Vehicles</button>

      <hr />
      To proceed with payment, please export the <b>Validated</b> Vehicles&nbsp;&nbsp;&nbsp;
      <button className="btn btn-success btn-success-thin" onClick={downloadValidated} >Validated Vehicles</button>
    </div>
  );
};

export default ExportPopup;
