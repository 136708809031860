import React from "react";

const ECOwoCard = ({
  children,
  title,
  subtitle,
  variation,
  handleAction,
  actionButtonText,
  br
}) => {
  return (
    <div className={`eCOwo-card ${variation}`}>
      <div className="wrapper">
        <div className="heading">
          <div style={br ? { paddingTop: '10px', paddingBottom: '18px' } : {}}>

            <h4>{title}</h4>
            <h6 className="subtitle">{subtitle}</h6>
          </div>
        </div>
        <div className="content">{children}</div>
        <div className="bottom">
          <button className={`btn btn-${variation}`} onClick={handleAction}>
            <span>{actionButtonText}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ECOwoCard;
