import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, selectIsLoggedIn } from '../../slices/auth';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { FormattedMessage } from 'react-intl'
import { IntlProvider, useIntl } from 'react-intl';

import serverSideEnums from '../../common/serverSideEnums';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import EditRowButtonRenderer from './editButtonRenderer';
import DocsIconsListRenderer from './iconsListRenderer';

import {
  fetchAdminReferralsAsync,
  saveAdminReferralAsync,
  selectReferrals,
  selectSaveReferralResult,
  selectSaveReferralStatus
} from './adminHomeSlice';

let needsReloading = true;
var saveButtonClicked = false;

export default function AdminReferrals(props) {
  const referrals = useSelector(selectReferrals);
  const saveReferralStatus = useSelector(selectSaveReferralStatus);
  const saveReferralResult = useSelector(selectSaveReferralResult);
  const user = useSelector(selectUser);
  const [rowIdsSelected, setRowIdsSelected] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('ToBePaid');
  const dispatch = useDispatch();
  const intl = useIntl();
  const statusTranslations = serverSideEnums(intl).status;
  const statusRefTranslations = serverSideEnums(intl).referralStatus;
  const [isSuperAdmin] = useState(user && user.roles.includes("ROLE_SuperAdmin"));


  useEffect(() => {
    if (needsReloading) {
      dispatch(fetchAdminReferralsAsync());
      needsReloading = false;
    }
  });

  if (!user) {
    props.history.push('/login');
  }

  const cellLeftAlign = { 'text-align': "left", padding: '2px' };
  const cellRightAlign = { 'text-align': "right", padding: '2px' };
  const cellCenterAlign = { 'text-align': "center", padding: '2px' };

  function headerHeightSetter() {
    var padding = 2;
    var height = 50 + padding;
    this.api.setHeaderHeight(height);
    this.api.resetRowHeights();
  }

  function dateFormatter(params) {
    var newDate = new Date();
    newDate.setTime(Date.parse(params.value));
    return newDate.toLocaleDateString();
  }

  const { register, errors, handleSubmit, reset } = useForm({
    defaultValues: {}
  });

  const getSelectedRowData = (gridApi) => {
    let selectedNodes = gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data);
    console.log(selectedData);
    return selectedData;
  };

  const onGridSelectionChanged = ({ api, columnApi, type }) => {
    const selectedRowsDataArray = getSelectedRowData(api);
    const rowIds = [];
    selectedRowsDataArray.forEach(rowData => {
      rowIds.push(rowData.id);
    });
    console.log(rowIds);
    setRowIdsSelected(rowIds);
  }

  const onSubmit = async (data, event) => {
    event.preventDefault();
    dispatch(saveAdminReferralAsync({ data: rowIdsSelected, newStatus: selectedStatus }));
  };

  useEffect(() => {
    console.log(saveReferralStatus);
    console.log({ saveButtonClicked });
    if (saveReferralStatus === 'idle' && saveButtonClicked) {
      saveButtonClicked = false;
      console.log({ saveReferralResult });
      if (saveReferralResult && saveReferralResult.error) {
        Swal.fire('Ahhh!', "The email was already referred. Please try another one.", 'info');
      } else {
        dispatch(fetchAdminReferralsAsync());
        reset({})
      }
      return;
    }

    if (saveReferralStatus === 'loading') {
      saveButtonClicked = true;
    }
    console.log({ referrals })
  });

  var statuses = [
    { isoCode: 'ToBePaid', name: 'To be Paid' },
    { isoCode: 'Paid', name: 'Paid' },
  ];

  var gridApi;
  var gridColumnApi;

  const onGridReady = params => {
    gridApi = params.api;
    gridColumnApi = params.columnApi;
    params.columnApi.getColumn('id').setSort("asc")
  };

  function downloadValidated() {
    var link = document.createElement("a");
    link.href = "https://eco2wo.com/api/referralsPaymentInfo";
    link.target = "_blank"

    document.body.appendChild(link);

    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
  }

  return (

    <div>
      <header className="">
        <h3>Referrals</h3>
        <div style={{ display: isSuperAdmin ? '' : 'none' }} >
          You can use this page to update the status of the referrals.
        </div>
      </header>
      <Form className="input-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-md-6 offset-md-0" style={{ display: isSuperAdmin ? '' : 'none' }} >
          Please select the referrals in the grid for which you want to change the status.
          Then click "Update Status".
          <Form.Group controlId="country">
            <Form.Label>New Status</Form.Label>
            <Form.Control
              as="select"
              name="status"
              onChange={(event) => setSelectedStatus(event.target.value)}
            >
              {statuses.map(({ isoCode, name }) => (
                <option value={isoCode} key={isoCode}>
                  {name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Button variant="primary" type="submit">Update Status</Button>
        </div>
      </Form >

      <div className="ag-theme-alpine" style={{ height: 800, width: '100%', position: 'relative' }}>
        <div style={{ position: 'absolute', top: 13, right: 6, zIndex: 1000, display: isSuperAdmin ? '' : 'none' }}>
          <button className="btn btn-success btn-success-thin" onClick={downloadValidated} >Export</button>
        </div>
        <AgGridReact style={{ height: 800, width: '100%' }}
          onFirstDataRendered={headerHeightSetter}
          rowData={referrals}
          onGridReady={onGridReady}
          defaultColDef={{
            flex: 1,
            editable: true,
            cellStyle: cellLeftAlign,
            headerClass: "header-text-center",
            wrapText: true
          }}
          default key="id" rowSelection='multiple'
          onSelectionChanged={onGridSelectionChanged}
        >
          <AgGridColumn field="id" filter={true} hide={true} />

          <AgGridColumn headerName="N."
            valueGetter={(params) => params.node.rowIndex + 1}
            maxWidth="60" cellStyle={cellCenterAlign} filter={true} />
          <AgGridColumn headerName="Email" headerClass="" field="email" filter={true} />
          <AgGridColumn headerName="Referred By Email" headerClass="" field="sellerEmail" filter={true} />
          <AgGridColumn headerName="Referred By Name" headerClass="" field="sellerName" filter={true} />
          <AgGridColumn field="date" headerName="Date" cellStyle={cellCenterAlign} valueFormatter={dateFormatter}
            sortable={true} filter={true} />
          <AgGridColumn cellStyle={cellRightAlign} headerName="Reward" maxWidth="70" valueGetter={(params) =>
            params.data.paymentAmount ? ('' + params.data.paymentAmount).concat(` €`) : ''} />
          <AgGridColumn cellStyle={cellCenterAlign} headerName="Status"
            valueGetter={(params) => { return statusRefTranslations[params.data.status]; }} maxWidth="100" filter={true} />
          {isSuperAdmin && <AgGridColumn headerName="" checkboxSelection={true}
            minWidth="60" maxWidth="60" cellStyle={cellRightAlign} />
          }
        </AgGridReact>
      </div >

    </div>
  );

}
