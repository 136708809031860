import http from "../http-common";

class UploadFilesService {
  upload(file, filenamePrefix, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
    return http.post("/upload/add/" + filenamePrefix, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  uploadMultiple(files, filenamePrefix) {
    let formData = new FormData();
    //alert(files);

    formData.append("file", files[0]);
    return http.post("/upload/add/" + filenamePrefix, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    //.then(() => {
    //  if (files.length > 1) {
    //    let formData2 = new FormData();
    //    formData2.append("file", files[1]);
    //    return http.post("/upload/add/" + filenamePrefix + '-1', formData2, {
    //      headers: {
    //        "Content-Type": "multipart/form-data",
    //      }
    //    })
    //  } else {
    //    return new Promise();
    //  }
    //})
  }
}

export default new UploadFilesService();
