import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, selectIsLoggedIn } from '../../slices/auth';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useForm } from 'react-hook-form';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import DocIconRenderer from './docIconRenderer';

import {
  selectAgents,
  fetchAgentsAsync,
  disableAgentAsync,
  selectDisableAgentStatus,
  selectDisableAgentResult
} from './adminHomeSlice';

let needsReloading = true;
var saveButtonClicked = false;

export default function AdminAgents(props) {
  const agents = useSelector(selectAgents);
  const disableAgentStatus = useSelector(selectDisableAgentStatus);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [country, setCountry] = useState('Deutschland');
  //const [editedPartner, setEditedPartner] = useState({});

  useEffect(() => {
    if (needsReloading) {
      dispatch(fetchAgentsAsync());
      needsReloading = false;
    }
  });

  if (!user) {
    props.history.push('/login');
  }

  const cellLeftAlign = { 'text-align': "left", padding: '2px' };
  const cellRightAlign = { 'text-align': "right", padding: '2px' };
  const cellCenterAlign = { 'text-align': "center", padding: '2px' };

  function headerHeightSetter() {
    var padding = 2;
    var height = 50 + padding;
    this.api.setHeaderHeight(height);
    this.api.resetRowHeights();
  }

  function dateFormatter(params) {
    var newDate = new Date();
    newDate.setTime(Date.parse(params.value));
    return newDate.toLocaleDateString();
  }

  function round2dec(num) {
    return Math.round(num * 100) / 100
  }

  var countries = [
    { isoCode: 'Deutschland', name: 'Deutschland' },
  ];

  var gridApi;
  var gridColumnApi;

  const onGridReady = params => {
    gridApi = params.api;
    gridColumnApi = params.columnApi;
    params.columnApi.getColumn('agent.partner.companyName').setSort("asc");
    params.columnApi.getColumn('agent.agentId').setSort("asc");
  };

  const disableAgent = (agentUser) => {
    dispatch(disableAgentAsync({ id: agentUser.agent.id, dis: true }));
  }

  const enableAgent = (agentUser) => {
    dispatch(disableAgentAsync({ id: agentUser.agent.id, dis: false }));
  }

  useEffect(() => {
    if (disableAgentStatus === 'idle' && saveButtonClicked) {
      saveButtonClicked = false;
      // if (savePartnerResult && !savePartnerResult.error) {
      //   Swal.fire('Awesome!', "Partner Saved!", 'success');

      dispatch(fetchAgentsAsync());
      //   reset({});
      //   setEditedPartner({});
      // } else {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Oops...',
      //     text: 'Partner could not be saved. Please check if the Company Name or Email are duplicates.'
      //   });

      //   return;
      // }
    }
    if (disableAgentStatus === 'loading') {
      saveButtonClicked = true;
    }

  });

  return (
    <div>
      <header className="">
        <h3>Agents Management</h3>
        This page is an overview of the Agents information and total commissions.
      </header>

      <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
        <AgGridReact style={{ height: 800, width: '100%' }}
          onGridReady={onGridReady}
          onFirstDataRendered={headerHeightSetter}
          rowData={agents}
          frameworkComponents={{
            payRowButtonRenderer: (props) => {
              return (
                <div>
                  <div>
                    {(props.data.agent.disabled !== true) &&
                      <button className="btn btn-success btn-success-thin"
                        onClick={() => {
                          disableAgent(props.data);
                        }}>Disable</button>
                    }
                  </div>
                  <div>
                    {(props.data.agent.disabled === true) &&
                      <button className="btn btn-success btn-success-thin"
                        onClick={() => {
                          enableAgent(props.data);
                        }}>Enable</button>
                    }
                  </div>
                </div>
              )
            },
            docIconRendered: DocIconRenderer,
            linkRenderer: props => (<a target="_blank" href={props.value}>LINK</a>)
          }}
          defaultColDef={{
            flex: 1,
            editable: true,
            cellStyle: cellLeftAlign,
            headerClass: "text-center",
            wrapText: true
          }}
          default key="id"
        >
          <AgGridColumn headerName="Partner" field="agent.partner.companyName" filter={true} />
          <AgGridColumn headerName="Agent ID" field="agent.agentId" maxWidth="100" filter={true} />
          <AgGridColumn headerName="Office ID" field="agent.officeId" maxWidth="100" filter={true} />
          <AgGridColumn headerName="First Name" field="firstName" filter={true} />
          <AgGridColumn headerName="Last Name" field="lastName" filter={true} />
          <AgGridColumn headerName="Email" field="email" filter={true} minWidth="180" />
          <AgGridColumn headerName="Promo code" field="agent.promoCode" maxWidth="100" filter={true} />

          <AgGridColumn headerName="Total Commission" valueGetter={(params) =>
            params.data.agent.totalCommission != null ? ('' + round2dec(params.data.agent.totalCommission)).concat(` €`) : '0 €'}
            filter={true} minWidth="95" cellStyle={cellCenterAlign} />
          <AgGridColumn headerName="Already Paid Commission" valueGetter={(params) =>
            params.data.agent.paidCommission != null ? ('' + round2dec(params.data.agent.paidCommission)).concat(` €`) : '0 €'}
            filter={true} minWidth="95" cellStyle={cellCenterAlign} />
          <AgGridColumn headerName="To Be Paid Commission" valueGetter={(params) =>
            params.data.agent.toBePaidCommission != null ? ('' + round2dec(params.data.agent.toBePaidCommission)).concat(` €`) : '0 €'}
            filter={true} minWidth="95" cellStyle={cellCenterAlign} />
            <AgGridColumn headerName="Enabled" valueGetter={(params) =>
              params.data.agent.disabled === true ? 'Disabled' : 'Enabled'}
              filter={true} minWidth="75"  />

          <AgGridColumn headerName=""
            cellRenderer="payRowButtonRenderer"
            cellStyle={cellCenterAlign}
          />
        </AgGridReact>
      </div >

    </div>
  );

}
