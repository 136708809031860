import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import Swal from 'sweetalert2';
import { BASE_API_URL } from '../../utils/constants';
import { useDispatch, useSelector } from "react-redux";
import * as ibantools from "ibantools";
import {
  selectAgentUser,
  fetchAgentUserByPromoCodeAsync
} from '../admin-home/adminHomeSlice';
import { createImmutableStateInvariantMiddleware } from '@reduxjs/toolkit';

var agentUserByPromoCodeLoaded = false;

const ThirdStep = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const { user } = props;

  var isPrivate, isFleet, isChargeSt, isWithVehicles, isCompany;
  const [wayOfPayment, setWayOfPayment] = useState('IBAN');
  const [ibanValue, setIbanValue] = useState('');
  const [ibanEdited, setIbanEdited] = useState(false);
  const [numChildren, setNumChildren] = useState(1);
  const [adminSettings, setAdminSettings] = useState(null);
  const lang = props.lang;
  const intl = useIntl(); //intl.formatMessage({ id: 'certId' })
  const agentUser = useSelector(selectAgentUser);
  const dispatch = useDispatch();

  try {
    ({ isPrivate, isFleet, isChargeSt, isWithVehicles, isCompany } =
      user.userType);
  } catch {
    props.history.push('/');
    window.location.reload();
  }

  function setIbanValueWrapper(value) {
    setIbanValue(value.replaceAll(' ',''));
    setIbanEdited(value !== '');
  }

  async function fetchAdminSettings() {
    const { data } = await axios.get(`${BASE_API_URL}/adminSettings/public`);
    setAdminSettings(data);
  }

  useEffect(() => {
    if (!adminSettings) {
      fetchAdminSettings();
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  if (!agentUserByPromoCodeLoaded && user.promoCode && !user.agentUser) {
    dispatch(fetchAgentUserByPromoCodeAsync({ promoCode: user.promoCode }));
    agentUserByPromoCodeLoaded = true;
  }

  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      iban: user.iban,
      bank_name: user.bank_name,
      referral: user.referral,
      wayOfPayment: user.wayOfPayment || 'IBAN',
    },
  });

  console.log({ errors });

  const onSubmit = async (data, event) => {
    event.preventDefault();

    if (wayOfPayment == 'IBAN') {
      if (errors.iban != null) {
        return false;
      }

      if (!isIbanValid(data.iban)) {
        return false;
      }
    }

    try {
      props.updateUser(data);
      const { user: userFromState } = props;

      console.log({ data });
      const user = { ...userFromState, ...data };

      user.vehicles = [];
      user.chargingPoints = [];
      user.referrals = [];

      let vIndex = 0;
      while (user[`licence_plate${vIndex}`]) {
        let v = {
          type: isFleet ? user[`vehicleType${vIndex}`] : 'Car',
          licencePlate: user[`licence_plate${vIndex}`],
          vin: user[`vin${vIndex}`],
          registrationDate: user[`registrationDate${vIndex}`],
        };

        if (!v.licencePlate.endsWith('E')) {
          v.licencePlate = v.licencePlate + 'E';
        }

        user.vehicles.push(v);
        vIndex++;
      }

      let cpIndex = 0;
      while (user[`nameOfChPoint${cpIndex}`]) {
        let cp = {
          name: user[`nameOfChPoint${cpIndex}`],
          number: user[`numberOfChPoint${cpIndex}`],
          //location: user[`locationOfChPoint${cpIndex}`],
          address: user[`chPointAddress${cpIndex}`],
          city: user[`chPointCity${cpIndex}`],
          postcode: user[`chPointZip${cpIndex}`],
          amountMWGenerated: user[`chPointAmountMW${cpIndex}`],
          powerSourceType: user[`chPointPowerSource${cpIndex}`] === '1' ? 1 : 0,
        };
        user.chargingPoints.push(cp);
        cpIndex++;
      }

      let rIndex = 0;
      while (user[`referral${rIndex}`]) {
        user.referrals.push(user[`referral${rIndex}`]);
        rIndex++;
      }

      user.sellerType = isPrivate ? 0 : isFleet ? 1 : 2;
      user.userType = 0;
      user.lang = lang;
      user.adminSettings = null;
      console.log({ lang });

      await axios.post(`${BASE_API_URL}/register`, {
        ...user,
        //...updatedData
      });
      Swal.fire(
        intl.formatMessage({ id: 'succReg' }),
        intl.formatMessage({ id: 'succReg2' }),
        'success'
      ).then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          //props.resetUser();
          props.history.push('/login');
        }
      });
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data, //"Email already exists" //
        });
        console.log('error', error.response.data);
      }
    }
  };

  var waysOfPayment = [
    { isoCode: 'IBAN', name: intl.formatMessage({ id: 'payoutToIban' }) },
    { isoCode: 'NGO01', name: intl.formatMessage({ id: 'dtNGO01' }) },
  ];

  function getSaversDetails() {
    const { user } = props;
    var vehicles = [];

    let vIndex = 0;
    let total = 0;
    while (user[`licence_plate${vIndex}`]) {
      let v = {
        type: user[`vehicleType${vIndex}`],
        licencePlate: user[`licence_plate${vIndex}`],
        vin: user[`vin${vIndex}`],
        amount: 120,
      };
      vehicles.push(v);
      total += v.amount;
      vIndex++;
    }

    return { vehicles, total };
  }

  const children = [];
  for (var i = 0; i < numChildren; i += 1) {
    children.push(
      <div>
        <Form.Group controlId={`referralGroup${i}`}>
          <Form.Control
            type='email'
            name={`referral${i}`}
            //placeholder="Enter your referral's email address"
            autoComplete='off'
            ref={register()}
            className={errors[`referral${i}`] ? 'input-error' : ''}
          />
          {errors[`referral${i}`] && (
            <p className='errorMsg'>{errors[`referral${i}`].message}</p>
          )}
        </Form.Group>
      </div>
    );
  }

  function getTotalPaymentAmount(adminSettings, fetchedAgentUser) {
    if (!adminSettings) return 0;
    var addPay = 0;
    if (user.agentUser) {
      addPay = user.agentUser.agent.partner.additionalPaymentAmount;
    } else if (fetchedAgentUser) {
      addPay = fetchedAgentUser.agent.partner.additionalPaymentAmount;
    }
    if (!adminSettings) return 0;
    let totalVehiclesPayment = 0;
    let totalSavedTCO2 = 0;

    getSaversDetails().vehicles.map(({ type, licencePlate, amount }) => {
      if (isFleet) {
        switch (type) {
          case 'Car':
            totalVehiclesPayment += (user.agentUser ? user.agentUser.agent.partner.fleetCarPaymentAmount : adminSettings.fleetCarPaymentAmount) + addPay;
            // totalSavedTCO2+= adminSettings.fleetCarTCo2Saved;
            break;
          case 'Bus':
            totalVehiclesPayment += (user.agentUser ? user.agentUser.agent.partner.fleetBusPaymentAmount : adminSettings.fleetBusPaymentAmount) + addPay;
            // totalSavedTCO2+= adminSettings.fleetBusTCo2Saved;
            break;
          case 'Truck':
            totalVehiclesPayment += (user.agentUser ? user.agentUser.agent.partner.fleetTruckPaymentAmount : adminSettings.fleetTruckPaymentAmount) + addPay;
            // totalSavedTCO2+= adminSettings.fleetTruckTCo2Saved;
            break;
          default:
            break;
        }
      } else {
        if (user.partialPayment) {
          totalVehiclesPayment += (adminSettings.pvtCarPartialPaymentAmount);
        } else {
          totalVehiclesPayment += (user.agentUser ? user.agentUser.agent.partner.pvtCarPaymentAmount : adminSettings.pvtCarPaymentAmount) + addPay;
        }
        // totalSavedTCO2+= adminSettings.pvtCarTCo2Saved;
      }
      totalSavedTCO2 += 2;
    });

    return { totalVehiclesPayment, totalSavedTCO2 }
  }

  function getExtraDonatePaymentAmount() {
    if (!adminSettings) return 0;
    let extraDonatePaymentAmount = 0;
    getSaversDetails().vehicles.map(({ type, licencePlate, amount }) => {
      extraDonatePaymentAmount += adminSettings.pvtCarPartialDonateAm;
    });

    return extraDonatePaymentAmount;
  }

  function getPaymentAmount(type, adminSettings, fetchedAgentUser) {
    if (!adminSettings) return 0;
    var addPay = 0;
    if (user.agentUser) {
      addPay = user.agentUser.agent.partner.additionalPaymentAmount;
    } else if (fetchedAgentUser) {
      addPay = fetchedAgentUser.agent.partner.additionalPaymentAmount;
    }
    if (isFleet) {
      switch (type) {
        case 'Car':
          return (user.agentUser ? user.agentUser.agent.partner.fleetCarPaymentAmount : adminSettings.fleetCarPaymentAmount) + addPay;
        case 'Bus':
          return (user.agentUser ? user.agentUser.agent.partner.fleetBusPaymentAmount : adminSettings.fleetBusPaymentAmount) + addPay;
        case 'Truck':
          return (user.agentUser ? user.agentUser.agent.partner.fleetTruckPaymentAmount : adminSettings.fleetTruckPaymentAmount) + addPay;
        default:
          return 0;
      }
    } else {
      if (user.partialPayment) {
        return adminSettings.pvtCarPartialPaymentAmount;
      } else {
        return (user.agentUser ? user.agentUser.agent.partner.pvtCarPaymentAmount : adminSettings.pvtCarPaymentAmount) + addPay;
      }
    }
  }

  const wlPartnerId = 23;
  if (user.promoCode &&
    agentUser && agentUser.agent && agentUser.agent.partner && agentUser.agent.partner.id === wlPartnerId) {
    user.partialPayment = true;
  }

  function isIbanValid(iban) {
    console.log({iban})
    if (!iban) {
      iban = ibanValue;
    }
    console.log({ibanReplaced: iban.replaceAll(' ','')});
    if (ibantools.isValidIBAN(iban.replaceAll(' ',''))) {
      delete errors.iban;
      return true;
    }

    if (ibanEdited) {
      errors.iban = { type: 'required', message: intl.formatMessage({ id: 'validIbanIsReq' }) };
    }

    return false;
  }

  return (
    <Form className='form-horizontal' onSubmit={handleSubmit(onSubmit)}>
      <motion.div
        className='col-md-12'
        initial={{ x: '-100vw' }}
        animate={{ x: 0 }}
        transition={{ stiffness: 150 }}>
        <br />
        <div style={{ height: "35px" }}>
          <FormattedMessage id='regPage3TheFolow' values={{ what: intl.formatMessage({ id: 'vehicles' }), }} />
        </div>
        <ul style={{ paddingLeft: '1em' }}>
          {getSaversDetails().vehicles.map(({ type, licencePlate, amount }) => (
            <li key={licencePlate}>
              {/*{(type
                ? intl.formatMessage({ id: type })
                : intl.formatMessage({ id: 'vehicle' })) + ' '}*/}
              <FormattedMessage id='licplate' /> "{licencePlate}":{' '}
              {getPaymentAmount(type, adminSettings, agentUser)} €
            </li>
          ))}
        </ul>
        <FormattedMessage id='totalAmountWillBe' />{' '}
        {getTotalPaymentAmount(adminSettings, agentUser).totalVehiclesPayment} €.
        <br />
        {user.partialPayment && !user.agentUser && !user.promoCode &&
          <>
            <FormattedMessage id='totalAmount2' values={{
              fortyFiveTimes: getExtraDonatePaymentAmount(),
              tonsTimes: getTotalPaymentAmount(adminSettings, agentUser).totalSavedTCO2
            }} />.
            <br />
          </>
        }
        <br />
        <Form.Group controlId='wayOfPayment'>
          <Form.Label>
            <b><FormattedMessage id='wop' /></b>
          </Form.Label>
          <FormattedMessage
            id="plsChooseWayOP"
            values={{ a: chunks => (<a target="_blank" href={props.ourNgosLink}>{chunks}</a>) }} />
          <Form.Control
            className='form-control'
            as='select'
            name='wayOfPayment'
            value={wayOfPayment}
            ref={register({})}
            onChange={(event) => { 
                setWayOfPayment(event.target.value); 
                if (event.target.value != 'IBAN') {
                  setIbanValueWrapper('');
                }}
              }>
            {waysOfPayment.map(({ isoCode, name }) => (
              <option value={isoCode} key={isoCode}>
                {name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {wayOfPayment === 'IBAN' && (
          <Form.Group controlId='ibanHolderNameGr'>
            <Form.Label>
              <FormattedMessage id='recipientName' />
            </Form.Label>
            <Form.Control
              type='text'
              name='ibanHolderName'
              //placeholder="Enter your IBAN's Holder Name"
              autoComplete='off'
              ref={register({
                required: intl.formatMessage({ id: 'mandatoryField' }),
              })}
              className={`${errors.ibanHolderName ? 'input-error' : ''}`}
            />
            {errors.ibanHolderName && (
              <p className='errorMsg'>{errors.ibanHolderName.message}</p>
            )}
          </Form.Group>
        )}
        {wayOfPayment === 'IBAN' && (
          <Form.Group controlId='iban'>
            <Form.Label>IBAN</Form.Label>
            <Form.Control
              type='text'
              name='iban'
              //placeholder='Enter your IBAN'
              autoComplete='off'
              onChange={e => setIbanValueWrapper(e.target.value)}
              isValid={isIbanValid()}
              ref={register({
                required: intl.formatMessage({ id: 'mandatoryField' })
              })}
              className={`${errors.iban ? 'input-error' : ''}`}
            />
            {errors.iban && (
              <p className='errorMsg'>{errors.iban.message}</p>
            )}
          </Form.Group>
        )}
        {wayOfPayment === 'IBAN' && (
          <Form.Group controlId='bankNameGrp'>
            <Form.Label>
              <FormattedMessage id='bankName' />
            </Form.Label>
            <Form.Control
              type='text'
              name='bankName'
              //placeholder="Enter your Bank's name"
              autoComplete='off'
              ref={register({
                required: intl.formatMessage({ id: 'mandatoryField' }),
              })}
              className={`${errors.bankName ? 'input-error' : ''}`}
            />
            {errors.bankName && (
              <p className='errorMsg'>{errors.bankName.message}</p>
            )}
          </Form.Group>
        )}
        {isPrivate && (
          <div>
            <b><FormattedMessage id='referralsEmail' /></b>
            <br />
            <FormattedMessage id='youCanReferLongText1' />
            {adminSettings ? adminSettings.referralPayment : 0}
            <FormattedMessage id='youCanReferLongText2' />

            {children}
            <Button
              variant='primary'
              type='button'
              onClick={() => {
                setNumChildren(numChildren + 1);
              }}>
              <FormattedMessage id='addAnotherRef' />
            </Button>
            <br />
            <br />
          </div>
        )}
        {isWithVehicles &&
          <>
            <h4><FormattedMessage id="nextStepsHeader" /></h4>
            <table>
              <tr height="70">
                <td align="center"><img src="email.png" width="50" height="50" alt="D" /></td>
                <td style={{ paddingLeft: "10px" }} ><FormattedMessage id="page3Nstep1" /></td>
              </tr>
              <tr height="70">
                <td align="center"><img src="search.png" width="50" height="50" alt="D" /></td>
                <td style={{ paddingLeft: "10px" }} ><FormattedMessage id="page3Nstep2" /></td>
              </tr>
              <tr height="60">
                <td align="center"><img src="car.png" width="70" alt="D" /></td>
                <td style={{ paddingLeft: "10px" }} ><FormattedMessage id="page3Nstep3" /></td>
              </tr>
            </table>
          </>
        }
        <div style={{ height: '10px' }}></div>
        <Button variant='primary' type='submit'>
          &nbsp;&nbsp;<FormattedMessage id='register' />&nbsp;&nbsp;
        </Button>
      </motion.div>
    </Form>
  );
};

export default ThirdStep;
