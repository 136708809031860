import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import ecoImage from "../assets/leaves-gec19df3f6_1280.jpg";

import { resetMyPassword } from "../slices/auth";
import { clearMessage } from "../slices/message";

const ForgotPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [finalMessage, setFinalMessage] = useState("");
  const intl = useIntl(); //intl.formatMessage({ id: 'certId' })

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    username: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("This field is required!"),
  });

  const handleLogin = (formValue) => {
    const { username } = formValue;
    setFinalMessage("");
    setLoading(true);

    dispatch(resetMyPassword({ username }))
      .unwrap()
      .then(() => {
        setLoading(false);
        setCompleted(true);
        setFinalMessage("We sent an email with a link to reset your password");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (isLoggedIn) {
    return <Redirect to="/profile" />;
  }

  return (
    <div className="row content-container two-column-form">
      <div className="left col-md-6 col-sm-12">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />
        <label>{finalMessage}</label>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleLogin}>
          <Form>
            <div className="form-group">
              <label htmlFor="username">Email</label>
              <Field
                name="username"
                type="text"
                className="form-control"
                disabled={loading || completed}
              />
              <ErrorMessage
                name="username"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loading || completed}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span><FormattedMessage id="sendPwdResetLink"/></span>
              </button>
            </div>
          </Form>
        </Formik>

        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}
      </div>
      <div className="col-md-6 col-sm-12 p-0">
        <div className="image-container">
          <img src={ecoImage} alt="eco green car co2" />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
